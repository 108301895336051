import React, { useCallback, useLayoutEffect, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { FilterIcon } from '../../components/common/Svg'
import SearchInput from '../../components/common/SearchInput'
import BrowseByTopic from '../../components/BrowseByTopic'
import { fetchArticleCategoryList, fetchArticleHomePage, fetchArticleSearchCollection } from '../../state/articles/actions'
import InPartnershipDiscover from '../../components/article/InPartnershipDiscover'
import { articles } from '../../state/articles/selectors'
import DiscCardMedium from '../../components/article/DiscCardMedium'
import ArticleCard from '../../components/article/ArticleCard'
import RecentSearches from '../../components/RecentSearches'
import { getPerkLists } from '../../state/perks/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'

export default function Discover() {
    const dispatch = useDispatch()
    const { articleHomePage, category, searchCollection } = useSelector(articles)
    const [t] = useTranslation('global')
    const { recentSearches } = useSelector(getPerkLists)
    const navigate = useNavigate()
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Article' })
    }, [])

    const onRestartLoad = useCallback(async () => {
        await dispatch(fetchArticleHomePage)
        await dispatch(fetchArticleSearchCollection)
        dispatch(fetchArticleCategoryList)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (category.length === 0) dispatch(fetchArticleCategoryList)
        // eslint-disable-next-line
    }, [])

    const data = [
        { id: 1, title: t('article.browse-by-topic'), content: <BrowseByTopic />, loadContent: () => dispatch(fetchArticleCategoryList) },
    ]
    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('article.articles'),
    }
    const collectionOne = window.self.innerWidth < 768 ? articleHomePage?.collectionOne?.articles.slice(0, 4) : articleHomePage?.collectionOne?.articles.slice(0, 3)
    const collectionTwo = window.self.innerWidth < 768 ? articleHomePage?.collectionTwo?.articles.slice(0, 4) : articleHomePage?.collectionTwo?.articles.slice(0, 3)

    const navigateViewAll = (slot: string) => {
        navigate(`/learn/articles/section/${slot}`)
    }

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Articles')

    return (
        <div>
            <Layout leftSidebarData={leftSidebarData}>
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div className="mobile:mx-10 mx-4 mobile:mb-[120px] mb-[80px] mt-4 medium:mt-[52px] xs-mobile:mt-[32px]">
                    <h1 className="heading-uppercase mb-6 text-black font tracking-tight hidden medium:block font-medium text-26 mobile:text-32 tablet:text-36">
                        {t('article.title')}
                    </h1>

                    <SearchInput smallWidthUntil="medium" action="articles" placeholder={t('article.what-would-you-like-to-know?')} onFocus={handleFocus} />

                    {onFocus && (
                        <RecentSearches data={recentSearches?.articles} type="articles" collection={searchCollection} />
                    )}

                    <div className="mt-6">
                        {/* In partnership */}
                        <InPartnershipDiscover />
                    </div>

                    {/* featured one and two */}
                    <div className="grid mobile:grid-cols-2 gap-6 h-fit">
                        {articleHomePage?.featuredArticleOne && <DiscCardMedium item={articleHomePage?.featuredArticleOne} />}
                        {articleHomePage?.featuredArticleTwo && <DiscCardMedium item={articleHomePage?.featuredArticleTwo} />}
                    </div>

                    {/* collection one */}
                    {collectionOne && (
                        <div className="mt-60">
                            <div className="flex items-end justify-between">
                                <span className="heading-font font-medium tracking-tight font text-black text-18">
                                    {articleHomePage?.collectionOne?.title}
                                </span>

                                <button onClick={() => navigateViewAll('collectionOne')} className="font-semibold not-heading-font tracking-tight underline underline-offset-2 text-rocket text-14">
                                    {t('global.view-all')} {articleHomePage?.collectionOne?.title}
                                </button>
                            </div>

                            <div className="grid laptop:gap-8 md:grid-cols-3 grid-cols-2 gap-6 mt-4">
                                {collectionOne?.map((item) => (
                                    <ArticleCard key={item.id} item={item} type="article" />
                                ))}
                            </div>
                        </div>
                    )}

                    {/* featured three and four */}
                    <div className="grid mobile:grid-cols-2 gap-6 mt-60 h-fit">
                        {articleHomePage?.featuredArticleThree && <DiscCardMedium item={articleHomePage?.featuredArticleThree} />}
                        {articleHomePage?.featuredArticleFour && <DiscCardMedium item={articleHomePage?.featuredArticleFour} />}
                    </div>

                    {/* collection two */}
                    {collectionTwo && (
                        <div className="mt-60">
                            <div className="flex items-end justify-between">
                                <span className="heading-font font-medium tracking-tight font text-black text-18">
                                    {articleHomePage?.collectionTwo?.title}
                                </span>

                                <button onClick={() => navigateViewAll('collectionTwo')} className="font-semibold not-heading-font tracking-tight underline underline-offset-2 text-rocket text-14">
                                    {t('global.view-all')} {articleHomePage?.collectionTwo?.title}
                                </button>
                            </div>

                            <div className="grid grid laptop:gap-8 md:grid-cols-3 grid-cols-2 gap-6 mt-4 mb-4">
                                {collectionTwo?.map((item) => (
                                    <ArticleCard key={item.id} item={item} type="article" />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </div>
    )
}
