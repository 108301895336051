import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useSelector } from 'react-redux'
import { DismissIcon } from '../common/Svg'
import { getQuiz } from '../../state/quiz/selectors'
import { getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    onClose: () => void
    activeQuestionIndex: number
    totalQuestionsCount: number
}

export default function QuizDetailHeader({ onClose, activeQuestionIndex, totalQuestionsCount }: IProps) {
    const { quiz } = useSelector(getQuiz)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    return (
        <div className="mobile:mx-[42px] mx-4">
            <div className="flex justify-center">
                <div className="mt-[31px] w-full items-center justify-between">
                    <div className="flex items-center">
                        <div onClick={onClose} className="z-10 relative cursor-pointer">
                            <DismissIcon width={13} height={13} color={whiteLabeling?.black} />
                        </div>

                        <p className="px-2 w-screen left-0 text-center text-cavolo text-18 font font-medium tracking-[-0.02rem] leading-[132%]">
                            {quiz.quizName}
                        </p>

                        <p className="min-w-[20px] text-black text-12 font-bold tracking-[-0.02rem] leading-[150%] mr-2 max-mobile:block hidden">
                            {quiz.id ? activeQuestionIndex + 1 : 0}/{totalQuestionsCount}
                        </p>
                    </div>

                    <div className="flex w-full items-center justify-center mt-[25px]">
                        <p className="min-w-[20px] text-black text-12 font-bold tracking-[-0.02rem] leading-[150%] mr-2 hidden mobile:block">
                            {quiz.id ? activeQuestionIndex + 1 : 0}/{totalQuestionsCount}
                        </p>

                        <div className={cn('max-w-[764px] w-full rounded-full flex-1 h-3', css(styles.progressBar))}>
                            <div
                                className="z-10 relative flex rounded-l-xl h-3 bg-raspberry"
                                style={{ width: quiz.id ? `${(activeQuestionIndex * 100) / totalQuestionsCount}%` : 0 }} />

                            <div className={cn('w-full relative z-0 rounded-full bg-white h-3', css(styles.unfilledBar))} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    progressBar: {
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.25)',
    },
    unfilledBar: {
        marginTop: -12,
    },
})
