import React from 'react'
import { DismissIcon } from './common/Svg'
import { IFactOfTheDay } from '../types/data'
import PerkDetail from '../pages/perk/PerkDetail'
import RecipeDetail from '../pages/recipe/RecipeDetail'
import ArticleDetail from '../pages/Discover/ArticleDetail'

interface IProps {
  setShowModal: (value: boolean) => void;
  item: IFactOfTheDay
}

export default function FactOfTheDayModal({ setShowModal, item }: IProps) {
    return (
        <div>
            {/* <style>{'html, body {height: 100%; overflow: hidden;}'}</style> */}

            <div className="fixed bg-white inset-0 z-[1000000] overflow-y-auto">
                <div onClick={() => setShowModal(false)} className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                <div className="relative flex items-center justify-center">
                    <div className="relative w-full h-full bg-white rounded-lg">
                        <div className="fixed top-0 left-0 w-full bg-white shadow-md flex items-center justify-between py-4 px-6 z-10">
                            <div className="w-full flex justify-end cursor-pointer" onClick={() => setShowModal(false)}>
                                <DismissIcon width={20} height={20} />
                            </div>
                        </div>

                        <div className="h-screen pt-12 pb-[100px] overflow-y-scroll">
                            <div className="text-black min-h-screen w-full">
                                {item?.perk && (
                                    <PerkDetail perkId={item?.perk} modal={false} />
                                )}
                                {item?.article && (
                                    <ArticleDetail articlesId={item?.article} modal={false} />
                                )}
                                {item?.recipe && (
                                    <RecipeDetail recipesId={item?.recipe} modal={false} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
