import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { travelSelector } from '../state/travel/selectors'
import { Context } from './common/BaseContext'

export default function ContentBlockMini() {
    const { travelContentBlock } = useSelector(travelSelector)
    const { setShowArticleModal } = useContext(Context)

    return (
        <div
            className="cursor-pointer w-full flex mt-[11px]"
            style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}
            onClick={() => {
                if (travelContentBlock?.overlayArticle) {
                    setShowArticleModal(travelContentBlock?.overlayArticle || 0)
                } else if (travelContentBlock?.bodyButtonUrl) {
                    window.location.href = travelContentBlock.bodyButtonUrl
                }
            }}>

            <style>
                {`
                   .line-clamp-five {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 5;
                        line-clamp: 5;
                   }
                `}
            </style>

            <div className="w-[150px]">
                <img
                    src={travelContentBlock?.image}
                    className="object-cover w-full h-full rounded-tl-lg rounded-bl-lg"
                    alt=""
                />
            </div>

            <div style={{ backgroundColor: travelContentBlock?.backgroundColor }}
                className="w-full bg-[#fefde3] rounded-r-lg flex items-center pl-4 py-[12px]">
                <div>
                    <p className="font-normal text-12 text-grey01">
                        {travelContentBlock?.subtitle}
                    </p>

                    <p style={{ lineHeight: '21.12px' }}
                        className="line-clamp-five font text-16 font-medium text-black mt-1">
                        {travelContentBlock?.title}
                    </p>
                </div>
            </div>
        </div>
    )
}
