import { RootState } from '../index'

export const travelSelector = (state: RootState) => state.travel
export const statisticsList = (state: RootState) => state.travel
export const journeyTypesList = (state: RootState) => state.travel
export const commuteStartEndList = (state: RootState) => state.travel
export const legTransportList = (state: RootState) => state.travel
export const commuteSaveList = (state: RootState) => state.travel
export const travelArticlesList = (state: RootState) => state.travel
export const surveyList = (state: RootState) => state.travel
