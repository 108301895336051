import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import Layout from '../components/layout/Layout'
import { articles } from '../state/articles/selectors'
import { fetchArticleHomePage, fetchCompanyArticle } from '../state/articles/actions'
import CompanyInPartnershipCard from '../components/article/specificContent/CompanyInPartnershipCard'
import { getUser } from '../state/user/selectors'
import CompanyCardMedium from '../components/article/specificContent/CompanyCardMedium'
import SponsoredByBlock from '../components/SponsoredByBlock'
import { getHomePageLists } from '../state/home/selectors'

export default function News() {
    const dispatch = useDispatch()
    const { companyArticle } = useSelector(articles)
    const { user } = useSelector(getUser)
    const [expanded, setExpanded] = useState<boolean>(false)
    const isPinnedData = companyArticle.filter((item) => item?.isPinned)
    const notPinnedData = companyArticle.filter((item) => !item?.isPinned)

    const toggleExpand = () => {
        setExpanded(!expanded)
    }

    const missionStatementContent = user?.company?.missionStatementContent || ''
    const textOnly = missionStatementContent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ')
    const truncatedContent = textOnly.split(/\s+/).slice(0, 50).join(' ') + (textOnly.split(/\s+/).length > 50 ? '...' : '')
    const shouldDisplayViewMore = textOnly.split(/\s+/).length > 50

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'News' })
    }, [])

    const fullContent = expanded ? missionStatementContent : truncatedContent

    const onRestartLoad = useCallback(() => {
        dispatch(fetchCompanyArticle)
        dispatch(fetchArticleHomePage)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Organisation News')

    return (
        <Layout maxWidth contentContainerClassName="flex-col items-center">
            {sponsoredBlock && <div className="mobile:hidden w-screen"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div className={`${companyArticle.length > 0 ? '' : 'h-screen'} medium:w-[900px] medium:mx-[200px] mobile:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[80px] mx-4`}>
                <h1 className="font-medium font tracking-tight text-black text-22">
                    {user?.company?.missionStatementTitle}
                </h1>

                {/* eslint-disable-next-line react/no-danger */}
                <h1 className="rich-text mt-[8px] font-light tracking-tight text-black text-16" dangerouslySetInnerHTML={{ __html: fullContent }} />

                {shouldDisplayViewMore && (
                    <span onClick={toggleExpand} className="text-16 font-light tracking-tight cursor-pointer underline text-rocket">
                        {expanded ? 'View less' : 'View more'}
                    </span>
                )}

                {isPinnedData?.map((item) => (
                    <CompanyInPartnershipCard item={item} />
                ))}

                <div className="grid mobile:grid-cols-2 gap-6 mt-6">
                    {notPinnedData.map((item) => (
                        <CompanyCardMedium item={item} />
                    ))}
                </div>
            </div>
        </Layout>
    )
}
