import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SurveyBody from '../components/survey/SurveyBody'
import QuizQuitOverlayModal from '../components/quiz/QuizQuitOverlayModal'
import { getUser } from '../state/user/selectors'
import SurveyDetailHeader from '../components/survey/SurveyDetailHeader'
import { getHomePageLists } from '../state/home/selectors'
import SponsoredByBlock from '../components/SponsoredByBlock'

export default function Survey() {
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { surveyId } = useParams()
    const { homePage } = useSelector(getHomePageLists)
    const [t] = useTranslation('global')
    const [showSurveyQuitOverlayModal, setShowSurveyQuitOverlayModal] = useState(false)

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    if (!homePage?.survey?.id) {
        return (
            <div className="bg-pistachio min-h-screen p-4 heading-uppercase text-22 tracking-tight font-semibold text-cavolo">
                {homePage?.survey?.id === Number(surveyId) ? (
                    t('surveys.survey-not-found')
                ) : (
                    t('surveys.you-have-already-completed-this-survey')
                )}
            </div>
        )
    }

    return (
        <div className="w-full bg-pistachio min-h-screen overflow-y-scroll pb-[250px]">
            {(!homePage?.survey?.id) && (
                <div className="p-4 heading-uppercase text-22 tracking-tight font-semibold text-cavolo">
                    {homePage?.survey?.id === Number(surveyId) ? (
                        t('surveys.survey-not-found')
                    ) : (
                        t('surveys.you-have-already-completed-this-survey')
                    )}
                </div>
            )}

            {homePage?.survey?.sponsorshipBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={homePage?.survey?.sponsorshipBlock} /></div>}

            {!homePage?.survey?.id && (
                <SurveyDetailHeader activeQuestionIndex={0}
                    totalQuestionsCount={homePage?.survey?.questions?.length || 0}
                    onClose={() => setShowSurveyQuitOverlayModal(true)}
                    card
                />
            )}

            <div className="w-full flex justify-center mobile:px-[42px] px-[16px]">
                <div className="max-w-[764px] w-full">
                    <SurveyBody survey setShowSurveyQuitOverlayModal={setShowSurveyQuitOverlayModal} />
                </div>
            </div>

            <QuizQuitOverlayModal
                description={user?.company?.gamification ? t('home.quit-text-if-gamification-is-enabled') : t('home.quit-text-if-gamification-is-disabled')}
                isShown={showSurveyQuitOverlayModal}
                onClose={() => navigate('/')}
                onContinue={() => setShowSurveyQuitOverlayModal(false)} />
        </div>
    )
}
