import React from 'react'

interface IProps {
    isChecked: boolean
    handleCheckboxChange: () => void;
    title: string
}

export default function Switcher({ handleCheckboxChange, isChecked, title }: IProps) {
    return (
        <div className="flex items-center mt-4">
            <label className="flex cursor-pointer select-none items-center">
                <div className="relative">
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="sr-only"
                    />
                    <div
                        className={`block h-[28px] w-[52px] rounded-full transition-colors duration-300 ${isChecked ? 'bg-spinach' : 'bg-grey01'}`} />

                    <div
                        className={`dot absolute ml-1 top-1 h-[20px] w-[20px] rounded-full bg-white transition-transform duration-300 ${isChecked ? 'translate-x-6' : 'translate-x-0'}`} />
                </div>
            </label>

            <h1 className="ml-2.5 text-14 text-normal text-cavolo">
                {title}
            </h1>
        </div>
    )
}
