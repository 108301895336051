import React, { Fragment, useEffect, useRef } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { getRecipe } from '../../state/recipe/selectors'
import { clearRecipeDetail,
    fetchByCategoryTagList,
    fetchRecipeCategoryList,
    fetchRecipeComments,
    fetchRecipeDetail } from '../../state/recipe/actions'
import RecipeDetailSideBar from '../../components/recipe/RecipeDetailSideBar'
import RecipeDetailBody from '../../components/recipe/RecipeDetailBody'
import RecipeDataContainer from '../../components/recipe/RecipeDataContainer'
import { RecipeMoreLikeThis } from '../../components/recipe/RecipeMoreLikeThis'
import Pagination from '../../components/common/Pagination'
import Skeleton from '../../components/common/Skeleton'
import { getWhiteLabelingList } from '../../state/user/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'

interface IProps{
    recipesId?: number | undefined
    modal?: boolean
}

export default function RecipeDetail({ recipesId, modal }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { recipeId } = useParams()
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { recipeDetail, recipeCategories, recipeCommentList, byCategory, loading } = useSelector(getRecipe)
    const byCategoryFilter = byCategory.filter((i) => i.id !== recipeDetail?.id)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Recipe deatail' })
    }, [])

    useEffect(() => {
        dispatch(clearRecipeDetail())
        dispatch(fetchRecipeDetail(Number(recipeId || recipesId)))
        dispatch(fetchRecipeCategoryList)

        if (!recipesId) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
        dispatch(fetchRecipeComments({ recipe: recipeId || recipesId, page: 1 }))
        // eslint-disable-next-line
    }, [recipeId, recipesId])

    useEffect(() => {
        if (recipeDetail?.tags) {
            const tags = recipeDetail?.tags.map((item) => item.id)
            dispatch(fetchByCategoryTagList(tags?.toString()))
        }
        // eslint-disable-next-line
    }, [recipeDetail])

    const navigateRecipeCategory = (id: number) => {
        navigate(`/lower/recipes/category/${id}`)
    }

    const reviewsRef = useRef(null)

    const openReviews = () => {
        if (reviewsRef.current) {
            // @ts-ignore
            // eslint-disable-next-line no-unsafe-optional-chaining
            const { top } = reviewsRef?.current?.getBoundingClientRect()
            window.scrollTo({
                top: top - 115,
                behavior: 'smooth',
            })
        }
    }

    return (
        <Layout maxWidth={false} showFooter={modal} showNavbar={modal} showSustainabilityMenu={modal}>
            <div className="w-full">
                {/** ***** DETAIL HEADER ******* */}
                <div className="w-full medium:bg-pistachio laptop:flex flex-col justify-center items-center">
                    <div className="laptop:max-w-[1600px] w-full">
                        <div className="w-full flex flex-col medium:flex-row medium:items-stretch items-center justify-end medium:justify-between" style={{ marginTop: -3 }}>
                            {!recipeDetail && <Skeleton className="object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]" />}
                            <img className={`${!recipeDetail ? 'hidden' : 'block'} object-cover w-full h-[405px] tablet:w-[720px] medium:w-[548px] laptop:w-[964px] flex-1 tablet911:h-[540px]`} src={recipeDetail?.image} alt="any" />
                            {recipeDetail?.sponsorshipBlock && <SponsoredByBlock containerStyles={{ width: '100%' }} item={recipeDetail.sponsorshipBlock} />}

                            <RecipeDetailSideBar openReviews={openReviews} recipesId={recipesId} />
                        </div>
                    </div>
                </div>
                {/** ***** DETAIL HEADER ******* */}

                {/** ****** DETAIL BODY ******** */}
                <RecipeDetailBody />
                {/** ****** DETAIL BODY ******** */}

                {/** ** MORE RECIPE LIKE THIS **** */}
                <div className="w-full pt-10 bg-mint laptop:flex flex-col justify-center items-center">
                    <div className="laptop:max-w-[1600px] w-full">
                        <RecipeMoreLikeThis items={byCategoryFilter} />

                        <div className="py-11 ml-[40px]">
                            <h2 className="heading-uppercase font text-18 font-medium text-black">
                                {t('lower.tags')}
                            </h2>

                            <div className="flex flex-wrap">
                                {recipeDetail?.tags?.map((item) => (
                                    <div key={item?.id} className="h-[36px] mt-[16px] rounded-[30px] bg-rocket w-fit px-5 flex items-center justify-center  mr-2 ">
                                        <span className="font-semibold text-12 text-pistachio">
                                            {item?.title}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/** ** MORE RECIPE LIKE THIS **** */}

                {/** *** RECIPE DETAIL FOOTER **** */}
                <div className="w-full laptop:flex flex-col justify-center items-center">
                    <div className="laptop:max-w-[1600px] w-full">
                        <RecipeDataContainer leftElement={(
                            <Fragment>
                                <h1 className="heading-font font tracking-tight font-medium text-18 text-rocket decoration-4 underline underline-offset-[10px] mb-5">
                                    {t('lower.categories')}
                                </h1>

                                {recipeCategories?.map((item) => (
                                    <div key={item.id} onClick={() => navigateRecipeCategory(item?.id)}>
                                        <h1 className="mt-2 pb-0.5 cursor-pointer font tracking-tight flex items-center text-black text-18"
                                            style={{ borderBottomWidth: 1, borderBottomColor: whiteLabeling.avocado }}>
                                            {item.name}
                                        </h1>
                                    </div>
                                ))}
                            </Fragment>
                        )} rightElement={(
                            <>
                                {recipeCommentList.results?.length > 0 && (
                                    <h1 ref={reviewsRef} className="font tracking-tight font-medium text-18 text-black">
                                        {t('lower.reviews')}
                                    </h1>
                                )}

                                {recipeCommentList.results?.map((item) => (
                                    <div key={item?.id} className="mt-4">
                                        <p className="flex font-normal tracking-tight text-grey01 text-14">
                                            <p className="text-14 font-semibold tracking-tight text-grey01">
                                                {item?.firstName} {item?.lastName?.slice(0, 1)}. &nbsp;
                                            </p>
                                            |&nbsp; {item?.timeAgo}
                                        </p>

                                        <p className="mt-1 font-light text-16 text-grey01">
                                            {item?.body}
                                        </p>

                                        <div className="border-[0.5px] border-[#A7D49D] mt-5" />
                                    </div>
                                ))}

                                <Pagination
                                    loading={loading}
                                    loadMore={(page) => dispatch(fetchRecipeComments({ recipe: recipeId || recipesId, page }))}
                                    containerClassName="mt-[60px]"
                                    totalCount={recipeCommentList.count}
                                    currentCount={recipeCommentList.results.length} />
                            </>
                        )} />
                    </div>
                </div>
                {/** *** RECIPE DETAIL FOOTER **** */}
            </div>
        </Layout>
    )
}
