import React, { useContext } from 'react'
import { RWebShare } from 'react-web-share'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Context } from '../common/BaseContext'
import { DismissIcon, InviteIcon } from '../common/Svg'
import image from '../../static/referral.png'
import { updatePrize } from '../../state/prize/actions'
import { getPrize } from '../../state/prize/selectors'
import { getReferralList, getUser } from '../../state/user/selectors'

interface IProps {
    showModal?: boolean
    onClose: () => void
}

export default function ReferralNotificationRedeemer({ onClose, showModal = true }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { screenWidth } = useContext(Context)
    const { referral } = useSelector(getReferralList)
    const { winnersPrice } = useSelector(getPrize)

    const shareData = {
        title: document.title,
        text: referral?.sharingText || '',
        url: `${window.location.origin}/refer/${user?.id}`,
    }

    const handleClick = async () => {
        try {
            dispatch(
                updatePrize({
                    id: winnersPrice.id,
                    isShown: true,
                    pointsTarget: winnersPrice?.pointsTarget?.id,
                    points: 0,
                }),
            )
            onClose()
            navigate('/me/refer-a-friend')
        } catch (err) {
            console.log(`Error: ${err}`)
        }
    }

    const onSubmit = () => {
        dispatch(
            updatePrize({
                id: winnersPrice.id,
                isShown: true,
                pointsTarget: winnersPrice?.pointsTarget?.id,
                points: 0,
            }),
        )

        onClose()
        navigate('/me/refer-a-friend')
    }

    function isMobileDevice() {
        const userAgent = navigator.userAgent.toLowerCase()
        return /mobile|android|iphone|ipad|ipod|blackberry|windows phone/.test(userAgent)
    }

    return (
        <div>
            {showModal && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center"
                                    onClick={() => {
                                        dispatch(
                                            updatePrize({
                                                id: winnersPrice.id,
                                                isShown: true,
                                                pointsTarget: winnersPrice?.pointsTarget?.id,
                                                points: 0,
                                            }),
                                        )
                                        onClose()
                                    }}>
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex items-end justify-center mobile:mt-[76px] mt-[60px]">
                                    <div className="flex flex-col items-center justify-center absolute">
                                        <img
                                            className="mobile:w-[267px] mobile:h-[220px] w-[200px] h-[165px]"
                                            src={image}
                                            alt=""
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className="flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]">
                                        <h1 className="heading-font mobile:text-28 text-18 text-black font-bold tracking-tight font">
                                            {t('log.congratulations!')}
                                        </h1>

                                        <h1 className="text-center heading-font mobile:text-22 text-18 text-black font-bold tracking-tight font">
                                            {t('referral.referral-first-description')}
                                        </h1>

                                        <p className="mt-2 text-center mobile:text-16 text-12 text-black tracking-tight font-light font mobile:leading-[28.6px] leading-[23.6px]">
                                            {winnersPrice.referralTriggerEvent === 'log_travel' ? t('referral.referral-log-journey-second-description') : ''}
                                            {winnersPrice.referralTriggerEvent === 'sign_up' ? t('referral.referral-register-second-description') : ''}
                                        </p>
                                    </div>

                                    <div className="mt-4 w-full">
                                        {isMobileDevice() ? (
                                            <button
                                                type="submit"
                                                className="w-full h-12 bg-rocket rounded-md flex items-center justify-center"
                                                style={{ boxShadow: '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}
                                                onClick={handleClick}>
                                                <InviteIcon />

                                                <h1 className="ml-2.5 text-16 font-bold tracking-tight text-pistachio">
                                                    {t('referral.invite-someone')}
                                                </h1>
                                            </button>
                                        ) : (
                                            <div onClick={onSubmit} className="w-full">
                                                <RWebShare
                                                    data={{
                                                        url: `${window.location.origin}/refer/${user?.id}`,
                                                        title: shareData?.title,
                                                    }}
                                                    onClick={() => console.info('share successful!')}
                                                >
                                                    <button
                                                        type="submit"
                                                        className="w-full h-12 bg-rocket rounded-md flex items-center justify-center"
                                                        style={{ boxShadow: '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}
                                                    >
                                                        <InviteIcon />

                                                        <h1 className="ml-2.5 text-16 font-bold tracking-tight text-pistachio">
                                                            {t('referral.invite-someone')}
                                                        </h1>
                                                    </button>
                                                </RWebShare>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
