import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { FETCH_PRIZE_DRAW_SUCCESS, FETCH_PRIZE_ERROR,
    FETCH_PRIZE_START,
    FETCH_WINNERS_PRICE_SUCCESS,
    FETCH_WINNERS_PRICE_UPDATE_SUCCESS,
    GET_PRIZE_WHEEL_TYPE_LIST } from './consts'
import { PrizeState } from './types'

const initialState: PrizeState = {
    // @ts-ignore
    winnersPrice: {},
    // @ts-ignore
    prizeDraw: [],
    // @ts-ignore
    prizeWheelType: [],
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): PrizeState => {
    const { payload } = action
    switch (action.type) {
    case FETCH_PRIZE_START:
        return { ...state, loading: true }

    case FETCH_WINNERS_PRICE_SUCCESS:
        return {
            ...state,
            winnersPrice: payload,
            loading: false,
        }

    case FETCH_WINNERS_PRICE_UPDATE_SUCCESS:
        return {
            ...state,
            winnersPrice: payload,
            loading: false,
        }

    case FETCH_PRIZE_DRAW_SUCCESS:
        return {
            ...state,
            prizeDraw: payload,
            loading: false,
        }

    case GET_PRIZE_WHEEL_TYPE_LIST:
        return {
            ...state,
            prizeWheelType: payload,
            loading: false,
        }

    case FETCH_PRIZE_ERROR:
        return { ...state, loading: false }

    default:
        return state
    }
}

const persistConfig: PersistConfig<PrizeState> = {
    key: 'prize',
    storage,
    whitelist: ['prizeDraw', 'prize'],
}

export default persistReducer(persistConfig, reducer)
