import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Skeleton from '../../components/common/Skeleton'
import { Badge, Termometr } from '../../components/common/Svg'
import Layout from '../../components/layout/Layout'
import SecondButton from '../../components/common/SecondButton'
import { fetchPledgeDetail, postCommitPledge } from '../../state/challenges/actions'
import PointModal from '../../components/common/PointModal'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getPledgesList } from '../../state/challenges/selectors'
import { getUser } from '../../state/user/selectors'

export default function PledgesDetail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pledgeId } = useParams()
    const [loading, setLoading] = useState<boolean>(true)
    const [showModalPoint, setShowModalPoint] = useState<boolean>(false)
    const [t] = useTranslation('global')
    const handleImageLoad = () => setLoading(false)
    const { user } = useSelector(getUser)
    let buttonName = t('lower.pledges')
    const ceo2Data = location?.state?.ceoValue
    const [loadingCommitPledge, setLoadingCommitPledge] = useState<boolean>(false)
    const [committed, setCommitted] = useState<boolean>(false)
    const [error, setError] = useState({ message: '' })
    const { pledgeDetail } = useSelector(getPledgesList)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Pledge detail' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        dispatch(fetchPledgeDetail(pledgeId || ''))
        // eslint-disable-next-line
    }, [pledgeId])

    if (location.state?.lastPage === '/lower/pledges/pledges-list') {
        buttonName = t('lower.pledges')
    }

    if (location.state?.lastPage === '/') {
        buttonName = 'Home'
    }

    if (location.state?.lastPage === '/lower/pledges') {
        buttonName = t('lower.pledges')
    }

    const onSubmit = async (id: number | undefined) => {
        setLoadingCommitPledge(true)
        try {
            await dispatch(
                postCommitPledge(
                    id,
                    () => {
                        setShowModalPoint(true)
                        setCommitted(true)
                    },
                    setError,
                ),
            )
        } finally {
            setLoadingCommitPledge(false)
        }
    }

    return (
        <Layout leftBackground>
            <div className="medium:pb-0 z-10 w-full flex flex-col medium:flex-row medium:items-stretch items-center medium:justify-between relative">
                <div className="mobile:bg-pistachio bg-pistachio tablet:w-[476px] medium:w-[404px] flex flex-col items-center mb-[0px] medium:mb-0 medium:h-auto medium:p-10 max-mobile:w-[calc(100vw-32px)] max-medium:w-[calc(100vw-80px)] tablet:ml-0 medium:flex relative medium:static z-50 top-[50vw] mb-[50vw]">
                    <div className="min-medium:bg-pistachio max-mobile:px-4 p-8 w-full flex flex-col justify-center medium:p-0">
                        <span onClick={() => navigate(-1)} className="text-center medium:pt-[36px] text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                            {buttonName}
                        </span>

                        <div className="flex flex-col items-center">
                            <div className="flex flex-col items-center">
                                <div className="medium:mt-[129px] mt-8" />

                                <span className="font-normal text-grey01 text-14 mt-3">
                                    {pledgeDetail?.title}
                                </span>

                                <span className="text-black font tracking-tight font-medium medium:text-32 text-26 text-center">
                                    {pledgeDetail?.title}
                                </span>
                            </div>

                        </div>
                    </div>

                    {pledgeDetail?.sponsorshipBlock && <SponsoredByBlock containerStyles={{ width: '100%' }} item={pledgeDetail.sponsorshipBlock} />}

                    <div className="p-8 mobile:pt-0 mobile:pb-10 max-mobile:px-0 medium:p-0 medium:w-full">
                        {pledgeDetail?.isJoined || committed ? (
                            <div className="medium:mt-[126px] mt-8 text-center">
                                <h1 className="text-16 font-normal font tracking-tight text-black">
                                    {t('lower.well-done-you’ve-committed-to-this-pledge')}
                                </h1>

                                <h1 className="font-medium mt-1.5 text-center text-rocket tracking-tight text-14">
                                    {ceo2Data} {t('global.saved')}
                                </h1>
                            </div>
                        ) : (
                            user?.company?.gamification && (
                                <>
                                    <SecondButton
                                        loading={loadingCommitPledge}
                                        onClick={() => onSubmit(pledgeDetail?.id)}
                                        text={t('lower.commit-to-this-pledge')}
                                        className="h-[48px] font-semibold w-full rounded-md text-14 text-pistachio bg-rocket hover:bg-spinach hover:ease-in hover:duration-200 medium:mt-[126px] mt-8"
                                    />

                                    <p className="mt-2 text-14 text-center text-error font-semibold">
                                        {error?.message}
                                    </p>

                                    <p className="mt-2 text-14 font-light text-grey01 text-center">
                                        {pledgeDetail?.joinedPeopleCount} {t('lower.people-have-committed-to-this-pledge-so-far')}
                                    </p>
                                </>
                            )
                        )}
                    </div>
                </div>

                <div className="flex flex-col medium:flex-1 max-medium:w-full">
                    {loading && <Skeleton className="medium:h-[540px] min-medium:aspect-video w-full object-cover medium:static absolute top-0 left-0" />}
                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} medium:h-[540px] min-medium:aspect-video w-full object-cover medium:static absolute top-0 left-0`} src={pledgeDetail?.image} alt="" />

                    <div className="mobile:ml-10 ml-4 mt-8 mobile:mt-10 tablet:mr-[160px] mobile:mr-10 mr-4 mobile:mb-[120px] mb-[80px]">
                        <div>
                            {user?.company?.gamification ? (
                                <div className="flex items-start">
                                    <div>
                                        <Badge width={24} height={24} />
                                    </div>

                                    <span className="ml-2 text-16 text-grey01 font-light">
                                        {t('lower.commit-to-this-pledge-and-receive')} {pledgeDetail?.points} {t('lower.points')}
                                    </span>
                                </div>
                            ) : null}

                            <div className="mt-6 flex items-center">
                                <Termometr width={24} height={24} />

                                <h1 className="ml-2 text-16 text-grey01">
                                    <span className="font-bold not-heading-font text-grey01">{t('lower.save')} {ceo2Data}</span> {t('lower.by-completing-this-challenge')}
                                </h1>
                            </div>
                        </div>

                        <div className="w-[100%] h-[1px] bg-avocado mt-[32px] mb-6" />

                        <h1 className="text-16 font-normal text-gray01 leading-[150%] text-grey01">
                            {pledgeDetail?.description}
                        </h1>
                    </div>
                </div>
            </div>

            <PointModal
                isShown={showModalPoint}
                onClose={() => setShowModalPoint(false)}
                point={pledgeDetail?.points}
                description={t('lower.for-committing-to-this-pledge-you’ve-earned')} />
        </Layout>
    )
}
