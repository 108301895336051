import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import AccountSidebar from '../../components/account/AccountSidebar'
import DropDownMenuFAQ from '../../components/account/DropDownMenuFAQ'

export default function FAQ() {
    const [t] = useTranslation('global')
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'FAQ' })
    }, [])

    return (
        <AccountSidebar>
            <div className="mobile:mt-[52px] mt-[32px] flex-1 tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 tablet:mr-[92px] medium:mr-[88px] mobile:mr-10 laptop:w-[680px]">
                <h1 className="heading-uppercase mobile:text-36 text-26 font tracking-tight font-medium text-darken mt-0.5 mb-[16.5px]">
                    {t('faqs.frequently-asked-questions')}
                </h1>

                <DropDownMenuFAQ
                    question={t('faqs.why-the-name-lowr')}
                    answer={t('faqs.why-the-name-lowr-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.what-is-my-dashboard')}
                    answer={t('faqs.what-is-my-dashboard-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.what-is-my-progress-bar')}
                    answer={t('faqs.what-is-my-progress-bar-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.what-happens-when-i-hit-my-100-plant-points-target-in-one-month')}
                    answer={t('faqs.what-happens-when-i-hit-my-100-plant-points-target-in-one-month-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.how-do-i-collect-plant-points')}
                    answer={(
                        <div>{t('faqs.how-do-i-collect-plant-points-line-1')}
                            <br />
                            - {t('faqs.how-do-i-collect-plant-points-line-2')}
                            <br />
                            - {t('faqs.how-do-i-collect-plant-points-line-3')}
                            <br />
                            - {t('faqs.how-do-i-collect-plant-points-line-4')}
                            <br />
                            - {t('faqs.how-do-i-collect-plant-points-line-5')}
                            <br />
                            - {t('faqs.how-do-i-collect-plant-points-line-6')}
                            <br />
                            - {t('faqs.how-do-i-collect-plant-points-line-7')}
                            <br />
                            - {t('faqs.how-do-i-collect-plant-points-line-8')}
                            <br />
                            {t('faqs.how-do-i-collect-plant-points-line-9')}
                        </div>
                    )}
                />

                <DropDownMenuFAQ
                    question={t('faqs.can-anyone-else-see-my-dashboard-&-progress-bar')}
                    answer={t('faqs.can-anyone-else-see-my-dashboard-&-progress-bar-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.how-do–i-know-you\'ve-planted-a-tree-for-me-when-i-hit-my-100-plant-points-target-in-one-month')}
                    answer={(
                        <div>
                            {t('faqs.how-do–i-know-you\'ve-planted-a-tree-for-me-when-i-hit-my-100-plant-points-target-in-one-month-answer')}
                            <a href="https://moretrees.eco/projects/" target="_blank" className="tracking-tight underline underline-offset-2 text-rocket" rel="noreferrer">
                                &nbsp;https://moretrees.eco/projects/
                            </a>
                        </div>
                    )}
                />

                <DropDownMenuFAQ
                    question={t('faqs.do-i-need-plant-points-in-order-to-access-perks,-such-as-discounts-&-freebies?')}
                    answer={t('faqs.do-i-need-plant-points-in-order-to-access-perks,-such-as-discounts-&-freebies-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.how-do-you-choose-your-perks-partners')}
                    answer={(
                        <div>
                            {t('faqs.how-do-you-choose-your-perks-partners-answer')}
                            <a href="mailto:hello@lowr.co" className="tracking-tight underline underline-offset-2 text-rocket" rel="noreferrer">&nbsp;hello@lowr.co.</a>
                        </div>
                    )}
                />

                <DropDownMenuFAQ
                    question={t('faqs.i-heard-something-about-freebies')}
                    answer={t('faqs.i-heard-something-about-freebies-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.who-writes-the-articles-&-facts-of-the-day-content')}
                    answer={(
                        <div>
                            {t('faqs.who-writes-the-articles-&-facts-of-the-day-content-answer-line-1')}
                            <a href="mailto:hello@lowr.co" className="tracking-tight underline underline-offset-2 text-rocket" rel="noreferrer">&nbsp;hello@lowr.co</a>&nbsp;
                            {t('faqs.who-writes-the-articles-&-facts-of-the-day-content-answer-line-2')}
                        </div>
                    )}
                />

                <DropDownMenuFAQ
                    question={t('faqs.can-i-share-plant-based-recipes-i-have-made')}
                    answer={(
                        <div>
                            {t('faqs.can-i-share-plant-based-recipes-i-have-made-answer-line-1')}
                            <a href="mailto:hello@lowr.co" className="tracking-tight underline underline-offset-2 text-rocket" rel="noreferrer">&nbsp;hello@lowr.co</a>&nbsp;
                            {t('faqs.can-i-share-plant-based-recipes-i-have-made-answer-line-2')}
                        </div>
                    )}
                />

                <DropDownMenuFAQ
                    question={t('faqs.how-can-i-provide-feedback-on-lowr')}
                    answer={t('faqs.how-can-i-provide-feedback-on-lowr-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.how-can-i-contact-someone-from-the-lowr-team')}
                    answer={(
                        <div>
                            {t('faqs.how-can-i-contact-someone-from-the-lowr-team-answer-line-1')}
                            <a href="mailto:hello@lowr.co" className="tracking-tight underline underline-offset-2 text-rocket" rel="noreferrer">&nbsp;hello@lowr.co</a>&nbsp;
                            {t('faqs.how-can-i-contact-someone-from-the-lowr-team-answer-line-2')}
                        </div>
                    )}
                />

                <DropDownMenuFAQ
                    question={t('faqs.is-lowr-free')}
                    answer={t('faqs.is-lowr-free-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.is-lowr-just-for-employees')}
                    answer={t('faqs.is-lowr-just-for-employees-answer')}
                />

                <DropDownMenuFAQ
                    question={t('faqs.can-my-family-access-the-app')}
                    answer={(
                        <div>
                            {t('faqs.can-my-family-access-the-app-answer-line-1')}
                            <a href="mailto:hello@lowr.co" className="tracking-tight underline underline-offset-2 text-rocket" rel="noreferrer">&nbsp;hello@lowr.co</a>&nbsp;
                            {t('faqs.can-my-family-access-the-app-answer-line-2')}
                        </div>
                    )}
                />

                <DropDownMenuFAQ
                    question={t('faqs.i\'ve-seen-the-name-the-green-shoot-institute-around-is-that-the-same-as-lowr')}
                    answer={t('faqs.i\'ve-seen-the-name-the-green-shoot-institute-around-is-that-the-same-as-lowr-answer')}
                />
            </div>
        </AccountSidebar>
    )
}
