import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import ArticleCard from '../../components/article/ArticleCard'
import { articles } from '../../state/articles/selectors'
import { fetchArticleCategoryList, fetchArticleMyBookmark } from '../../state/articles/actions'
import BrowseByTopic from '../../components/BrowseByTopic'
import { FilterIcon } from '../../components/common/Svg'
import SearchInput from '../../components/common/SearchInput'
import Pagination from '../../components/common/Pagination'
import { getPerkLists } from '../../state/perks/selectors'
import RecentSearches from '../../components/RecentSearches'

export default function ArticleBookmark() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { myBookmarkArticle, loading, category, searchCollection } = useSelector(articles)
    const [t] = useTranslation('global')
    const { recentSearches } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Article bookmark' })
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchArticleMyBookmark({ page: 1 }))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        if (myBookmarkArticle?.results?.length === 0) onRestartLoad()
        // eslint-disable-next-line
    }, [myBookmarkArticle?.results?.length])

    useEffect(() => {
        dispatch(fetchArticleCategoryList)
        // eslint-disable-next-line
    }, [category.length])

    const data = [
        { id: 1, title: t('article.browse-by-topic'), content: <BrowseByTopic /> },
    ]
    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('article.articles'),
    }

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="medium:mt-[52px] mt-[32px] min-h-screen mx-12 tablet:mb-[120px] mb-[80px] mobile:mx-10 mx-4">
                <span onClick={() => navigate('/learn/articles')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('article.title')}
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="heading-uppercase xs-mobile:block hidden font tracking-tight font-medium tablet:text-36 mobile:text-32 text-26">
                        {t('article.my-articles')}
                    </h1>

                    <SearchInput action="articles" placeholder={t('article.what-would-you-like-to-know?')} onFocus={handleFocus} />
                </div>

                {onFocus && (
                    <RecentSearches data={recentSearches?.articles} type="articles" collection={searchCollection} />
                )}

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/learn/articles')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('article.title')}
                    </span>

                    <h1 className="font tracking-tight font-medium mt-2 text-26">
                        {t('article.my-articles')}
                    </h1>
                </div>

                <div className="grid md:grid-cols-3 sm:grid-cols-1 mobile:gap-[24px] tablet:gap-[24px] laptop:gap-[32px] mt-8">
                    { myBookmarkArticle?.results?.length !== 0 && myBookmarkArticle?.results?.map((item) => <ArticleCard key={item.id} item={item} type="article" />)}
                </div>

                <Pagination
                    loading={loading}
                    loadMore={(page) => dispatch(fetchArticleMyBookmark({ page }))}
                    containerClassName="mt-[60px]"
                    totalCount={myBookmarkArticle?.count}
                    currentCount={myBookmarkArticle?.results?.length} />
            </div>
        </Layout>
    )
}
