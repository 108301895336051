import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

type QueryParams = {
    [key: string]: string;
};

function parseQueryString(queryString: string): QueryParams {
    return queryString
        .replace(/^\?/, '') // Remove the leading ?
        .split('&') // Split into key-value pairs
        .map((pair) => pair.split('=')) // Split key and value
        .reduce((acc: QueryParams, [key, value]) => {
            acc[decodeURIComponent(key)] = value ? decodeURIComponent(value) : ''
            return acc
        }, {})
}

export function useQueryParams(): QueryParams {
    const location = useLocation()
    return useMemo(() => parseQueryString(location.search), [location.search])
}

export function objectToUrlParams(params: object) {
    return Object.entries(params).filter(([key, value]) => value && key)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')
}
