import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PrizeState } from './types'
import { CLEAR_LEADERBOARD,
    FETCH_LEADERBOARD_ERROR,
    FETCH_LEADERBOARD_START,
    FETCH_MY_RANGE_SUCCESS,
    GET_LEADERBOARD_LIST } from './consts'

const initialState: PrizeState = {
    leaderboard: { currentLeaderboard: 0, count: 0, myRank: 0, thisMonthPoints: undefined, results: [], leaderboards: [], isUserLeaderboard: true },
    myRangeBelowTwo: { currentLeaderboard: 0, count: 0, myRank: 0, results: [], leaderboards: [], isUserLeaderboard: true },
    loading: false,
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): PrizeState => {
    const { payload } = action
    switch (action.type) {
    case FETCH_LEADERBOARD_START:
        return { ...state, loading: true }

    case GET_LEADERBOARD_LIST:
        return {
            ...state,
            leaderboard: { ...payload, results: [...state.leaderboard.results, ...payload.results] },
            loading: false,
        }

    case CLEAR_LEADERBOARD:
        return {
            ...state,
            leaderboard: { currentLeaderboard: 0, count: 0, myRank: 0, thisMonthPoints: undefined, results: [], leaderboards: [...state.leaderboard.leaderboards], isUserLeaderboard: true },
            loading: false,
        }

    case FETCH_MY_RANGE_SUCCESS:
        return {
            ...state,
            myRangeBelowTwo: payload,
            loading: false,
        }

    case FETCH_LEADERBOARD_ERROR:
        return { ...state, loading: false }

    default:
        return state
    }
}

const persistConfig: PersistConfig<PrizeState> = {
    key: 'leaderboard',
    storage,
    whitelist: ['myRangeBelowTwo', 'leaderboard'],
}

export default persistReducer(persistConfig, reducer)
