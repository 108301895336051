import React, { useRef } from 'react'
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next'
import { IArticles } from '../../types/data'
import ArticleCard from './ArticleCard'
import { SliderLeftArrow, SliderRightArrow } from '../common/Svg'

interface IProps {
    items: IArticles[]
}

export function DetailBottomSlider({ items }: IProps) {
    const [t] = useTranslation('global')
    const sliderRef = useRef<Slider>(null)

    const next = () => {
        sliderRef.current?.slickNext()
    }

    const previous = () => {
        sliderRef.current?.slickPrev()
    }

    const settings = {
        speed: 500,
        infinite: false,
        arrows: false,
        variableWidth: true,
        slidesToScroll: 3,
        responsive: [
            { breakpoint: 415, settings: { slidesToScroll: 1 } },
            { breakpoint: 600, settings: { slidesToScroll: 2 } },
            { breakpoint: 815, settings: { slidesToScroll: 3 } },
            { breakpoint: 1199, settings: { slidesToScroll: 4 } },
            { breakpoint: 1385, settings: { slidesToScroll: 3 } },
            { breakpoint: 100000, settings: { slidesToScroll: 4 } },
        ],
    }

    return (
        <div className="medium:ml-10 ml-4">
            <div className="mb-5 flex justify-between mr-10">
                <h2 className="heading-uppercase font text-18 font-medium text-black">
                    {t('article.related-articles')}
                </h2>

                <div className="flex gap-6 items-center">
                    <div onClick={previous} className="cursor-pointer">
                        <SliderLeftArrow height={12.73} width={7.78} />
                    </div>

                    <div className="cursor-pointer" onClick={next}>
                        <SliderRightArrow height={12.73} width={7.78} />
                    </div>
                </div>
            </div>

            <Slider ref={sliderRef} {...settings}>
                {items.map((item) => (
                    <div key={`${item.id}-slider`} className="tablet:pr-8 medium:pr-6 pr-4 mb-10"
                        style={{
                            minWidth: '200px',
                            width: window.innerWidth <= 911 ? '176px'
                                : window.innerWidth >= 912 && window.innerWidth <= 1199 ? '184px'
                                    : window.innerWidth >= 1200 ? '342px' : 'auto',
                        }}
                    >
                        <ArticleCard item={item} type="article" />
                    </div>
                ))}
            </Slider>
        </div>
    )
}
