import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select, { StylesConfig } from 'react-select'
import { getOrganisationalUnitsList, getUser, getWhiteLabelingList } from '../state/user/selectors'
import { fetchOrganisationalUnitsList, fetchUpdateUserSetting, fetchUser } from '../state/user/actions'
import { ShieldIcon } from './common/Svg'
import { Colors } from '../themes'
import Button from './common/Button'

interface ColourOption {
  value: string;
  label: JSX.Element;
  color: string;
}

interface IProps {
    isShown?: boolean
    setScheduleShowModal?: (value: boolean) => void;
    onClose: () => void
}

export default function OrganisationUnitModal({ isShown = true, onClose, setScheduleShowModal }: IProps) {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const [search, setSearch] = useState<Date | string | any>('')
    const [selectedOrganisationUnit, setSelectedOrganisationUnit] = useState(null)
    const [loading, setLoading] = useState<boolean>(false)
    const debouncedSearch = useDebounce(search, 500)

    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { organisationUnitsSearchResults } = useSelector(getOrganisationalUnitsList)

    function useDebounce(value: string, delay: number) {
        const [debouncedValue, setDebouncedValue] = useState(value)

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)

            return () => {
                clearTimeout(handler)
            }
        }, [value, delay])

        return debouncedValue
    }

    const options = organisationUnitsSearchResults?.map((item: any) => (
        {
            id: item?.id,
            value: item.name,
            label:
    <div className="flex items-center">
        {item?.image !== null ? (
            <img className="w-[26px] h-[28px] object-cover" src={item?.image} alt="" />
        ) : (
            <ShieldIcon />
        )}

        <span className="font-bold not-heading-font text-14 ml-[13px]">{item?.name}</span>
    </div>,
            color: whiteLabeling?.black || 'rgba(0, 0, 0, 1)',
            item,
        }
    ))

    const colourStyles: StylesConfig<ColourOption> = {
        dropdownIndicator: (base: any) => ({
            ...base,
            color: whiteLabeling?.rocket,
            cursor: 'pointer',
        }),
        // @ts-ignore
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            cursor: 'pointer',
            color: isSelected
                ? whiteLabeling?.grey01
                : (whiteLabeling?.grey01 && isFocused
                    ? whiteLabeling?.marrow
                    : whiteLabeling?.grey01),
            backgroundColor: isSelected
                ? whiteLabeling?.white
                : isFocused
                    ? whiteLabeling?.mint
                    : whiteLabeling?.white,
        }),
        input: (styles) => ({ ...styles }),
        control: (styles) => ({ ...styles, padding: 7, borderRadius: 10, borderColor: whiteLabeling?.avocado }),
        placeholder: (styles) => ({ ...styles }),
        indicatorSeparator: () => ({ display: 'none' }),
    }

    const onSubmit = async () => {
        const data = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            displayName: user?.displayName || '.',
            timezone: user?.timezone,
            organisationUnit: selectedOrganisationUnit || '',
        }

        setScheduleShowModal?.(true)

        setLoading(true)
        await dispatch(fetchUpdateUserSetting(data))
        setLoading(false)

        onClose()
        if (!user?.organisationUnit?.name) dispatch(fetchUser)
    }

    const handleInputChange = (value: string) => {
        setSearch(value)
    }

    const handleSelectChange = (selectedOption: any) => {
        setSelectedOrganisationUnit(selectedOption?.item?.id)
    }

    useEffect(() => {
        if (debouncedSearch) dispatch(fetchOrganisationalUnitsList(debouncedSearch))
    }, [debouncedSearch, dispatch])

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                {/* <div className="w-full flex items-end justify-end"> */}
                                {/* <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center mt-[16px]"> */}
                                {/*    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} /> */}
                                {/* </div> */}
                                {/* </div> */}

                                <div className="flex flex-col justify-center py-[32px]">
                                    <h1 className="text-black font tracking-tight font-medium text-22 mb-[20px]">
                                        {t('account.join-a-club').replace('[organisational-unit-name]', user.company.organisationalUnitName)}
                                    </h1>

                                    <div className="mt-1.5 w-full">
                                        <Select
                                            maxMenuHeight={200}
                                            options={options}
                                            placeholder={t('account.search')}
                                            styles={colourStyles}
                                            isSearchable
                                            onInputChange={handleInputChange}
                                            onChange={handleSelectChange}
                                            noOptionsMessage={() => 'Type the first three characters'}
                                        />
                                    </div>

                                    <Button
                                        loading={loading}
                                        onClick={onSubmit}
                                        style={!selectedOrganisationUnit ? disabledStyles : undefined}
                                        disabled={!selectedOrganisationUnit}
                                        text={t('global.save')}
                                        className={`${selectedOrganisationUnit && 'cursor-pointer'}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
