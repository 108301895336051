import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import image from '../static/quiz-complete.png'
import { Context } from './common/BaseContext'
import { DismissIcon } from './common/Svg'
import SecondButton from './common/SecondButton'
import { updatePrize } from '../state/prize/actions'
import { getPrize } from '../state/prize/selectors'

interface IProps {
    isShown?: boolean
    onClose: () => void
    width?: boolean
}

export default function EnteredPrizeDrawModal({ width, isShown = true, onClose }: IProps) {
    const dispatch = useDispatch()
    const { screenWidth } = useContext(Context)
    const { winnersPrice } = useSelector(getPrize)

    const onSubmit = () => {
        onClose()
        dispatch(
            updatePrize({
                id: winnersPrice.id,
                isShown: true,
                pointsTarget: winnersPrice?.pointsTarget?.id || 0,
                points: 0,
            }),
        )
    }

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onSubmit} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center mt-[16px]">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex items-end justify-center mobile:mt-[116px] mt-[90px]" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.25))' }}>
                                    <div className="flex flex-col items-center justify-center absolute mobile:w-[202px] mobile:h-[202px] w-[150px] h-[150px]">
                                        <img
                                            className="w-full h-full"
                                            src={image}
                                            alt=""
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className={`${width ? 'xs-mobile:w-[386px] w-[242px]' : 'xs-mobile:w-[296px] w-[242px]'} flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]`}>
                                        <h1 className="mobile:text-22 text-18 text-black font-semibold tracking-tight font mt-10">
                                            Congratulations!
                                        </h1>

                                        <h1 className="text-center mobile:text-22 text-18 text-black font-normal tracking-tight font">
                                            You’ve been entered into the prize draw
                                        </h1>
                                    </div>

                                    <SecondButton onClick={onSubmit} text="Continue" className="mt-[17px] w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
