import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { IPledge } from '../../types/data'
import PledgesCard from '../../components/pledges/PledgesCard'

export default function PledgesViewAll() {
    const navigate = useNavigate()
    const location = useLocation()
    const [t] = useTranslation('global')
    const newPledgeData = location?.state?.pledges

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Pledge view all' })
    }, [])

    return (
        <Layout>
            <div className="mobile:mx-10 mx-16 medium:mt-[52px] mt-[32px] mobile:mb-[120px] mb-[300px]">
                <span onClick={() => navigate('/dashboard')} className="xs-mobile:block hidden text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('navbar.Home')}
                </span>

                <div className="flex items-center justify-between">
                    <h1 className="xs-mobile:block hidden font tracking-tight font-medium tablet:text-36 mobile:text-32 text-26">
                        {newPledgeData?.title}
                    </h1>
                </div>

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/dashboard')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('navbar.Home')}
                    </span>

                    <h1 className="font tracking-tight font-medium mt-2 text-26">
                        {t('lower.pledges')}
                    </h1>
                </div>

                <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-10">
                    {newPledgeData?.pledges?.map((item: IPledge) => (
                        <div key={item.id}>
                            <PledgesCard item={item} />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
