import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { ArrowTravel, DismissIcon, HearthActiveIcon, HearthDefaultIcon } from '../../components/common/Svg'
import Button from '../../components/common/Button'
import { getDate, getDates } from '../../utils/date'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import { getUser } from '../../state/user/selectors'
import { commuteSave } from '../../state/travel/actions'
import { legTransportList } from '../../state/travel/selectors'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import { getHomePageLists } from '../../state/home/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import DeleteLegModal from '../../components/travel/DeleteLegModal'
import JourneyLegs from '../../components/travel/JourneyLegs'
import { readableC02 } from '../../utils/helpers'

interface FormData {
  name: string;
}

export default function TrackTravelImpact() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useSelector(getUser)
    const { pathname } = useLocation()
    const { navbarHeight, screenWidth, isEmbedded } = useContext(Context)
    // @ts-ignore
    const containerRef = useRef<HTMLDivElement>(null)
    const [t] = useTranslation('global')
    const oneWay = location?.state?.oneWay

    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const [showDeleteLegReturnModal, setShowDeleteLegReturnModal] = useState<boolean>(false)
    const [showDeleteLegOutboundModal, setShowDeleteLegOutboundModal] = useState<boolean>(false)

    const [heart, setHeart] = useState<boolean>(false)
    const [heartClicked, setHeartClicked] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const { commuteStartEnd } = useSelector(legTransportList)
    const todayDate = location?.state?.todayDate

    // const [showModalPoint, setShowModalPoint] = useState<string | number | undefined>(0)

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')
    const { date, gameDate } = outboundData
    const returnJourneyData = JSON.parse(localStorage.getItem('returnTravelData') || '{}')

    const totalSum = [...(outboundData?.legs || []), ...(returnJourneyData?.legs || [])].reduce((accumulator, currentObject) => accumulator + currentObject.ceo2Kg, 0)

    const navigateBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    const onSubmit = async (data: FormData) => {
        setLoading(true)

        dispatch(commuteSave({
            commute: outboundData?.backendCommuteId || commuteStartEnd.id,
            favouriteRouteName: heart ? data.name : undefined,
        }, () => {
            navigate('/log/your-impact')
            setLoading(false)
        }))
    }

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel impact' })
    }, [])

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const deleteLegOutbound = () => {
        if (!returnJourneyData?.legs?.length || !outboundData?.legs?.length) {
            setShowQuizQuitOverlayModal(true)
            return
        }

        setShowDeleteLegOutboundModal(true)
    }

    const deleteLegReturn = () => {
        if (!returnJourneyData?.legs?.length || !outboundData?.legs?.length) {
            setShowQuizQuitOverlayModal(true)
            return
        }

        setShowDeleteLegReturnModal(true)
    }

    const handleHeartClick = () => {
        setHeartClicked(true)
        setHeart(!heart)
    }

    useEffect(() => {
        if (heartClicked && containerRef.current) {
            containerRef.current.scrollTo({ top: document?.body?.scrollHeight, left: 0, behavior: 'smooth' })
            setHeartClicked(false)
        }
    }, [heartClicked])

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false}>
            <div className="w-full h-screen">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <Formik onSubmit={onSubmit} initialValues={{ name: '' }}>
                    <Form>
                        <div style={{ marginTop: navbarHeight }} className="fixed top-0 left-0 w-full z-10 bg-white">
                            <div className="flex justify-between items-center pt-[30px] pr-2 mobile:mx-[42px] mx-4">
                                <div onClick={navigateBack} className="flex justify-end cursor-pointer">
                                    <ArrowTravel width={22} height={22} />
                                </div>

                                <div onClick={() => setShowQuizQuitOverlayModal(true)}
                                    className="flex justify-end cursor-pointer">
                                    <DismissIcon width={18} height={18} />
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: screenWidth > 768 ? navbarHeight : 0 }} ref={containerRef} className="h-screen flex flex-col items-center pt-5 mobile:px-[42px] px-4">
                            <div className="max-w-[600px] w-full mt-2 pb-[300px]">
                                {user?.company?.isTravelTypeEvent ? (
                                    <h1 className="heading-uppercase text-cavolo text-22 tracking-tight font-semibold">
                                        {t('log.your-trip’s-footprint')}
                                    </h1>
                                ) : (
                                    <h1 className="heading-uppercase text-cavolo text-22 tracking-tight font-semibold">
                                        {t('log.journey-summary')}
                                    </h1>
                                )}

                                <h1 className="mt-2">
                                    {returnJourneyData?.endPlaceName?.slice(0, returnJourneyData?.endPlaceName?.indexOf(',')) || outboundData?.endPlaceName?.slice(0, outboundData?.endPlaceName?.indexOf(','))} to {returnJourneyData?.startPlaceName?.slice(0, returnJourneyData?.startPlaceName?.indexOf(',')) || outboundData?.startPlaceName?.slice(0, outboundData?.startPlaceName?.indexOf(','))}, {oneWay ? 'one way,' : 'return,'}
                                    <span>{!gameDate ? getDate(todayDate || date) : getDates(gameDate)}</span>
                                </h1>

                                <div className="flex items-center">
                                    <h1 className="px-4 py-2 rounded-md bg-lime text-16 tracking-tight font-bold not-heading-font text-cavolo mt-4">
                                        {t('log.total')}: {readableC02(totalSum)}
                                    </h1>
                                </div>

                                {outboundData?.legs?.length > 0 && (
                                    <JourneyLegs impact journeyData={outboundData} onDelete={deleteLegOutbound} />
                                )}

                                {returnJourneyData?.legs?.length > 0 && (
                                    <JourneyLegs impact goingBackCommute journeyData={returnJourneyData}
                                        onDelete={deleteLegReturn} />
                                )}

                                {(user?.company?.travelLogByFavourite === 'enabled_expanded' || user?.company?.travelLogByFavourite === 'enabled_collapsed') && (
                                    <div className="mb-[27px]">
                                        <div onClick={handleHeartClick}
                                            className="flex items-center mt-7 cursor-pointer">
                                            {heart ? (
                                                <HearthActiveIcon />
                                            ) : (
                                                <HearthDefaultIcon />
                                            )}

                                            <h1 className="text-14 tracking-tight text-raspberry ml-3">
                                                {t('log.save-as-favourite-route')}
                                            </h1>
                                        </div>

                                        {heart && (
                                            <div className="mt-5">
                                                <Input
                                                    placeholder={t('log.enter-name')}
                                                    name="name"
                                                    type="text"
                                                    validate={required(t)} />
                                            </div>
                                        )}
                                    </div>
                                )}

                            </div>
                        </div>

                        <div
                            className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                            style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                            <div
                                className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                                <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                                <div
                                    className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                                    <Button
                                        loading={loading}
                                        className="absolute bottom-8 duration-100 not-heading-font"
                                        text={t('global.save')} />
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={() => navigate('/me/impact')}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />

                {/* {user?.company?.isTravelTypeEvent ? ( */}
                {/*    user?.company?.gamification && ( */}
                {/*        <TravelPointModal */}
                {/*            isShown={!!showModalPoint} */}
                {/*            onClose={() => navigate('/me/impact')} */}
                {/*            point={showModalPoint || 0} */}
                {/*            title={t('log.congratulations!')} */}
                {/*            description={t('log.for-logging-your-travel-you’ve-earned-25-points-and-secured-a-spot-in-this-month’s-prize-draw!')} */}
                {/*            descriptionTwo={t('log.remember-to-log-your-travel-after-the-next-game.')} /> */}
                {/*    ) */}
                {/* ) : ( */}
                {/*    user?.company?.gamification && ( */}
                {/*        <TravelPointModal */}
                {/*            isShown={!!showModalPoint} */}
                {/*            onClose={() => navigate('/me/impact')} */}
                {/*            point={showModalPoint || 0} */}
                {/*            title={t('global.well-done')} /> */}
                {/*    ) */}
                {/* )} */}

                <DeleteLegModal
                    title={t('log.are-you-sure-you-want-to-delete-this-journey?')}
                    outboundLeg
                    isShown={showDeleteLegOutboundModal}
                    onContinue={() => setShowDeleteLegOutboundModal(false)}
                />

                <DeleteLegModal
                    title={t('log.are-you-sure-you-want-to-delete-this-journey?')}
                    returnLeg
                    isShown={showDeleteLegReturnModal}
                    onContinue={() => setShowDeleteLegReturnModal(false)} />
            </div>
        </Layout>
    )
}
