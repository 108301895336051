import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { getPerkLists } from '../../state/perks/selectors'
import { fetchPerksBrand, fetchPerksByType, fetchPerksFilterTag, fetchSearch } from '../../state/perks/actions'
import { FilterIcon } from '../../components/common/Svg'
import PerkByTypeItem from '../../components/common/PerkByTypeItem'
import AllBrandsItem from '../../components/common/AllBrandsItem'
import PerkTags from '../../components/common/PerkTags'
import PerkCard from '../../components/perk/PerkCard'
import SearchInput from '../../components/common/SearchInput'

export default function PerkBySearch() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { search } = useParams()
    const [t] = useTranslation('global')
    const { perksListBySearch, loadingPerkListBySearch } = useSelector(getPerkLists)

    const data = [
        { id: 1, title: t('lower.swaps-by-type'), content: <PerkByTypeItem />, loadContent: () => dispatch(fetchPerksByType) },
        { id: 2, title: t('lower.all-brands'), content: <AllBrandsItem />, loadContent: () => dispatch(fetchPerksBrand) },
        { id: 3, title: t('lower.tags'), content: <PerkTags />, loadContent: () => dispatch(fetchPerksFilterTag) },
    ]

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Swap by search' })
    }, [])

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('lower.swaps'),
    }

    useEffect(() => {
        if (search) dispatch(fetchSearch(search))

        // eslint-disable-next-line
    }, [search])

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="min-h-screen mobile:mx-10 mx-16 mobile:mt-[52px] mt-[32px] tablet:mb-[120px] mb-[80px]">
                <span onClick={() => navigate('/lower/swaps')} className="xs-mobile:block hidden text-rocket tracking-tight italic font-normal cursor-pointer underline underline-offset-2 text-14">
                    {t('navbar.Swaps')}
                </span>

                <div className="flex items-end justify-between">
                    <h1 className="xs-mobile:block hidden tracking-[-0.02em] leading-[126%] font-medium tablet:text-36 mobile:text-32 text-26 text-black mt-[8px]">
                        {search} {t('article.search-results')}
                    </h1>

                    <SearchInput
                        autoFocus
                        defaultValue={search}
                        action="perks"
                        placeholder={t('lower.find-a-great-swap')} />
                </div>

                <div className="xs-mobile:hidden mt-8">
                    <span onClick={() => navigate('/lower/swaps')} className="text-rocket tracking-tight italic font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('navbar.Swaps')}
                    </span>

                    <h1 className="tracking-[-0.02em] leading-[126%] font-medium tablet:text-36 mobile:text-32 text-26 text-black">
                        {search} {t('article.search-results')}
                    </h1>
                </div>

                {perksListBySearch.length > 0 && (
                    <>
                        <h1 className="text-black tracking-tight leading-[132%] font-medium text-18 mt-[32px]">
                            {perksListBySearch.length} {t('article.results')}
                        </h1>

                        <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-3">
                            {perksListBySearch?.map((item) => (
                                <div key={item.id}>
                                    <PerkCard item={item} type="perk" />
                                </div>
                            ))}
                        </div>
                    </>
                )}

                {!loadingPerkListBySearch && perksListBySearch.length === 0 && (
                    <h1 className="tracking-[-0.02em] font-medium text-18 leading-[132%] text-black mt-[32px]">
                        {t('article.your-search-returned-no-results')}
                    </h1>
                )}
            </div>
        </Layout>
    )
}
