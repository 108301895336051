import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import logoWhite from '../../static/lowr-white.png'
import { FacebookIcon, InstagramIcon, LinkedInIcon } from '../common/Svg'
import Skeleton from '../common/Skeleton'
import { getUser } from '../../state/user/selectors'

function Footer(): JSX.Element {
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const [loading, setLoading] = useState(true)
    const [t] = useTranslation('global')
    const handleImageLoad = () => setLoading(false)

    return (
        <div className={`${!user?.company?.footerIsShown && 'hidden'} w-full`}>
            <div className="pl-4 xs-max:hidden bg-spinach pb-[200px] z-0 relative">
                <footer>
                    <div className="flex pt-8">
                        <div>
                            <h6 className="text-12 text-savoy font-medium">
                                <a href="https://www.lowr.co/contact-us" target="_blank" rel="noreferrer">
                                    {t('footer.contact-us')}
                                </a>
                            </h6>

                            <h6 className="text-12 text-savoy font-medium mt-[6px]">
                                <NavLink to="/faqs">
                                    {t('footer.faqs')}
                                </NavLink>
                            </h6>

                            <h6 className="text-12 text-savoy font-medium mt-[6px]">
                                <NavLink to="/learn/organisation-news">
                                    {t('footer.about-us')}
                                </NavLink>
                            </h6>
                        </div>

                        <div className="ml-[60px]">
                            <h6 className="text-12 text-savoy font-medium">
                                <a href="#">
                                    {t('footer.follow-us')}
                                </a>
                            </h6>

                            <div className="w-[104px] mt-2 flex justify-between">
                                <a href="https://www.linkedin.com/company/lowr/" target="_blank" rel="noreferrer">
                                    <LinkedInIcon width={24} height={24} />
                                </a>

                                <a href="https://www.instagram.com/lowr.co/" target="_blank" rel="noreferrer">
                                    <InstagramIcon width={24} height={24} />
                                </a>

                                <a href="https://www.facebook.com/plantsandperks" target="_blank" rel="noreferrer">
                                    <FacebookIcon width={24} height={24} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="mt-[32px]">
                        <div style={{ width: user?.company?.logoContainerWidth }}>
                            <div className="mb-[24px]">
                                {loading && <Skeleton style={{ width: user?.company?.logoContainerWidth, height: user?.company?.logoHeight }} className="rounded" />}

                                <img
                                    style={user?.company?.logo === null ? { width: 95, height: 28 } : { width: user?.company?.logoWidth, height: user?.company?.logoHeight }}
                                    onLoad={handleImageLoad}
                                    className={`${loading ? 'hidden' : 'block'} nav-logo object-contain`}
                                    src={user?.company?.logo === null ? logoWhite : user?.company?.logo}
                                    alt="Your Company" />
                            </div>
                        </div>

                        <span className="text-15 italic font-normal leading-[22.5px] text-savoy font">
                            {t('footer.we-are-on-a-mission-to-lower-human-impact-globally')}
                        </span>
                    </div>

                    <div className="flex mt-8 mb-9">
                        <span className="mr-4 text-12 text-savoy font-medium">
                            <NavLink
                                to="/terms-of-services">
                                {t('footer.terms-of–service')}
                            </NavLink>
                        </span>

                        <span onClick={() => navigate('/privacy-policy')} className="cursor-pointer text-savoy font-medium text-12">
                            {t('footer.privacy-policy')}
                        </span>
                    </div>
                </footer>

                <span className="font-normal text-savoy text-9">
                    &copy; {t('footer.the-green-shoot-inst.-Ltd.-2023.-all-rights-reserved')}
                </span>
            </div>

            <div className="max-xs-max:hidden w-full bg-spinach pb-[36px] laptop:flex flex-col justify-center items-center">
                <div className="laptop:max-w-[1600px] w-full">
                    <footer className="flex items-center">
                        <div className="medium:w-[265px] w-[180px] ml-6 mt-[32px]">
                            <div style={{ width: user?.company?.logoContainerWidth }} className="flex flex-shrink-0 items-center">
                                {loading && <Skeleton style={{ width: user?.company?.logoContainerWidth, height: user?.company?.logoHeight }} className="rounded" />}

                                <img
                                    style={user?.company?.logo === null ? { width: 95, height: 28 } : { width: user?.company?.logoWidth, height: user?.company?.logoHeight }}
                                    onLoad={handleImageLoad}
                                    className={`${loading ? 'hidden' : 'block'} nav-logo object-contain`}
                                    src={user?.company?.logo === null ? logoWhite : user?.company?.logo}
                                    alt="Your Company" />
                            </div>

                            <div className="mt-[24px]">
                                <span className="text-12 font-normal italic text-savoy">
                                    {t('footer.we-are-on-a-mission-to-lower-human-impact-globally')}
                                </span>
                            </div>
                        </div>

                        <div className="flex ml-10 mt-[46px]">
                            <div>
                                <h6 className="text-savoy font-medium text-12">
                                    <a href="https://www.lowr.co/contact-us" target="_blank" rel="noreferrer">
                                        {t('footer.contact-us')}
                                    </a>
                                </h6>

                                <h6 className="text-savoy font-medium text-12 mt-[6px]">
                                    <NavLink to="/faqs">
                                        {t('footer.faqs')}
                                    </NavLink>
                                </h6>

                                <h6 className="text-savoy font-medium text-12 mt-[6px]">
                                    <NavLink to="/learn/organisation-news">
                                        {t('footer.about-us')}
                                    </NavLink>
                                </h6>
                            </div>

                            <div className="medium:ml-[80px] ml-[40px]">
                                <h1 className="mr-4 text-12 text-savoy font-medium">
                                    <NavLink
                                        to="/terms-of-services">
                                        {t('footer.terms-of–service')}
                                    </NavLink>
                                </h1>

                                <span onClick={() => navigate('/privacy-policy')} className="cursor-pointer text-savoy font-medium text-12">
                                    {t('footer.privacy-policy')}
                                </span>
                            </div>

                            <div className="w-[94px] medium:ml-[80px] ml-[40px]">
                                <h6 className="text-savoy font-medium text-12">
                                    <a href="#">
                                        {t('footer.follow-us')}
                                    </a>
                                </h6>

                                <div className="flex justify-between w-[104px] mt-[8px]">
                                    <a href="https://www.linkedin.com/company/lowr/" target="_blank" rel="noreferrer">
                                        <LinkedInIcon width={24} height={24} />
                                    </a>
                                    <a href="https://www.instagram.com/lowr.co/" target="_blank" rel="noreferrer">
                                        <InstagramIcon width={24} height={24} />
                                    </a>
                                    <a href="https://www.facebook.com/plantsandperks" target="_blank" rel="noreferrer">
                                        <FacebookIcon width={24} height={24} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </footer>

                    <span className="ml-6 font-normal text-savoy text-9">
                        © {t('footer.the-green-shoot-inst.-Ltd.-2023.-all-rights-reserved')}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer
