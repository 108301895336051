import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import BrowseByTopic from '../../components/BrowseByTopic'
import { fetchArticleCategoryList } from '../../state/articles/actions'
import { FilterIcon } from '../../components/common/Svg'
import { articles } from '../../state/articles/selectors'
import ArticleCard from '../../components/article/ArticleCard'
import Layout from '../../components/layout/Layout'
import SearchInput from '../../components/common/SearchInput'
import { IArticleCollection } from '../../types/data'
import { getPerkLists } from '../../state/perks/selectors'
import RecentSearches from '../../components/RecentSearches'

export default function ArticleViewAll() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { slot } = useParams()
    const [t] = useTranslation('global')
    const { articleHomePage, searchCollection } = useSelector(articles)
    const { recentSearches } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)

    // @ts-ignore
    const newArticleData: IArticleCollection = articleHomePage[slot]

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Article view all' })
    }, [])

    const data = [
        { id: 1, title: t('article.browse-by-topic'), content: <BrowseByTopic />, loadContent: () => dispatch(fetchArticleCategoryList) },
    ]
    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('article.articles'),
    }

    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    })

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="pb-16 mobile:mx-10 mx-16 min-h-screen medium:mt-[52px] mt-[32px]">
                <div className="hidden xs-mobile:block">
                    <button onClick={() => navigate('/learn/articles')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('article.title')}
                    </button>

                    <div className="h-[45px] flex flex-row items-end justify-between mt-[8px]">
                        <h1 className="font tracking-tight font-medium text-32 tablet:text-36 text-black">
                            {newArticleData?.title}
                        </h1>

                        <SearchInput action="articles" placeholder={t('article.what-would-you-like-to-know?')} onFocus={handleFocus} />
                    </div>

                    {onFocus && (
                        <RecentSearches data={recentSearches?.articles} type="articles" collection={searchCollection} />
                    )}
                </div>

                <div className="xs-mobile:hidden block">
                    <SearchInput action="articles" placeholder={t('article.what-would-you-like-to-know?')} />

                    <button onClick={() => navigate('/learn/articles')} className="text-rocket pt-[32px] tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('article.title')}
                    </button>

                    <h1 className="text-black font tracking-tight font-medium mt-[8px] text-26">
                        {newArticleData?.title}
                    </h1>

                </div>

                <div className="grid grid-cols-1 mobile:gap-8 mobile:grid-cols-3 xs-mobile:grid-cols-2 gap-4 mt-[24px] xs-mobile:mt-10">
                    {newArticleData?.articles?.map((item) => (
                        <ArticleCard key={item.id} item={item} type="article" />
                    ))}
                </div>
            </div>
        </Layout>
    )
}
