import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { getLanguageList } from '../state/user/selectors'

interface IProps {
    isShown?: boolean;
    onClose?: () => void;
    loginConfig?: any
}

export default function LanguageModal({ isShown, onClose, loginConfig }: IProps) {
    const { languageItem } = useSelector(getLanguageList)
    const [t] = useTranslation('global')

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString && JSON.parse(languageDataString)

    const browserLanguageDataString = localStorage.getItem('browserLanguage')
    const browserLanguageData = browserLanguageDataString && JSON.parse(browserLanguageDataString)

    const sortedLanguageItem = Array.isArray(languageItem)
        ? [...languageItem].sort((a, b) => {
            if (a.isDefault && !b.isDefault) return -1
            if (!a.isDefault && b.isDefault) return 1
            return 0
        })
        : []

    const sortedLoginLanguageItem = Array.isArray(loginConfig?.languages)
        ? [...loginConfig.languages].sort((a, b) => {
            if (a.isDefault && !b.isDefault) return -1
            if (!a.isDefault && b.isDefault) return 1
            return 0
        })
        : []

    const language = sortedLanguageItem?.map((item) => item?.language)
    const defaultValue = language?.find((option) => option?.shortName === languageData)

    const loginLanguage = sortedLoginLanguageItem?.map((item) => item?.language)
    const loginDefaultValue = loginLanguage?.find((option) => option?.shortName === (languageData || browserLanguageData))

    const onSubmit = (item: any) => {
        if (item.shortName) localStorage.setItem('language', JSON.stringify(item.shortName))
        if (onClose) {
            onClose()
        }
        window.location.reload()
    }

    return (
        isShown && (
            <div>
                <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                    <div onClick={onClose} className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                    <div className="flex items-center justify-center min-h-screen">
                        <div className="relative xs-mobile:w-[423px] w-11/12 bg-white mobile:p-6 p-4 rounded-lg"
                            style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                            <h1 className="text-22 font-medium text-black">
                                {t('account.Language')}
                            </h1>

                            {isEmpty(loginLanguage) ? (
                                language.map((item) => (
                                    <div onClick={() => onSubmit(item)}
                                        className={`${(defaultValue?.shortName || browserLanguageData) === item.shortName ? 'bg-rocket' : 'bg-white border border-rocket'} flex items-center justify-center w-full h-[48px] rounded-md mt-5 cursor-pointer`}>
                                        <h1 className={`${(defaultValue?.shortName || browserLanguageData) === item.shortName ? 'text-pistachio' : 'text-rocket'} text-14 font-semibold`}>
                                            {item.name}
                                        </h1>
                                    </div>
                                ))
                            ) : (
                                loginLanguage.map((item) => (
                                    <div onClick={() => onSubmit(item)}
                                        className={`${loginDefaultValue?.name === item.name ? 'bg-rocket' : 'bg-white border border-rocket'} flex items-center justify-center w-full h-[48px] rounded-md mt-5 cursor-pointer`}>
                                        <h1 className={`${loginDefaultValue?.name === item.name ? 'text-pistachio' : 'text-rocket'} text-14 font-semibold`}>
                                            {item.name}
                                        </h1>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}
