import apiClient from '../instance'
import { IActivities, IActivitiesData,
    IAddPointVisitResponse,
    IAddVisitPoint,
    IBadgeTask,
    IEmail,
    IFinalPointRewardsData,
    IHowToEarnPoints, ILandingPageData, ILanguageData,
    ILoginConfig, IMenuItemsData, IOrganisationUnits,
    IPrivacyPolicy, IReferral,
    IRegisterForm,
    IRegisterUser,
    IResetEmail,
    IResetPassword, ISavedPlaces,
    ISignIn,
    IToken,
    IUpdateUserAvatar,
    IUpdateUserForm,
    IUpdateUserPassword,
    IUser,
    IWhiteLabeling } from '../../types/data'
import { ACTIVE_REFERRAL_CAMPAIGN_URL, ACTIVITIES_URL,
    BADGE_TASKS_URL,
    DELETE_MY_ACCOUNT,
    FINAL_POINT_REWARDS_URL,
    HOW_TO_EARN_POINTS_URL, LANDING_PAGE_URL, LANGUAGE_ITEMS_URL,
    LOGIN_CONFIG_URL, MENU_ITEMS_URL, ORGANISATIONAL_UNITS_URL,
    PRIVACY_POLICY_URL, SAVED_PLACES_URL,
    SETTINGS_URL,
    USER_ADD_POINT,
    USER_AVATAR_UPDATE,
    USER_CHANGE_PASSWORD,
    USER_EMAIL_REGISTER,
    USER_RESET_LINK,
    USER_RESET_PASSWORD,
    USER_SIGN_IN,
    USER_UPDATE,
    USER_URL,
    WHITE_LABELING_URL } from '../../urls'
import { getFormData } from '../../utils/helpers'

/**
 * Get user
 */
export const loginApi = async (data: ISignIn): Promise<(IRegisterUser) | null> => {
    try {
        const { data: response } = await apiClient.post<IRegisterUser>(USER_SIGN_IN, {
            hostname: window.location.hostname,
            ...data,
        })

        return response
    } catch (e) {
        throw e
    }
}

export const registerUserEmail = async (
    data: IRegisterForm,
): Promise<(IUser & IToken & IRegisterUser
) | null> => {
    try {
        const { data: response } = await apiClient.post<IUser & IToken & IRegisterUser>(
            USER_EMAIL_REGISTER,
            {
                hostname: window.location.host,
                ...data,
            },
        )
        return response
    } catch (e) {
        throw e
    }
}

export const resetLink = async (data: IResetEmail): Promise<IResetEmail> => {
    try {
        const { data: response } = await apiClient.post<IResetEmail>(USER_RESET_LINK, { ...data, isWeb: true, frontendDomain: window.location.origin })
        return response
    } catch (e) {
        throw e
    }
}

export const resetPassword = async (data: IResetPassword): Promise<IUser> => {
    try {
        const { data: response } = await apiClient.put<IUser>(USER_RESET_PASSWORD, data)
        return response
    } catch (e) {
        throw e
    }
}

export const getBadgeTasks = async (): Promise<IBadgeTask | null> => {
    try {
        const { data: response } = await apiClient.get<IBadgeTask>(BADGE_TASKS_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Update user
 */

export const fetchUserApi = async (): Promise<IUser> => {
    try {
        const { data: response } = await apiClient.get<IUser>(USER_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Add visit points
 * */
export const addVisitPoints = async (data: IAddVisitPoint): Promise<IAddPointVisitResponse> => {
    try {
        const { data: response } = await apiClient.post<IAddPointVisitResponse>(USER_ADD_POINT, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Update user setting
 */
export const updateUserSetting = async (data: IUpdateUserForm): Promise<IUpdateUserForm> => {
    try {
        const { data: response } = await apiClient.patch<IUpdateUserForm>(USER_UPDATE, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Update user password
 */
export const updateUserPassword = async (data: IUpdateUserPassword): Promise<IUpdateUserPassword> => {
    try {
        const { data: response } = await apiClient.put<IUpdateUserPassword>(USER_CHANGE_PASSWORD, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * deleted my account
 */
export const deleteMyAccount = async (): Promise<{ userDelete: boolean } | null> => {
    try {
        const { data: response } = await apiClient.delete<{ userDelete: boolean }>(DELETE_MY_ACCOUNT)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * get privacy policy content
 */
export const getPrivacyPolicy = async (): Promise<IPrivacyPolicy | null> => {
    try {
        const { data: response } = await apiClient.get<IPrivacyPolicy>(PRIVACY_POLICY_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * get how to earn points content
 */
export const getHowToEarnPoints = async (): Promise<IHowToEarnPoints | null> => {
    try {
        const { data: response } = await apiClient.get<IHowToEarnPoints>(HOW_TO_EARN_POINTS_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Update user avatar
 */
export const updateUserAvatar = async (data: IUpdateUserAvatar): Promise<IUpdateUserAvatar> => {
    try {
        const formData = getFormData(data)

        const { data: response } = await apiClient.put<IUpdateUserAvatar>(USER_AVATAR_UPDATE, formData)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * get final point rewards
 */
export const getFinalPointRewards = async (): Promise<IFinalPointRewardsData | null> => {
    try {
        const { data: response } = await apiClient.get<IFinalPointRewardsData>(FINAL_POINT_REWARDS_URL)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * post final points rewards
 */
export const postFinalPointRewards = async (data: { reward: number | string }): Promise<any> => {
    try {
        const { data: response } = await apiClient.post<any>(FINAL_POINT_REWARDS_URL, { reward: data })
        return response
    } catch (e) {
        throw e
    }
}

export const getSettings = async (): Promise<IEmail | null> => {
    try {
        const { data: response } = await apiClient.get<IEmail>(SETTINGS_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getWhiteLabeling = async (): Promise<IWhiteLabeling | null> => {
    try {
        const { data: response } = await apiClient.get<IWhiteLabeling>(WHITE_LABELING_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getLoginConfig = async (domain: string): Promise<ILoginConfig | null> => {
    try {
        const { data: response } = await apiClient.get<ILoginConfig>(LOGIN_CONFIG_URL, { params: { domain } })

        return response
    } catch (e) {
        throw e
    }
}

export const getMenuItems = async (): Promise<IMenuItemsData[] | null> => {
    try {
        const { data: response } = await apiClient.get<IMenuItemsData[]>(MENU_ITEMS_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getLandingPage = async (id: number): Promise<ILandingPageData> => {
    try {
        const { data: response } = await apiClient.get<ILandingPageData>(LANDING_PAGE_URL.replace('{id}', String(id)))

        return response
    } catch (e) {
        throw e
    }
}

export const getLanguageItems = async (): Promise<ILanguageData | null> => {
    try {
        const { data: response } = await apiClient.get<ILanguageData>(LANGUAGE_ITEMS_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getOrganisationalUnitsSearchList = async (text: string): Promise<IOrganisationUnits | null> => {
    try {
        const { data: response } = await apiClient.get<IOrganisationUnits | null>(ORGANISATIONAL_UNITS_URL, {
            params: { text },
        })
        return response
    } catch (e) {
        throw e
    }
}

export const getReferralItems = async (): Promise<IReferral | null> => {
    try {
        const { data: response } = await apiClient.get<IReferral>(ACTIVE_REFERRAL_CAMPAIGN_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const getSavedPlacesItem = async (): Promise<ISavedPlaces | null> => {
    try {
        const { data: response } = await apiClient.get<ISavedPlaces>(SAVED_PLACES_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const postSavedPlacesItem = async (data: ISavedPlaces): Promise<ISavedPlaces | null> => {
    try {
        const { data: response } = await apiClient.post<ISavedPlaces>(SAVED_PLACES_URL, data)

        return response
    } catch (e) {
        throw e
    }
}

export const deleteSavedPlaceItem = async (id: number | string): Promise<any | null> => {
    try {
        const url = `${SAVED_PLACES_URL}/${id}`
        const { data: response } = await apiClient.delete<any>(url)

        return response
    } catch (e) {
        throw e
    }
}

export const editSavedPlaceItem = async (data: ISavedPlaces): Promise<any | null> => {
    try {
        const { id, ...rest } = data
        const { data: response } = await apiClient.put<any>(`${SAVED_PLACES_URL}/${id}`, rest)

        return response
    } catch (e) {
        throw e
    }
}

export const getActivitiesItem = async (): Promise<IActivities | null> => {
    try {
        const { data: response } = await apiClient.get<IActivities>(ACTIVITIES_URL)

        return response
    } catch (e) {
        throw e
    }
}

export const postActivitiesItem = async (data: { activities: IActivitiesData[] }): Promise<IActivitiesData | null> => {
    try {
        const { data: response } = await apiClient.post<IActivitiesData>(ACTIVITIES_URL, data)

        return response
    } catch (e) {
        throw e
    }
}
