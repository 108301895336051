import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { postPerkBookMark } from '../../state/perks/actions'
import { IPerks } from '../../types/data'
import { ArrowBottomIcon, SavedIconActive, SavedIconDefault, StarIcon } from '../common/Svg'
import Skeleton from '../common/Skeleton'
import { readableC02 } from '../../utils/helpers'
import SponsoredByBlock from '../SponsoredByBlock'

interface IProps {
    item: IPerks
}

export default function PerkWeRecommendCard({ item }: IProps) {
    const dispatch = useDispatch()
    const firstImage = item?.perksImages && item?.perksImages.map((items) => items?.image)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const [t] = useTranslation('global')
    // @ts-ignore
    const isTotal = item?.isTotalRating?.new

    const onPressBookmark = async (id: number) => {
        await dispatch(postPerkBookMark(id))
    }

    return (
        <NavLink to={`/lower/swaps/${item?.id}`}>
            <div className="hidden mobile:block">
                <div className="mt-4 flex items-end relative">
                    <div className="w-[484px] h-[191px] bg-white absolute px-5 pt-5 pb-5 rounded-lg ml-10 mb-9 flex flex-col justify-between">
                        <div>
                            <div className="flex mb-2 items-center justify-between">
                                <span className="text-black font tracking-tight font-medium heading-font text-22">
                                    {item?.perkBrand?.discount} {item?.perkBrand?.name}
                                </span>

                                <div onClick={(e) => {
                                    e.preventDefault()
                                    onPressBookmark(item?.id)
                                }} className="rounded-full flex justify-center items-center bg-black hover:text-spinach w-[28px] h-[28px]">
                                    {item?.isBookmark ? (
                                        <SavedIconActive width={12} height={15} />
                                    ) : (
                                        <SavedIconDefault width={12} height={15} />
                                    )}
                                </div>
                            </div>

                            <span className="font-normal text-grey01 text-16">
                                {item?.shortDescription}
                            </span>
                        </div>

                        {item?.ceo2Kg !== 0 ? (
                            <div className="flex items-center">
                                <ArrowBottomIcon width={15} height={15} />

                                <p className="font-semibold not-heading-font text-grey01 ml-1.5 text-12">
                                    {readableC02(item?.ceo2Kg || 0)}
                                </p>
                            </div>
                        ) : (
                            <div className="flex items-center">
                                <StarIcon width={18} height={19} />

                                <p className="font-semibold text-grey01 text-12">
                                    {!isTotal ? Math.floor(Number(item?.totalRating || 0)) : t('lower.new')}
                                </p>
                            </div>
                        )}
                    </div>

                    {loading && <Skeleton className="rounded-lg w-screen h-[560px] object-cover aspect-w-16 aspect-h-9 aspect-video" />}
                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-lg w-screen h-[560px] object-cover aspect-w-16 aspect-h-9 aspect-video`} src={firstImage ? firstImage[0] : ''} alt="" />
                </div>
            </div>

            <div className="cursor-pointer w-full mobile:hidden">
                <div className="mt-3 card" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                    <div className="flex justify-end mr-4">
                        <button onClick={(e) => {
                            e.preventDefault()
                            onPressBookmark(item?.id)
                        }} className="absolute rounded-full flex justify-center items-center mt-4 bg-black w-[28px] h-[28px]">
                            {item?.isBookmark ? (
                                <SavedIconActive width={12} height={15} />
                            ) : (
                                <SavedIconDefault width={12} height={15} />
                            )}
                        </button>
                    </div>

                    {loading && <Skeleton className="rounded-t-lg aspect-w-16 aspect-h-9 aspect-video" />}
                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg aspect-w-16 aspect-h-9 aspect-video`} src={firstImage ? firstImage[0] : ''} alt="" />

                    <div className="rounded-b-lg bg-white">
                        {item?.sponsorshipBlock && <SponsoredByBlock className="px-4 mb-1" fontColor="black" noBackground noBorder item={item?.sponsorshipBlock} />}

                        <h5 className={cn('mb-0.5 font-medium heading-font font tracking-tight text-black text-16 px-4', { 'pt-5': !item?.sponsorshipBlock })}>
                            {item?.perkBrand?.discount} {item?.perkBrand?.name}
                        </h5>

                        <p className="mb-2.5 font-light tracking-tight text-grey01 text-14 px-4">
                            {item?.shortDescription}
                        </p>

                        <div className="flex items-center px-4 pb-4">
                            {item?.ceo2Kg !== 0 ? (
                                <>
                                    <ArrowBottomIcon width={15} height={15} />

                                    <p className="font-semibold not-heading-font text-grey01 ml-1.5 text-12">
                                        {readableC02(item?.ceo2Kg || 0)}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <StarIcon width={18} height={19} />

                                    <p className="font-semibold text-grey01 text-12">
                                        {!isTotal ? Math.floor(Number(item?.totalRating || 0)) : t('lower.new')}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}
