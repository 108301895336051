import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { travelDate } from '../../utils/date'
import { getUser } from '../../state/user/selectors'

type CalendarProps = {
    year: number;
    month: number;
    selectedDays: string[];
    onSelect: (day: number) => void;
    onPrevMonth: () => void;
    onNextMonth: () => void;
};

const daysInMonth = (year: number, month: number): number => new Date(year, month, 0).getDate()

export default function SelectCalendar({ year, month, selectedDays, onSelect, onPrevMonth, onNextMonth }: CalendarProps) {
    const [t] = useTranslation('global')
    const { user } = useSelector(getUser)
    const days = daysInMonth(year, month)
    const firstDayOfMonth = (new Date(year, month - 1, 1).getDay() + 6) % 7

    const daysArray = Array.from({ length: days }, (_, i) => i + 1)
    const emptyDaysArray = Array.from({ length: firstDayOfMonth }, (_, i) => i)

    const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const earliestLoggingDateAllowed = user?.company?.earliestLoggingDateAllowed
        ? new Date(user.company.earliestLoggingDateAllowed)
        : null

    const latestLoggingDateAllowed = user?.company?.latestLoggingDateAllowed
        ? new Date(user.company.latestLoggingDateAllowed)
        : null

    return (
        <div className="w-full max-w-[400px]">
            <div className="flex justify-between items-center mb-4">
                <button
                    className={`${new Date(year, month - 2, 1) < (earliestLoggingDateAllowed || '') ? 'text-grey01' : 'text-rocket'} `}
                    onClick={onPrevMonth}
                    disabled={earliestLoggingDateAllowed ? new Date(year, month - 1, 1) < earliestLoggingDateAllowed : false}>
                    «
                </button>

                <div className="text-18 font-semibold text-black">
                    {t(`monthNames.${monthNames[month - 1]}`)} {year}
                </div>

                <button
                    className={`${new Date(year, month, 1) > (latestLoggingDateAllowed || '') ? 'text-grey01' : 'text-rocket'}`}
                    onClick={onNextMonth}
                    disabled={latestLoggingDateAllowed ? new Date(year, month, 1) > latestLoggingDateAllowed : false}>
                    »
                </button>
            </div>

            <div className="grid grid-cols-7 gap-y-4 gap-x-3">
                {weekDays.map((dayName) => (
                    <div key={dayName} className="text-center font-bold text-black">
                        {t(`weekDays.${dayName}`)}
                    </div>
                ))}

                {emptyDaysArray.map((_, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`empty-${i}`} className="text-center text-gray-300">
                        {' '}
                    </div>
                ))}

                {daysArray.map((day) => {
                    const fullDate = new Date(year, month - 1, day)

                    const normalizedFullDate = new Date(fullDate.setHours(0, 0, 0, 0))
                    const normalizedMinDate = earliestLoggingDateAllowed ? new Date(new Date(earliestLoggingDateAllowed).setHours(0, 0, 0, 0)) : null
                    const normalizedMaxDate = latestLoggingDateAllowed ? new Date(new Date(latestLoggingDateAllowed).setHours(0, 0, 0, 0)) : null

                    const disableDate = ((normalizedMinDate ? normalizedFullDate < normalizedMinDate : false) || (normalizedMaxDate ? normalizedFullDate > normalizedMaxDate : false))

                    return (
                        <button
                            key={day}
                            className={cn(
                                'font-bold text-14 flex items-center justify-center rounded-full w-9 h-9',
                                selectedDays.includes(travelDate(fullDate)) ? 'bg-rocket text-white' : 'bg-white text-rocket',
                                disableDate && 'text-grey01 cursor-not-allowed',
                            )}
                            onClick={() => !disableDate && onSelect(day)}
                            disabled={disableDate}
                        >
                            {day}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}
