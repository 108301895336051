import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '../common/BaseContext'
import { DismissIcon, UnionIcon } from '../common/Svg'
import SecondButton from '../common/SecondButton'
import image from '../../static/quiz-complete.png'
import { IQuizAnswerResponse } from '../../types/data'

interface IProps {
    isShown?: boolean
    onClose: () => void
    width?: boolean
    data?: IQuizAnswerResponse | null
}

export default function PointModal({ width, isShown = true, onClose, data }: IProps) {
    const [t] = useTranslation('global')
    const { screenWidth } = useContext(Context)
    if (!data) return <div />

    return (
        <div>
            {isShown && (
                <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                    <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                    <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                        <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                            <div onClick={onClose} className="mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center mt-[16px]">
                                <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                            </div>

                            <div className="flex items-end justify-center mobile:mt-[116px] mt-[90px]" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.25))' }}>
                                <div className="flex flex-col items-center justify-center absolute mobile:w-[202px] mobile:h-[202px] w-[150px] h-[150px]">
                                    <img
                                        className="w-full h-full"
                                        src={image}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-center pb-[32px]">
                                <div className={`${width ? 'xs-mobile:w-[386px] w-[242px]' : 'xs-mobile:w-[296px] w-[242px]'} flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]`}>
                                    <h1 className="mobile:text-22 text-18 text-darken font-semibold tracking-tight font">
                                        Quiz complete
                                    </h1>

                                    <h1 className="mobile:text-22 text-18 text-darken font-normal tracking-tight font">
                                        {t('global.well-done')}
                                    </h1>

                                    <div className="w-[179px] flex justify-between mt-[12px]">
                                        <div className="flex flex-col items-center">
                                            <h1 className="text-14 text-grey01 font-normal">
                                                {t('global.total-score')}
                                            </h1>

                                            <h1 className="flex text-26 text-spinach font-medium font mt-[-5px]">
                                                {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                                                {Math.round((100 * data?.correctAnswersCount) / data?.allAnswersCount)} <span className="text-18 text-spinach font-medium font text-start">%</span>
                                            </h1>
                                        </div>

                                        <div>
                                            <h1 className="text-14 text-grey01 font-normal tracking-tight">
                                                {t('global.total-points')}
                                            </h1>

                                            <div className="flex items-center justify-center">
                                                <h1 className="mr-[2px] text-26 text-spinach font-medium tracking-tight text-center font">
                                                    {data?.completePoints}
                                                </h1>
                                                <UnionIcon width={12} height={12} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <SecondButton onClick={onClose} text={t('global.continue')} className="mt-[17px] w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
