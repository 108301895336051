import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getUser } from '../../state/user/selectors'
import { statisticsList } from '../../state/travel/selectors'
import { fetchStatisticsEmployee, fetchStatisticsFan } from '../../state/travel/actions'
import { statisticsDate, statisticsDateEmployee } from '../../utils/date'
import { readableCO2 } from '../../utils/helpers'
import Loader from '../common/Loader'

function Employee({ item, languageData }: {item: any, languageData: any}) {
    const [formattedMonth, setFormattedMonth] = useState<string | null>(null)

    useLayoutEffect(() => {
        const fetchData = async () => {
            const formatted = await statisticsDateEmployee(item?.month, languageData)
            setFormattedMonth(formatted)
        }

        fetchData()
        // eslint-disable-next-line
    }, [item?.date, languageData])

    return (
        <p className="text-center text-10 font-bold not-heading-font text-black">
            {formattedMonth}
        </p>
    )
}

function IsFan({ item, languageData }: {item: any, languageData: any}) {
    const [formattedDate, setFormattedDate] = useState<string | null>(null)

    useLayoutEffect(() => {
        const fetchData = async () => {
            const formatted = await statisticsDate(item?.date, languageData)
            setFormattedDate(formatted)
        }

        fetchData()
    }, [item?.date, languageData])

    return (
        <p className="text-center text-10 font-bold not-heading-font text-black">
            {formattedDate}
        </p>
    )
}

export default function TravelGraph() {
    const dispatch = useDispatch()
    const containerRef = useRef(null)
    const { user } = useSelector(getUser)
    const { statisticsFan, statisticsEmployee } = useSelector(statisticsList)
    const [t] = useTranslation('global')
    const lastDateStatisticFan = statisticsFan?.results?.length > 0
        // eslint-disable-next-line no-unsafe-optional-chaining
        ? statisticsFan?.results[statisticsFan?.results?.length - 1]?.userCeo2Kg
        : null
    const lastDateStatisticEmployee = statisticsEmployee?.results?.length > 0
        // eslint-disable-next-line no-unsafe-optional-chaining
        ? statisticsEmployee?.results[statisticsEmployee?.results?.length - 1]?.userCeo2Kg
        : null
    const [selectedHeight, setSelectedHeight] = useState<number | null | string>()
    const [selectedHeightUser, setSelectedHeightUser] = useState<number | null | string>()

    const graphData = user?.company?.isTravelTypeEvent ? statisticsFan : statisticsEmployee
    const currentDate = new Date()
    const monthNumber = currentDate.getMonth() + 1 + 2

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const handleColumnClickUser = (height: string) => {
        setSelectedHeightUser(height)
        setSelectedHeight(0)
    }

    const handleColumnClick = (height: string) => {
        setSelectedHeight(height)
        setSelectedHeightUser(0)
    }

    useEffect(() => {
        // @ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        containerRef?.current?.scrollTo({ left: 76 * graphData?.results?.length, behavior: 'smooth' })
    }, [containerRef, monthNumber, graphData, lastDateStatisticFan, lastDateStatisticEmployee])

    useEffect(() => {
        if (lastDateStatisticFan !== undefined && lastDateStatisticFan !== null && lastDateStatisticFan !== 0) {
            setSelectedHeightUser(readableCO2(lastDateStatisticFan))
            setSelectedHeight(readableCO2(lastDateStatisticFan))
        }

        if (lastDateStatisticEmployee !== undefined && lastDateStatisticEmployee !== null && lastDateStatisticEmployee !== 0) {
            setSelectedHeightUser(readableCO2(lastDateStatisticEmployee))
            setSelectedHeight(readableCO2(lastDateStatisticEmployee))
        }
    }, [user?.company?.isTravelTypeEvent, setSelectedHeight, setSelectedHeightUser, lastDateStatisticFan, lastDateStatisticEmployee])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchStatisticsFan())
        dispatch(fetchStatisticsEmployee())
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        isEmpty(graphData) ? (
            <div className="w-full flex justify-center">
                <Loader width={75} height={75} />
            </div>
        ) : (
            <div className="medium:w-[48%]">
                <div ref={containerRef} className="flex items-start overflow-y-scroll frame">
                    {graphData?.results?.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key="" className={`flex-col ${graphData.results.length > 3 ? 'mr-[26px]' : 'mx-[26px]'} ${graphData.results.length > 3 ? '' : 'flex-1'}`}>
                            <div className="h-[127px] flex items-end justify-center pt-[36px]">
                                {item?.userCeo2Percent !== 0 && (
                                    <div onClick={() => handleColumnClickUser(`user-${index}-${item?.userCeo2Kg}`)} className="w-[100%] cursor-pointer h-[130px] flex flex-col items-center justify-end">
                                        {(selectedHeightUser === `user-${index}-${item?.userCeo2Kg}` || selectedHeightUser === readableCO2(item?.userCeo2Kg)) && (
                                            <p className="text-center text-10 font-semibold text-grey01 mb-[8px] not-heading-font">
                                                {readableCO2(item?.userCeo2Kg)}
                                            </p>
                                        )}

                                        <div
                                            className={`${graphData.results.length > 3 ? 'w-[25px]' : 'w-[100%]'} bg-mint hover:bg-[#A7D49D] hover:text-spinach hover:ease-in hover:duration-200`}
                                            style={{ height: item?.userCeo2Percent }} />
                                    </div>
                                )}

                                {item?.averageCeo2Percent !== 0 && (
                                    <div onClick={() => handleColumnClick(`average-${index}-${item?.averageCeo2Kg}`)} className="w-[100%] cursor-pointer h-[130px] flex flex-col items-center justify-end">
                                        {(selectedHeight === `average-${index}-${item?.averageCeo2Kg}` || selectedHeight === readableCO2(item?.averageCeo2Kg)) && (
                                            <p className="text-center text-10 font-semibold text-grey01 mb-[8px]">
                                                {readableCO2(item?.averageCeo2Kg)}
                                            </p>
                                        )}
                                        <div
                                            className={`${graphData.results.length > 3 ? 'w-[25px]' : 'w-[100%]'} bg-spinach hover:bg-[#24411FFF] hover:ease-in hover:duration-200`}
                                            style={{ height: item?.averageCeo2Percent }} />
                                    </div>
                                )}
                            </div>

                            <div className="text-center mt-[8px]">
                                {user?.company?.isTravelTypeEvent ? <IsFan item={item} languageData={languageData} /> : <Employee item={item} languageData={languageData} />}

                                <p className="text-10 font-normal not-heading-font text-grey01">
                                    {user?.company?.isTravelTypeEvent ? item.teamNameOnImpact : item?.year}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                {graphData?.results?.length > 0 ? (
                    <div className="flex mt-[12px]">
                        <div className="flex items-center mr-[12px]">
                            <div className="w-[12px] h-[12px] bg-mint mr-[6px]" />
                            <p className="font-semibold not-heading-font text-grey01 text-12">{t('me.your-impact')} ({Math.round(user?.company?.isTravelTypeEvent ? statisticsFan?.userTotalCeoKg : statisticsEmployee?.userTotalCeoKg)} kg CO2e)</p>
                        </div>

                        <div className="flex items-center">
                            <div className="w-[12px] h-[12px] bg-spinach mr-[6px]" />
                            {user?.company?.isTravelTypeEvent ? (
                                <p className="font-semibold not-heading-font text-grey01 text-12">{t('me.fan-average')} ({Math.round(statisticsFan?.companyAverageCeoKg)} kg CO2e)</p>
                            ) : (
                                <p className="font-semibold not-heading-font text-grey01 text-12">
                                    {t('me.company-average').replace('[Company]', user?.company?.companyDescriptionImpactSection || t('global.company'))} ({Math.round(statisticsEmployee?.companyAverageCeoKg)} kg CO2e)
                                </p>
                            )}
                        </div>
                    </div>
                ) : (<div />)}
            </div>
        )
    )
}
