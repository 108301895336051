import React, { useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import useOutsideClick from '../../hooks/useOutsideAlerter'
import Navbar from '../layout/Navbar'
import { More, MoreIcon } from '../common/Svg'
import Footer from '../layout/Footer'
import { getUser } from '../../state/user/selectors'
import { Context } from '../common/BaseContext'
import SustainabilityMenu from '../layout/SustainabilityMenu'
import AccountMenuContent from '../AccountMenuContent'
import UploadImageModal from './UploadImageModal'

interface IProps {
    children?: React.ReactNode
    prizeDraw?: boolean
}

export default function AccountSidebar({ children, prizeDraw }: IProps): JSX.Element {
    const { user } = useSelector(getUser)
    const { navbarHeight } = useContext(Context)
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const modalRef = useRef(null)
    const [t] = useTranslation('global')

    const handleCloseModal = () => {
        setOpen(false)
    }

    useOutsideClick(modalRef, handleCloseModal)

    const handleOpenModal = () => {
        setOpen(true)
    }

    return (
        <div className="flex flex-col overflow-y-hidden h-screen justify-between">
            <style>{'html, body {height: 100%; overflow: hidden;}'}</style>
            <Navbar spaceOnBottom={false} />

            <div className="flex overflow-y-hidden">
                {!prizeDraw && (
                    <div style={{ paddingTop: navbarHeight + 32 }}
                        className="hidden px-2 mobile:block tablet:hidden h-full top-0 bg-pistachio w-60 mobile:pt-[52px]">
                        <div onClick={handleOpenModal}
                            className="inline-flex p-2 text-sm text-gray-500 rounded-lg focus:outline-none">
                            <div className="flex flex-col items-center">
                                <MoreIcon width={24} height={24} />

                                <span className="font-semibold tracking-tight text-rocket text-12">
                                    {t('account.more')}
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <div ref={modalRef} style={{ paddingTop: navbarHeight }}
                    className={`overflow-y-scroll top-0 left-0 w-[311px] bg-pistachio pt-[50px] pl-6 text-white fixed h-full z-40 ease-in-out duration-300 ${open ? 'translate-x-0' : '-translate-x-full'}`}>
                    <div className="h-full mt-4 mr-10 py-4">
                        <AccountMenuContent right setShowModal={setShowModal} setLoading={setLoading} loading={loading} />

                        <div className="pb-[80px]" />
                    </div>
                </div>

                <div style={{ paddingTop: navbarHeight + 35 }}
                    className="hidden overflow-y-scroll tablet:block bg-pistachio pl-10 laptop:pl-10 large-laptop:pl-80 laptop:w-[480px] tablet:w-[390px] laptop:grow h-full">
                    <div className="h-full laptop:mr-10 mr-6 py-4">
                        <AccountMenuContent right setShowModal={setShowModal} setLoading={setLoading} loading={loading} />

                        <div className="pb-[80px]" />
                    </div>
                </div>

                <div className="overflow-y-scroll flex flex-col justify-between w-full large-laptop:w-4/6 mobile:pt-[52px] pt-[32px] min-h-screen" style={{ paddingTop: navbarHeight }}>
                    <div className="">
                        {children}
                    </div>

                    <Footer />
                    {user?.company?.menuPosition === 'Bottom' && <SustainabilityMenu isFixed />}
                </div>
            </div>

            {!prizeDraw && !location.pathname.includes('/account') && (
                <div onClick={handleOpenModal} className="flex justify-center items-center">
                    <div
                        className="fixed bottom-[100px] mobile:hidden cursor-pointer flex justify-center items-center w-[100px] h-[48px] bg-rocket rounded-[30px]"
                        style={{ boxShadow: ('2px 3px 0px rgba(0, 0, 0, 0.2') }}>
                        <div className="mr-2">
                            <More width={18} height={18} />
                        </div>

                        <h1 className="text-16 leading-[17.6px] text-white font-semibold font">
                            {t('account.more')}
                        </h1>
                    </div>
                </div>
            )}

            <UploadImageModal showModal={showModal} setShowModal={setShowModal} setLoading={setLoading} />
        </div>
    )
}
