import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DismissIcon } from '../common/Svg'
import { Context } from '../common/BaseContext'
import SecondButton from '../common/SecondButton'

interface IProps {
    isShown?: boolean
    onClose: () => void
    log?: boolean
    setSelectedValues?: any
    title: string
}

export default function InfoModal({ isShown = true, onClose, log, setSelectedValues, title }: IProps) {
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    const onSubmit = () => {
        if (!log) setSelectedValues?.([])
        onClose()
    }

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onSubmit} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className="xs-mobile:w-[296px] w-[242px] flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]">
                                        <h1 className="pb-5 text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                            {title}
                                        </h1>
                                    </div>

                                    <SecondButton onClick={onSubmit} text={t('global.continue')} className="mt-6 w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200 not-heading-font" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
