import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { getPerkDetailLists, getPerkLists } from '../../state/perks/selectors'
import PerkDetailSideBar from '../../components/perk/PerkDetailSideBar'
import { CopyIcon, SavedIconActive, SavedIconDefault, ShareIcon } from '../../components/common/Svg'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { clearPerkReview,
    fetchPerkCommentList,
    fetchPerkDetail,
    fetchPerksByType,
    postPerkBookMark } from '../../state/perks/actions'
import PerkDetailBody from '../../components/perk/PerkDetailBody'
import PerkRateModal from '../../components/perk/PerkRateModal'
import PerkWriteReviewModal from '../../components/perk/PerkWriteReviewModal'
import Pagination from '../../components/common/Pagination'
import PerkBolCouponModal from '../../components/perk/PerkBolCouponModal'
import SponsoredByBlock from '../../components/SponsoredByBlock'

interface IProps{
    perkId?: number | undefined
    modal?: boolean
}

export default function PerkDetail({ perkId, modal }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { detailId } = useParams()
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { user } = useSelector(getUser)
    const { loading, perksByType, perkCommentList } = useSelector(getPerkLists)
    const { perkDetail: perksData } = useSelector(getPerkDetailLists)
    const perkBrandUrl = perksData?.perkBrand?.brandUrl
    const brandUrl = perkBrandUrl?.includes('?')
    const discountCode = perksData?.perkBrand?.discountCode
    const [t] = useTranslation('global')
    const [showBolModal, setShowBolModal] = useState(false)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Swap detail' })
    }, [])

    useEffect(() => {
        if (!perkId) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }, [detailId, perkId])

    const onPressBookmark = async (id: number) => {
        await dispatch(postPerkBookMark(id))
    }

    const navigationPerksByType = (perksId: number) => {
        navigate(`/lower/swaps/swap-by-type/${perksId}`)
    }

    const onRestartLoad = useCallback(() => {
        dispatch(clearPerkReview())
        dispatch(fetchPerkDetail(Number(detailId || perkId)))

        if (!perksByType?.length) dispatch(fetchPerksByType)
        dispatch(fetchPerkCommentList({ perk: Number(perksData?.id), page: 1 }))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const onActionButtonClick = useCallback(() => {
        if (perksData?.buttonActionType === 'show_bol_meal_form_modal') {
            setShowBolModal(true)
        }
    }, [perksData?.buttonActionType])

    const navigation = () => {
        if (perkId) {
            navigate('/lower/swaps')
        } else {
            navigate(-1)
        }
    }

    return (
        <Layout showFooter={modal} showNavbar={modal} showSustainabilityMenu={modal}>
            <div className="w-full">
                {/** ***** DETAIL HEADER ******* */}
                <div className="flex justify-between mt-[-3px]">
                    <PerkDetailSideBar onActionButtonClick={onActionButtonClick} perkId={perkId} />

                    <div className="flex flex-col items-center relative bg-pistachio">
                        <div className="flex justify-end w-full">
                            <Carousel showIndicators autoPlay infiniteLoop interval={3000} showArrows={false}
                                showStatus={false} showThumbs={false}>
                                {perksData?.perksImages?.map((item) => (
                                    <div key={item.id}>
                                        <img className="medium:h-[542px] min-medium:aspect-video object-cover" src={item?.image} alt="" />
                                    </div>
                                ))}
                            </Carousel>
                        </div>

                        <div className="z-50 w-11/12 mobile:h-[320px] mt-[-10%] bg-white relative medium:hidden flex flex-col items-center justify-between mobile:p-8 px-[16px] py-[24px]">
                            <span onClick={navigation}
                                className="tracking-tight italic font-normal cursor-pointer underline underline-offset-2 text-rocket mt-1 text-14">
                                {t('account.Swaps')}
                            </span>

                            <h1 className="text-black tracking-tight font-medium text-26 mobile:text-32 tablet:text-36 text-center line-clamp-2 mb-9 mt-[35.5px]">
                                {perksData?.perkBrand?.discount} {perksData?.perkBrand?.name}
                            </h1>

                            {perksData?.buttonText ? (
                                <div onClick={onActionButtonClick} className="cursor-pointer flex flex-col items-center justify-center bg-lime rounded-lg p-5 min-w-[180px]" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}>
                                    <h1 className="font-medium not-heading-font tracking-tight font text-center text-18 line-clamp-3">
                                        {perksData?.buttonText}
                                    </h1>
                                </div>
                            ) : !perksData?.perkBrand?.brandUrl ? (
                                <CopyToClipboard text={discountCode || ''}>
                                    <div
                                        className="cursor-pointer flex flex-col items-center justify-center bg-lime rounded-lg p-4"
                                        style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}>
                                        <div className="flex items-center">
                                            <h1 className="font-medium not-heading-font tracking-tight font text-18">
                                                {t('lower.redeem-your-20%-off-now')}
                                            </h1>

                                            <div className="ml-2">
                                                <CopyIcon width={16} height={16} />
                                            </div>
                                        </div>

                                        <h1 className="font-normal text-black text-14">
                                            {t('lower.copy-the-code')} <span className="font-semibold">{t('lower.greenshoot')}</span> {t('lower.now')}
                                        </h1>
                                    </div>
                                </CopyToClipboard>
                            ) : (
                                <a href={brandUrl ? `${perksData?.perkBrand?.brandUrl}&pref1=app&pref2=${user?.id}` : perksData?.perkBrand?.brandUrl} target="_blank" rel="noreferrer">
                                    <div className="cursor-pointer flex flex-col items-center justify-center bg-rocket rounded-lg p-5" style={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}>
                                        <div className="flex items-center">
                                            <h1 className="font-medium not-heading-font tracking-tight font text-center text-18 line-clamp-3 text-white">
                                                {t('lower.get')} {perksData?.perkBrand?.discount} {perksData?.perkBrand?.name}
                                            </h1>

                                            <div className="ml-3">
                                                <ShareIcon width={16} height={16} />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )}

                            <div className="flex justify-between mt-6 w-full">
                                <div className="flex">
                                    <PerkRateModal />

                                    <PerkWriteReviewModal />
                                </div>

                                <button onClick={() => onPressBookmark(Number(perksData?.id))}
                                    className="rounded-full hover:ease-in hover:duration-200 hover:text-spinach flex justify-center items-center bg-black w-[28px] h-[28px]">
                                    {perksData?.isBookmark ? (
                                        <SavedIconActive width={12} height={15} />
                                    ) : (
                                        <SavedIconDefault width={12} height={15} />
                                    )}
                                </button>
                            </div>

                            {perksData?.sponsorshipBlock && <SponsoredByBlock className="px-4 mb-1 w-11/12" fontColor="black" item={perksData?.sponsorshipBlock} />}
                        </div>
                    </div>
                </div>
                {/** ***** DETAIL HEADER ******* */}

                {/** ****** DETAIL BODY ******** */}
                <PerkDetailBody />
                {/** ****** DETAIL BODY ******** */}

                {/** *** PERK DETAIL FOOTER **** */}
                <div className="flex medium:flex-row flex flex-col-reverse mt-[59px] mb-[119px]">
                    <div
                        className="tablet:w-[396px] medium:w-[284px] medium:pr-0 xs-mobile:mr-10 xs-mobile:ml-10 ml-5 mr-5">
                        <div className="max-w-[284px] medium:mt-0 mt-10">
                            <h1 className="heading-uppercase font tracking-tight font-medium text-18 text-rocket decoration-4 underline underline-offset-[10px] mb-5">
                                {t('lower.swaps-by-type')}
                            </h1>

                            {perksByType?.map((item) => (
                                <div key={item.id} onClick={() => navigationPerksByType(item.id)}>
                                    <h1 className="mt-2 pb-0.5 cursor-pointer font tracking-tight flex items-center text-black text-18"
                                        style={{ borderBottomWidth: 1, borderBottomColor: whiteLabeling?.avocado }}>
                                        {item.name} ({item.perksCount})
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>

                    {perkCommentList.results.length !== 0 && (
                        <div
                            className="tablet:w-[764px] medium:w-[508px] medium:ml-0 xs-mobile:ml-10 xs-mobile:mr-10 ml-5 mr-5">
                            <h1 className="font tracking-tight font-medium text-18 text-black">
                                {t('lower.reviews')}
                            </h1>

                            {perkCommentList.results.map((item) => (
                                <div key={item?.id} className="mt-4">
                                    <p className="flex font-normal tracking-tight text-grey01 text-14">
                                        <p className="text-14 font-semibold tracking-tight text-grey01">
                                            {item?.ownerFirstName} {item?.ownerLastName?.slice(0, 1)}. &nbsp;
                                        </p>
                                        |&nbsp; {item?.timeAgo}
                                    </p>

                                    <h1 className="mt-1.5 text-16 font-bold text-black">
                                        {item?.headline}
                                    </h1>

                                    <p className="mt-1 font-light text-16 text-grey01">
                                        {item?.details}
                                    </p>

                                    <div className="border-[0.5px] border-avocado mt-5" />
                                </div>
                            ))}

                            <Pagination
                                loading={loading}
                                loadMore={(page) => dispatch(fetchPerkCommentList({
                                    perk: perksData?.id || 0,
                                    page,
                                }))}
                                containerClassName="mt-[60px]"
                                totalCount={perkCommentList.count || 0}
                                currentCount={perkCommentList.results.length} />
                        </div>
                    )}
                </div>
                {/** *** PERK DETAIL FOOTER **** */}
            </div>

            {showBolModal && <PerkBolCouponModal onClose={() => setShowBolModal(false)} />}
        </Layout>
    )
}
