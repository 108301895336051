import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { travelSelector } from '../state/travel/selectors'
import { getUser } from '../state/user/selectors'
import Layout from '../components/layout/Layout'

export default function LogRedirect() {
    const navigate = useNavigate()
    const { gameList } = useSelector(travelSelector)
    const { user } = useSelector(getUser)

    useEffect(() => {
        navigate(((gameList?.filter((i: any) => !i?.isDisabled)?.length === 1 && user?.company?.autoForwardIfSingleEvent) && user?.company?.travelTrackingType !== 'standard') ? '/log' : '/log/track-travel-fan', { replace: true })
    }, [])

    return (
        <Layout showFooter={false}>
            <div />
        </Layout>
    )
}
