import { Dispatch } from 'redux'
import { ILeaderboard, IMyRangeData } from '../../types/data'
import { CLEAR_LEADERBOARD,
    FETCH_LEADERBOARD_ERROR,
    FETCH_LEADERBOARD_START,
    FETCH_MY_RANGE_SUCCESS,
    GET_LEADERBOARD_LIST } from './consts'
import { getLeaderboardList, getMyBadge } from './api'

const fetchLeaderboardStart = () => ({
    type: FETCH_LEADERBOARD_START,
})

export const fetchLeaderboardListSuccess = (response: ILeaderboard) => ({
    type: GET_LEADERBOARD_LIST,
    payload: response,
})

export const fetchClearLeaderboard = () => ({
    type: CLEAR_LEADERBOARD,
})

export const fetchMyRangeSuccess = (response: IMyRangeData) => ({
    type: FETCH_MY_RANGE_SUCCESS,
    payload: response,
})

const fetchEnd = () => ({
    type: FETCH_LEADERBOARD_ERROR,
})

/**
 * Fetch leaderboard list
 * @returns
 */

export const fetchLeaderboard = (params?: { page?: number, leaderboard?: number | string }) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchLeaderboardStart())
        const response = await getLeaderboardList(params)

        if (response) {
            dispatch(fetchLeaderboardListSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchLeaderboard: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch my lex range or below ten list
 * @returns
 */

export const fetchMyRange = (params?: { leaderboard?: number | string }) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchLeaderboardStart())
        const response = await getMyBadge(params)

        if (response) {
            dispatch(fetchMyRangeSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchMyRange: ', e)
        dispatch(fetchEnd())
    }
}
