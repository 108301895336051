import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { ArrowBottomIcon, SavedIconActive, SavedIconDefault, StarIcon } from '../common/Svg'
import { IPerks } from '../../types/data'
import { postPerkBookMark } from '../../state/perks/actions'
import { postHomeBookMark } from '../../state/home/actions'
import Skeleton from '../common/Skeleton'
import { readableC02 } from '../../utils/helpers'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item?: IPerks
    type?: 'home' | 'perk'
}

export default function PerkCard({ item, type }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const firstImage = item?.perksImages && item?.perksImages.map((items) => items?.image)
    const [loading, setLoading] = useState(true)
    const handleImageLoad = () => setLoading(false)
    const [t] = useTranslation('global')
    // @ts-ignore
    const isTotal = item?.isTotalRating?.new

    const onPressBookmark = async () => {
        if (!item) return

        if (type === 'perk') await dispatch(postPerkBookMark(item?.id))
        if (type === 'home') await dispatch(postHomeBookMark(item?.id))
    }

    const navigatePerkDetail = () => {
        if (item) navigate(`/lower/swaps/${item.id}`)
    }

    return (
        <div className="cursor-pointer relative" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="flex justify-end mr-3">
                <div onClick={onPressBookmark} className="absolute rounded-full flex justify-center items-center mt-3 bg-black w-[28px] h-[28px]">
                    {item?.isBookmark ? (
                        <SavedIconActive width={12} height={15} />
                    ) : (
                        <SavedIconDefault width={12} height={15} />
                    )}
                </div>
            </div>

            <div onClick={navigatePerkDetail}>
                {loading && <Skeleton className="rounded-t-lg w-full aspect-w-16 aspect-h-9 aspect-video object-cover" />}
                <img onLoad={handleImageLoad} src={firstImage ? firstImage[0] : ''} className={`${loading ? 'hidden' : 'block'} rounded-t-lg w-full aspect-w-16 aspect-h-9 aspect-video object-cover`} alt="" />

                <style>
                    {`
                        .line-clamp-two {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                        }
                    `}
                </style>

                <div className="flex flex-col justify-between rounded-b-lg bg-white min-h-[102px] hover:bg-[#F2F2F2] hover:drop-shadow-[0.3] hover:delay-200">{item?.sponsorshipBlock && (<SponsoredByBlock className="px-4 mb-1" fontColor="black" noBackground noBorder item={item?.sponsorshipBlock} />)}

                    <button className={cn('line-clamp-two text-left mb-0.5 mx-3 font-medium font tracking-tight text-black text-15 leading-[132%] mobile:mt-3', { 'mt-3': !item?.sponsorshipBlock })}>
                        {loading ? (
                            <>
                                <Skeleton className="w-full mr-[45%] h-[13px] rounded-sm mt-2" />
                                <Skeleton className="w-8/12 mr-[45%] h-[13px] rounded-sm mt-2" />
                            </>
                        ) : (
                            <ContentBlockTitle text={`${item?.perkBrand?.discount} ${item?.perkBrand?.name}`} />
                        )}
                    </button>

                    <div className="flex items-center mx-3 mb-3">
                        {loading ? (
                            <Skeleton className="w-full mr-[75%] h-[10px] rounded-sm" />
                        ) : (
                            item?.ceo2Kg !== 0 ? (
                                <div className="flex items-center">
                                    <ArrowBottomIcon width={15} height={15} />

                                    <p className="font-semibold not-heading-font text-grey01 ml-1.5 text-12">
                                        {readableC02(item?.ceo2Kg || 0)}
                                    </p>
                                </div>
                            ) : (
                                <div className="flex items-center">
                                    <StarIcon width={18} height={19} />

                                    <p className="font-semibold not-heading-font text-grey01 text-12">
                                        {!isTotal ? Math.floor(Number(item?.totalRating || 0)) : t('lower.new')}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
