import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import lodash from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { ArrowRight, Plus } from '../components/common/Svg'
import { travelSelector } from '../state/travel/selectors'
import { fetchRecentRoutes, fetchSavedRoutes } from '../state/travel/actions'
import { getUser } from '../state/user/selectors'
import Layout from '../components/layout/Layout'
import { getDateTime } from '../utils/date'
import { displayDistanceWithUnit, replacePartsOfText } from '../utils/helpers'
import { Context } from '../components/common/BaseContext'
import SponsoredByBlock from '../components/SponsoredByBlock'
import { getHomePageLists } from '../state/home/selectors'
import { clearTravelData, logSavedCommute, saveCommuteInstanceToLocalstorage } from '../utils/travel'
import { ILeg } from '../types/data'
import DropdownLog from '../components/common/DropDown'
import InfoModal from '../components/travel/InfoModal'

export default function Log() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { navbarHeight, screenWidth, isEmbedded, distanceUnit } = useContext(Context)
    const { pathname } = useLocation()
    const location = useLocation()
    const isSwitched = location?.state?.isSwitched
    const { user } = useSelector(getUser)
    const { recentRoutes, savedRoutes, gameList, loading } = useSelector(travelSelector)
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const [t] = useTranslation('global')
    const [showModalPoint, setShowModalPoint] = useState<boolean | undefined>(false)
    const [destination, setDestination] = useState<any>()
    const [nameLog, setNameLog] = useState<string>('')
    const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchSavedRoutes())
        dispatch(fetchRecentRoutes())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Log' })
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (((gameList?.filter((i: any) => !i?.isDisabled))?.length === 1 && user?.company?.autoForwardIfSingleEvent) && user?.company?.travelTrackingType !== 'standard') {
            clearTravelData(dispatch)
            const activeGame = gameList?.find((i: any) => !i?.isDisabled)

            localStorage.setItem('travelData', JSON.stringify({
                date: [activeGame?.date],
                gameDate: [activeGame?.date],
                gameIds: activeGame ? [activeGame.id] : [],
                legs: [],
                gameData: activeGame ? [{ id: activeGame?.id, item: { ...activeGame } }] : [],
                traveledWithOthersWhoDidntLogCount: 0,
            }))

            if (user.company?.travelLogByFavourite === 'Hidden' && user?.company?.travelLogByRecent === 'Hidden') {
                navigate('/log/track-travel-start-end', { state: { prevUrl: location?.state?.prevUrl, isSwitched } })
            }
        }
        // eslint-disable-next-line
    }, [gameList, dispatch])

    const onContinue = (commute: any, name: string) => {
        if (loading) return

        setNameLog(name)

        setDestination(commute)
        if (commute?.endPlaceId !== travelData?.gameData[0]?.item?.placeId) {
            setShowModalPoint(true)
        } else {
            logCommute(commute)
        }
    }

    const logCommute = (commute: any) => {
        if (loading) return

        logSavedCommute(() => null, dispatch, travelData.date, commute, travelData?.gameIds, travelData?.gameDate, navigate)
    }

    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const onNewJourney = () => {
        const gameData = travelData?.gameData?.[0]
        const unsavedCommute = gameData?.item?.unsavedCommute

        if (unsavedCommute && unsavedCommute?.id) {
            saveCommuteInstanceToLocalstorage(unsavedCommute, {
                gameIds: [gameData?.id],
                gameData: [gameData],
                gameDate: [gameData?.item?.date],
                backendCommuteId: unsavedCommute?.id,
                date: unsavedCommute?.dates,
            }, {})

            if (unsavedCommute.legs.filter((i: ILeg) => i.isGoingBack).length) {
                navigate('/log/track-travel-overview', { state: { oneWay: false } })
                return
            }

            if (unsavedCommute.legs.length) {
                navigate('/log/track-travel-overview')
                return
            }

            navigate('/log/track-travel-start-end', { state: { prevUrl: location?.state?.prevUrl, disableAutoSubmit: true, isSwitched } })
            return
        }

        navigate('/log/track-travel-start-end', { state: { prevUrl: location?.state?.prevUrl, disableAutoSubmit: true, isSwitched } })
    }

    const columns = [
        {
            position: user?.company?.travelLogByNewJourneyPosition || 3,
            component: (
                <div className="cursor-pointer">
                    <div className="flex items-center justify-between pt-[15px]" onClick={onNewJourney}>
                        <h1 className="font-medium not-heading-font text-cavolo text-16">
                            {t('log.log-a-new-journey')}
                        </h1>

                        <ArrowRight />
                    </div>

                    <div className="mt-2.5 border-b border-solid border-avocado" />
                </div>
            ),
        },
        {
            position: user?.betaTesting ? 1.5 : '-',
            component: (
                <>
                    <div>
                        <div className="flex items-center justify-between mt-[21px]">
                            <h1 className="font-medium not-heading-font text-cavolo text-18">
                                {t('log.record-now-with-location-services')}
                            </h1>

                            <div className="flex items-center">
                                <div className="flex items-center h-[20px] bg-[#FF6337] px-[14px] mr-[20px] rounded">
                                    <h1 className="text-12 font-normal text-[#00362B]">
                                        {t('log.beta')}
                                    </h1>
                                </div>

                                <ArrowRight />
                            </div>
                        </div>

                        <div className="mt-2.5 border-b border-solid border-avocado" />
                    </div>

                    <div>
                        <div className="flex items-center justify-between mt-[21px]">
                            <h1 className="font-medium not-heading-font text-cavolo text-18">
                                {t('log.talk-to')} {user?.company?.chatbotName}, {t('log.our-AI-chatbot')}
                            </h1>

                            <div className="flex items-center">
                                <div className="flex items-center h-[20px] bg-[#FF6337] px-[14px] mr-[20px] rounded">
                                    <h1 className="text-12 font-normal text-[#00362B]">
                                        {t('log.beta')}
                                    </h1>
                                </div>

                                <ArrowRight />
                            </div>
                        </div>

                        <div className="mt-2.5 border-b border-solid border-avocado" />
                    </div>
                </>
            ),
        },
        {
            position: user?.company?.travelLogByFavourite !== 'Hidden' ? user.company.travelLogByFavouritePosition || 3 : '-',
            component: (
                <DropdownLog
                    openOrClose={user?.company?.travelLogByFavourite === 'enabled_collapsed'}
                    favourite={user?.company?.travelLogByFavourite === 'Disabled'}
                    title={t('log.log-a-favourite-journey')}
                    body={(
                        <div className={`${savedRoutes.length > 0 ? 'mt-[0px]' : 'mt-[10px]'}`}>
                            {savedRoutes.length > 0 ? (
                                <>
                                    {savedRoutes?.slice(0, 3)?.map((item) => (
                                        <div key={item?.id} className="flex items-center justify-between w-full h-[42px] bg-text px-1 rounded mt-[10px]">
                                            <div className="w-[25%] flex justify-start">
                                                <h1 className="text-14 text-black not-heading-font">
                                                    {item?.name}
                                                </h1>
                                            </div>

                                            <div className="w-[49.9%] flex justify-center">
                                                <h1 className="text-14 text-grey01 font-normal">
                                                    {displayDistanceWithUnit(item.commute.distanceInKm || 0, distanceUnit, t)} {t('global.by')}&nbsp;
                                                    {lodash.uniq(item?.commute?.legs?.map((i) => i?.transport?.name)).join(', ')}
                                                </h1>
                                            </div>

                                            <div className="w-[25%] flex justify-end">
                                                <div
                                                    onClick={() => item?.commute.startPlaceId && (travelData?.date && user?.company?.travelTrackingType === 'event' ? onContinue(item.commute, 'favourite') : logCommute(item.commute))}
                                                    className={`w-[33px] h-[33px] flex items-center justify-center ${item?.commute.startPlaceId && !loading ? 'bg-rocket' : 'bg-grey02'}  rounded ${item?.commute.startPlaceId && 'cursor-pointer'}`}
                                                    style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                                                    <Plus />
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                    {savedRoutes?.length > 3 && (
                                        <h1 onClick={() => navigate('/log/track-travel-saved-routes')}
                                            className="my-3 text-14 font-semibold not-heading-font text-black underline underline-offset-2 cursor-pointer">
                                            {t('log.more')}
                                        </h1>
                                    )}
                                </>

                            ) : (
                                <h1 className="text-14 text-black pb-2.5">
                                    {t('global.no-favourite-journeys-available-log-a-new-journey-and-save-as-favourite-on-the-summary-screen')}
                                </h1>
                            )}
                        </div>
                    )}
                />
            ),
        },
        {
            position: user?.company?.travelLogByRecent !== 'Hidden' ? user.company?.travelLogByRecentPosition || 3 : '-',
            component: (
                <DropdownLog
                    openOrClose={user?.company?.travelLogByRecent === 'enabled_collapsed'}
                    recent={user?.company?.travelLogByRecent === 'Disabled'}
                    title={t('log.log-a-recent-journey')}
                    body={(
                        <div className={`${recentRoutes.length > 0 ? 'mt-[0px]' : 'mt-[10px]'}`}>
                            {recentRoutes?.length > 0 ? (
                                <>
                                    {recentRoutes?.slice(0, 3)?.map((item) => (
                                        <div key={item?.id} className="flex items-center justify-between w-full h-[42px] bg-text px-1 rounded mt-[10px]">
                                            <div className="w-[25%] flex justify-start">
                                                <h1 className="text-14 text-black not-heading-font">
                                                    {getDateTime(item?.dates?.[0] || item.date || undefined)}
                                                </h1>
                                            </div>

                                            <div className="w-[49.9%] flex justify-center">
                                                <h1 className="text-14 text-grey01 font-normal">
                                                    {displayDistanceWithUnit(item.distanceInKm || 0, distanceUnit, t)} {t('global.by')}&nbsp;
                                                    {lodash.uniq(item?.legs?.map((i) => i?.transport?.name)).join(', ')}
                                                </h1>
                                            </div>

                                            <div className="w-[25%] flex justify-end">
                                                <div
                                                    onClick={() => item?.startPlaceId && (travelData?.date && user?.company?.travelTrackingType === 'event' ? onContinue(item, 'recent') : logCommute(item))}
                                                    className={`w-[33px] h-[33px] flex items-center justify-center ${item?.startPlaceId && !loading ? 'bg-rocket' : 'bg-grey02'}  rounded ${item?.startPlaceId && 'cursor-pointer'}`}
                                                    style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
                                                    <Plus />
                                                </div>
                                            </div>
                                        </div>
                                    )) }

                                    {recentRoutes?.length > 3 && (
                                        <h1 onClick={() => navigate('/log/track-travel-recent-routes')}
                                            className="my-3 text-14 font-semibold text-black underline underline-offset-2 cursor-pointer">
                                            {t('log.more')}
                                        </h1>
                                    )}
                                </>

                            ) : (
                                <h1 className="text-14 text-black pb-2.5">
                                    {t('log.no-recent-journeys-saved')}
                                </h1>
                            )}

                        </div>
                    )}
                />
            ),
        },
    ].filter((i) => i.position !== '-').sort((a: any, b: any) => a.position - b.position)

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded}>
            <div className="w-full">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div
                    style={{ marginTop: screenWidth > 768 ? navbarHeight : 0 }}
                    className={`flex flex-col items-center ${screenWidth < 768 && 'pt-5'} mobile:px-[42px] px-4 h-screen`}>
                    <div className="max-w-[600px] w-full pb-[300px]">
                        <h1 className="heading-uppercase text-22 font mt-1.5 tracking-tight font-semibold text-cavolo mb-4">
                            {t('log.log-a-journey')}
                        </h1>

                        {columns.map((i) => i.component)}
                    </div>
                </div>
            </div>

            <InfoModal
                isShown={showModalPoint}
                onClose={() => setShowModalPoint(false)}
                log
                title={replacePartsOfText(t('log.log-route-end-place-invalid-error-message'), {
                    '[travel-event-name]': user?.company?.travelEventName || '',
                    '[fan-company-game]': travelData?.gameData?.[0]?.item?.place ? travelData.gameData[0].item.place.slice(0, travelData.gameData[0].item.place.indexOf(',')) : '',
                    '[saved-favourite-destination]': destination?.endPlaceName ? destination.endPlaceName.slice(0, destination.endPlaceName.indexOf(',')) : '',
                    '[recent/favourite]': nameLog,
                })} />
        </Layout>
    )
}
