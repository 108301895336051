import { Dispatch } from 'redux'
import { FETCH_USER_ERROR,
    FETCH_USER_START,
    LOGIN_SUCCESS,
    SIGN_OUT,
    FETCH_USER_SUCCESS,
    FETCH_USER_REGISTER_SUCCESS,
    FETCH_USER_RESET_PASSWORD_SUCCESS,
    CHANGE_USER_POINTS_SUCCESS,
    FETCH_BADGE_TASKS_SUCCESS,
    FETCH_USER_ADD_VISIT_POINTS,
    FETCH_USER_UPDATE_SETTING_SUCCESS,
    FETCH_USER_UPDATE_PASSWORD_SUCCESS,
    FETCH_PRIVACY_POLICY_SUCCESS,
    FETCH_HOW_TO_EARN_POINTS_SUCCESS,
    FETCH_USER_AVATAR_UPDATE_SUCCESS,
    FETCH_FINAL_POINT_REWARDS_SUCCESS,
    POST_FINAL_POINTS_REWARDS_SUCCESS,
    FETCH_SETTINGS_SUCCESS,
    FETCH_WHITE_LABELING_SUCCESS,
    FETCH_LOGIN_CONFIG_SUCCESS,
    FETCH_MENU_ITEMS_SUCCESS,
    FETCH_LANDING_PAGE_SUCCESS,
    FETCH_LANGUAGE_ITEMS_SUCCESS,
    FETCH_LOGIN_CONFIG_START,
    GET_ORGANISATIONAL_UNITS_SEARCH_LIST,
    FETCH_REFERRAL_ITEMS_SUCCESS,
    FETCH_SAVED_PLACES_SUCCESS,
    POST_SAVED_PLACES_SUCCESS,
    EDIT_SAVED_PLACES_SUCCESS,
    DELETE_SAVED_PLACES_SUCCESS,
    FETCH_ACTIVITIES_SUCCESS,
    POST_ACTIVITIES_SUCCESS } from './consts'
import { addVisitPoints,
    deleteMyAccount, deleteSavedPlaceItem, editSavedPlaceItem,
    fetchUserApi,
    getActivitiesItem,
    getBadgeTasks,
    getFinalPointRewards,
    getHowToEarnPoints,
    getLandingPage,
    getLanguageItems,
    getLoginConfig,
    getMenuItems, getOrganisationalUnitsSearchList,
    getPrivacyPolicy, getReferralItems, getSavedPlacesItem,
    getSettings,
    getWhiteLabeling,
    loginApi, postActivitiesItem,
    postFinalPointRewards, postSavedPlacesItem,
    registerUserEmail,
    resetLink,
    resetPassword,
    updateUserAvatar,
    updateUserPassword,
    updateUserSetting } from './api'
import { IActivities, IActivitiesData,
    IAddPointVisitResponse,
    IAddVisitPoint,
    IBadgeTask,
    IEmail,
    IFinalPointRewardsData,
    IHowToEarnPoints,
    ILandingPageData, ILanguageData,
    ILoginConfig,
    IMenuItemsData, IOrganisationUnits,
    IPrivacyPolicy, IReferral,
    IRegisterForm, IRegisterUser,
    IResetEmail,
    IResetPassword, ISavedPlaces,
    ISignIn,
    IUpdateUserAvatar,
    IUpdateUserForm,
    IUpdateUserPassword,
    IUser,
    IWhiteLabeling } from '../../types/data'

const fetchUserStart = () => ({
    type: FETCH_USER_START,
})

const fetchLoginConfigStart = () => ({
    type: FETCH_LOGIN_CONFIG_START,
})

export const loginSuccess = (user: IRegisterUser) => ({
    type: LOGIN_SUCCESS,
    payload: user,
})

export const fetchUserSuccess = (user: IUser) => ({
    type: FETCH_USER_SUCCESS,
    payload: user,
})

const fetchEnd = () => ({
    type: FETCH_USER_ERROR,
})

const signOut = () => ({
    type: SIGN_OUT,
    payload: null,
})

export const fetchUserRegisterSuccess = (user: IUser) => ({
    type: FETCH_USER_REGISTER_SUCCESS,
    payload: user,
})

export const updateUserPoint = (response: number | string | undefined) => ({
    type: CHANGE_USER_POINTS_SUCCESS,
    payload: response,
})

export const fetchUserResetLinkSuccess = () => ({
    type: FETCH_USER_RESET_PASSWORD_SUCCESS,
})

export const fetchUserAddVisitPointSuccess = (user: IAddPointVisitResponse) => ({
    type: FETCH_USER_ADD_VISIT_POINTS,
    payload: user,
})

export const fetchBadgeTasksSuccess = (response: IBadgeTask) => ({
    type: FETCH_BADGE_TASKS_SUCCESS,
    payload: response,
})

export const fetchUpdateUserSettingSuccess = (user: IUpdateUserForm) => ({
    type: FETCH_USER_UPDATE_SETTING_SUCCESS,
    payload: user,
})

export const fetchUpdateUserPasswordSuccess = (user: IUpdateUserPassword) => ({
    type: FETCH_USER_UPDATE_PASSWORD_SUCCESS,
    payload: user,
})

export const fetchPrivacyPolicySuccess = (response: IPrivacyPolicy) => ({
    type: FETCH_PRIVACY_POLICY_SUCCESS,
    payload: response,
})

export const fetchHowToEarnPointsSuccess = (response: IHowToEarnPoints) => ({
    type: FETCH_HOW_TO_EARN_POINTS_SUCCESS,
    payload: response,
})

export const fetchUserAvatarUpdateSuccess = (user: IUpdateUserAvatar) => ({
    type: FETCH_USER_AVATAR_UPDATE_SUCCESS,
    payload: user,
})

export const fetchFinalPointRewardsSuccess = (response: IFinalPointRewardsData) => ({
    type: FETCH_FINAL_POINT_REWARDS_SUCCESS,
    payload: response,
})

export const postFinalPointRewardsSuccess = (user: IUser) => ({
    type: POST_FINAL_POINTS_REWARDS_SUCCESS,
    payload: user,
})

export const fetchSettingsSuccess = (response: IEmail) => ({
    type: FETCH_SETTINGS_SUCCESS,
    payload: response,
})

export const fetchWhiteLabelingSuccess = (response: IWhiteLabeling) => ({
    type: FETCH_WHITE_LABELING_SUCCESS,
    payload: response,
})

export const fetchLoginConfigSuccess = (response: ILoginConfig) => ({
    type: FETCH_LOGIN_CONFIG_SUCCESS,
    payload: response,
})

export const fetchLandingPageSuccess = (response: ILandingPageData) => ({
    type: FETCH_LANDING_PAGE_SUCCESS,
    payload: response,
})

export const fetchMenuItemsSuccess = (response: IMenuItemsData[]) => ({
    type: FETCH_MENU_ITEMS_SUCCESS,
    payload: response,
})

export const fetchLanguageItemsSuccess = (response: ILanguageData) => ({
    type: FETCH_LANGUAGE_ITEMS_SUCCESS,
    payload: response,
})

const fetchOrganisationalUnitsSearchSuccess = (response: IOrganisationUnits) => ({
    type: GET_ORGANISATIONAL_UNITS_SEARCH_LIST,
    payload: response,
})

export const fetchReferralItemsSuccess = (response: IReferral) => ({
    type: FETCH_REFERRAL_ITEMS_SUCCESS,
    payload: response,
})

export const fetchSavedPlacesSuccess = (response: ISavedPlaces) => ({
    type: FETCH_SAVED_PLACES_SUCCESS,
    payload: response,
})

export const postSavedPlacesSuccess = (response: ISavedPlaces) => ({
    type: POST_SAVED_PLACES_SUCCESS,
    payload: response,
})

export const deleteSavedPlacesSuccess = (response: any) => ({
    type: DELETE_SAVED_PLACES_SUCCESS,
    payload: response,
})

export const editSavedPlacesSuccess = (response: ISavedPlaces) => ({
    type: EDIT_SAVED_PLACES_SUCCESS,
    payload: response,
})

export const fetchActivitiesSuccess = (response: IActivities) => ({
    type: FETCH_ACTIVITIES_SUCCESS,
    payload: response,
})

export const postActivitiesSuccess = (response: IActivitiesData) => ({
    type: POST_ACTIVITIES_SUCCESS,
    payload: response,
})

/**
 * Fetch user
 * @returns
 */
export const login = (
    data: ISignIn,
    onSuccess: () => void,
    setError?: (value: string) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await loginApi(data)

        if (response) {
            dispatch(loginSuccess(response))
            onSuccess()
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        console.log("User haven't been loaded!", e)
        dispatch(fetchEnd())

        if (setError) {
            setError(e.response.data)
        }
    }
}

export const fetchUserResetLink = (
    data: IResetEmail,
    onSuccess: () => void,
    setError?: (value: string) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await resetLink(data)

        if (response) {
            dispatch(fetchUserResetLinkSuccess())
            onSuccess()
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
        if (setError) {
            setError(e.response.data)
        }
    }
}

export const fetchUserRegisterEmail = (
    data: IRegisterForm,
    onSuccess: (response: any) => void,
    setError?: (value: string) => void,
    statusCode?: (value: number) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await registerUserEmail(data)

        if (response) {
            dispatch(fetchUserRegisterSuccess(response))
            onSuccess(response)
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        console.log(e)
        dispatch(fetchEnd())
        if (statusCode) {
            statusCode(e?.response?.status)
        }

        if (setError) {
            setError(e?.response?.data)
        }
    }
}

export const fetchUser = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await fetchUserApi()

        if (response) {
            dispatch(fetchUserSuccess(response))
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        console.log("User haven't been loaded!", e)
        dispatch(fetchEnd())
    }
}

export const fetchUserResetPassword = (
    data: IResetPassword,
    onSuccess: () => void,
    setError?: (value: string) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await resetPassword(data)
        if (response) {
            dispatch(fetchUserRegisterSuccess(response))
            onSuccess()
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
        if (setError) {
            setError(e.response.data)
        }
    }
}

/**
 * SignOut
 * @returns
 */
export const signOutUser = (onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(signOut())
        onSuccess()
    } catch {
        console.log('Error signOutUser')
    }
}

export const fetchBadgeTasks = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getBadgeTasks()

        if (response) {
            dispatch(fetchBadgeTasksSuccess(response))
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        dispatch(fetchEnd())
    }
}

/**
 * user reset password
 * @returns
 */

export const fetchAddVisitPoints = (data: IAddVisitPoint, res: (response: IAddPointVisitResponse) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await addVisitPoints(data)

        if (response) {
            dispatch(fetchUserAddVisitPointSuccess(response))
            dispatch(updateUserPoint(response.thisMonthPoints))
            res(response)
        }
    } catch (e) {
        console.log('ERROR, fetchAddVisitPoints: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch update user setting
 * @returns
 */
export const fetchUpdateUserSetting = (data: IUpdateUserForm) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await updateUserSetting(data)

        if (response) {
            dispatch(fetchUpdateUserSettingSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchUpdateUserSetting: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch update user password
 * @returns
 */
export const fetchUpdateUserPassword = (
    data: IUpdateUserPassword,
    onSuccess: () => void,
    setError?: (value: string) => void,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await updateUserPassword(data)

        if (response) {
            dispatch(fetchUpdateUserPasswordSuccess(response))
            onSuccess()
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        console.log('ERROR, fetchUpdateUserPassword: ', e)
        dispatch(fetchEnd())

        if (setError) {
            setError(e.response.data)
        }
    }
}

/**
 * delete my account
 * @returns
 */
export const deleteAccount = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        await deleteMyAccount()
        dispatch(fetchEnd())
        dispatch(signOut())
    } catch {
        dispatch(fetchEnd())
    }
}

/**
 * fetch privacy policy
 * @returns
 */
export const fetchPrivacyPolicy = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getPrivacyPolicy()

        if (response) {
            dispatch(fetchPrivacyPolicySuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchPrivacyPolicy: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * fetch how to earn points
 * @returns
 */
export const fetchHowToEarnPoints = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getHowToEarnPoints()

        if (response) {
            dispatch(fetchHowToEarnPointsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchHowToEarnPoints: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch user avatar update
 * @returns
 */
export const fetchUserAvatarUpdate = (data: IUpdateUserAvatar) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await updateUserAvatar(data)

        if (response) {
            dispatch(fetchUserAvatarUpdateSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchUserAvatarUpdate: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * fetch final point rewards
 * @returns
 */
export const fetchFinalPointRewards = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getFinalPointRewards()

        if (response) {
            dispatch(fetchFinalPointRewardsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchFinalPointRewards: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Post final points rewards
 * @returns
 */
export const finalPointRewards = (data: { reward: number | string }, onSuccess: () => void) => async (
    dispatch: Dispatch,
) => {
    try {
        dispatch(fetchUserStart())
        const response = await postFinalPointRewards(data)

        if (response) {
            dispatch(postFinalPointRewardsSuccess(response))
            onSuccess()
        }
        // @ts-ignore
    } catch (e: AxiosError) {
        console.log('ERROR, fetchFinalPointRewards: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchSettings = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getSettings()

        if (response) {
            dispatch(fetchSettingsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSettings: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchWhiteLabeling = (onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getWhiteLabeling()

        if (response) {
            dispatch(fetchWhiteLabelingSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('ERROR, fetchSettings: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchLoginConfig = (domain: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchLoginConfigStart())
        const response = await getLoginConfig(domain)

        if (response) {
            dispatch(fetchLoginConfigSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchLoginConfig: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchMenuItems = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getMenuItems()

        if (response) {
            dispatch(fetchMenuItemsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSettings: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchLandingPage = (id: number) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getLandingPage(id)

        if (response) {
            dispatch(fetchLandingPageSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchLoginConfig: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchLanguageItems = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getLanguageItems()

        if (response) {
            dispatch(fetchLanguageItemsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchLanguageItems: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchOrganisationalUnitsList = (text: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())

        const response = await getOrganisationalUnitsSearchList(text)

        if (response) {
            dispatch(fetchOrganisationalUnitsSearchSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchOrganisationalUnitsList: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchReferral = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getReferralItems()

        if (response) {
            dispatch(fetchReferralItemsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchReferral: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchSavedPlaces = async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getSavedPlacesItem()

        if (response) {
            dispatch(fetchSavedPlacesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSavedPlaces: ', e)
        dispatch(fetchEnd())
    }
}

export const postSavedPlaces = (data: ISavedPlaces) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await postSavedPlacesItem(data)

        if (response) {
            dispatch(postSavedPlacesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSavedPlaces: ', e)
        dispatch(fetchEnd())
    }
}

export const deleteSavedPlace = (id: number | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await deleteSavedPlaceItem(id)

        if (response) {
            dispatch(deleteSavedPlacesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSavedPlaces: ', e)
        dispatch(fetchEnd())
    }
}

export const editSavedPlace = (data: ISavedPlaces) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await editSavedPlaceItem(data)

        if (response) {
            dispatch(editSavedPlacesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSavedPlaces: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchActivities = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await getActivitiesItem()

        if (response) {
            dispatch(fetchActivitiesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchActivities: ', e)
        dispatch(fetchEnd())
    }
}

export const postActivities = (data: { activities: IActivitiesData[] }) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchUserStart())
        const response = await postActivitiesItem(data)

        if (response) {
            dispatch(postActivitiesSuccess(response))
            // @ts-ignore
            dispatch(fetchActivities())
        }
    } catch (e) {
        console.log('ERROR, fetchActivities: ', e)
        dispatch(fetchEnd())
    }
}
