import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IRecipe } from '../../types/data'
import { ArrowBottomIcon, SavedIconActive, SavedIconDefault } from '../common/Svg'
import { postRecipeBookmark } from '../../state/recipe/actions'
import Skeleton from '../common/Skeleton'
import { readableC02 } from '../../utils/helpers'
import SponsoredByBlock from '../SponsoredByBlock'
import ContentBlockTitle from '../ContentBlockTitle'

interface IProps {
    item: IRecipe
}

export default function HomeRecipeWeLoveCard({ item }: IProps) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const handleImageLoad = () => setLoading(false)

    const onPressBookmark = async (id: number) => {
        await dispatch(postRecipeBookmark(id))
    }

    const navigateRecipeDetail = () => {
        navigate(`/lower/recipes/${item?.id}`)
    }

    return (
        <div className="cursor-pointer w-full" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}>
            <div className="mt-3 card">
                <div className="flex justify-end mr-4">
                    <div onClick={(e) => {
                        e.preventDefault()
                        onPressBookmark(item?.id)
                    }} className="absolute rounded-full flex justify-center items-center mt-4 bg-black w-[28px] h-[28px]">
                        {item?.isBookmark ? (
                            <SavedIconActive width={12} height={15} />
                        ) : (
                            <SavedIconDefault width={12} height={15} />
                        )}
                    </div>
                </div>

                <div onClick={navigateRecipeDetail}>
                    {loading && <Skeleton className="rounded-t-lg mobile:h-[363px] h-auto w-full object-cover" />}
                    <img onLoad={handleImageLoad} className={`${loading ? 'hidden' : 'block'} rounded-t-lg mobile:h-[363px] h-auto w-full object-cover`} src={item?.image || item?.thumbnail} alt="" />

                    <div className="mobile:p-5 rounded-b-lg bg-white">
                        {item?.sponsorshipBlock && <SponsoredByBlock className="px-4 mb-1" fontColor="dark" noBackground noBorder item={item.sponsorshipBlock} />}

                        <button className="font-medium font tracking-tight text-black text-16 max-mobile:px-4 max-mobile:pt-5">
                            {loading ? (
                                <Skeleton className="w-9/12 h-[13px] rounded-sm mt-1" />
                            ) : (
                                <ContentBlockTitle text={item?.title} />
                            )}
                        </button>

                        <div className="flex items-center mt-2 ml-0.5 max-mobile:px-4 max-mobile:py-4">
                            {loading ? (
                                <Skeleton className="w-full mr-[75%] h-[10px] rounded-sm mt-2" />
                            ) : (
                                <div className="flex items-center">
                                    <ArrowBottomIcon width={15} height={15} />

                                    <p className="font-semibold not-heading-font not-heading-font text-grey01 ml-1.5" style={{ fontSize: 12 }}>
                                        {readableC02(item?.ceo2Kg || 0)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
