import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DismissIcon, EventMobileIcon, EventWebIcon, TicketModalMobileIcon, TicketModalWebIcon } from './Svg'
import SecondButton from './SecondButton'
import { Context } from './BaseContext'
import { updatePrize } from '../../state/prize/actions'
import { getPrize } from '../../state/prize/selectors'
import { getUser } from '../../state/user/selectors'
import ReferralNotificationRedeemer from '../referral/ReferralNotificationRedeemer'
import ReferralNotificationReferrer from '../referral/ReferralNotificationReferrer'
import { clearTravelData } from '../../utils/travel'

interface IProps {
    isShown?: boolean
    onClose: () => void
    width?: boolean
    title: string
    descriptionFirst?: string
    descriptionSecond?: string
    prizeDraw?: boolean
}

export default function TicketModal({ prizeDraw, width, isShown = true, onClose, title, descriptionFirst, descriptionSecond }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { screenWidth } = useContext(Context)
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { winnersPrice } = useSelector(getPrize)

    const onSubmit = () => {
        if (!prizeDraw) {
            dispatch(
                updatePrize({
                    id: winnersPrice.id,
                    isShown: true,
                    pointsTarget: winnersPrice?.pointsTarget?.id,
                    points: 0,
                }),
            )
        }

        onClose()
        clearTravelData(dispatch)

        if (prizeDraw) {
            if (user?.company?.anonymousMode) {
                navigate('/')
                return
            }
            navigate('/me/impact')
        }
    }

    if (winnersPrice?.rewardFor === 'redeemer') {
        return <ReferralNotificationRedeemer showModal={isShown} onClose={onClose} />
    }

    if (winnersPrice?.rewardFor === 'referrer') {
        return <ReferralNotificationReferrer showModal={isShown} onClose={onClose} />
    }

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 pt-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div onClick={onSubmit} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center">
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                {!prizeDraw && (
                                    <div className="flex items-end justify-center mobile:mt-[76px] mt-[60px]">
                                        <div className="flex flex-col items-center justify-center absolute">
                                            {winnersPrice.ticketsCount === 1 ? (
                                                screenWidth >= 768 ? (
                                                    <TicketModalWebIcon />
                                                ) : (
                                                    <TicketModalMobileIcon />
                                                )
                                            ) : (
                                                screenWidth >= 768 ? (
                                                    <EventWebIcon />
                                                ) : (
                                                    <EventMobileIcon />
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="flex flex-col justify-center items-center pb-[32px]">
                                    <div className={`${width ? 'xs-mobile:w-[386px] w-[242px]' : 'xs-mobile:w-[296px] w-[242px]'} flex flex-col items-center justify-center mobile:mt-[22px] mt-[24px]`}>
                                        <h1 className="heading-font mobile:text-22 text-18 text-black font-bold tracking-tight font">
                                            {title}
                                        </h1>

                                        {descriptionFirst ? (
                                            <h1 className="mt-2 text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                                {descriptionFirst}
                                            </h1>
                                        ) : (
                                            <h1 className="mt-2 text-center mobile:text-22 text-18 text-black tracking-tight font-normal font mobile:leading-[28.6px] leading-[23.6px]">
                                                {winnersPrice.ticketsCount === 1 ? t('log.by-collecting-points-you\'ve-got-a-ticket-for-the-prize-draw') : t('log.by-collecting-points-you\'ve-got-n-tickets-for-the-prize-draw').replace('[N]', String(winnersPrice.ticketsCount))}
                                            </h1>
                                        )}

                                        <h1 className="mt-2 text-center mobile:text-22 text-18 text-black tracking-tight font-light font mobile:leading-[28.6px] leading-[23.6px]">
                                            {descriptionSecond || 'Keep collecting points for more chances to win'}
                                        </h1>
                                    </div>

                                    <SecondButton onClick={onSubmit} text="Continue" className="not-heading-font mt-6 w-[122px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
