import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ActiveArrow, DefaultArrow } from './Svg'
import { getWhiteLabelingList } from '../../state/user/selectors'

interface IProps {
    title: string
    body: any
    favourite?: boolean
    recent?: boolean
    openOrClose?: boolean
}

export default function DropdownLog({ title, body, favourite, recent, openOrClose }: IProps) {
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [openedMenu, setOpenedMenu] = useState((favourite || recent) || openOrClose)

    return (
        <div className={`pb-2.5 border-b border-solid ${favourite || recent ? 'border-grey01' : 'border-avocado'}`}>
            <button
                disabled={favourite || recent}
                className="w-full flex items-center justify-between pt-[15px]"
                onClick={() => setOpenedMenu(!openedMenu)}
            >
                <span className={`font-medium ${(favourite || recent) ? 'text-grey01' : 'text-cavolo'} text-16 not-heading-font`}>{title}</span>

                {openedMenu ? (
                    <DefaultArrow width={14} height={8} color={(favourite || recent) ? '#5B5B5B' : whiteLabeling?.rocket} />
                ) : (
                    <ActiveArrow width={14} height={8} color={(favourite || recent) ? '#5B5B5B' : whiteLabeling?.rocket} />
                )}
            </button>

            <div className={`transition-all duration-300 ease-in-out overflow-hidden ${openedMenu ? 'max-h-0 scale-y-0 opacity-0' : 'max-h-[500px] scale-y-100 opacity-100'}`} style={{ transformOrigin: 'top' }}>
                {body}
            </div>
        </div>
    )
}
