import React, { useContext, useEffect, useRef, useState } from 'react'
import { uniq } from 'lodash'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DropDownMenu from './DropDownMenu'
import { ILeftSlideBarProps } from '../../types/components'
import useOutsideClick from '../../hooks/useOutsideAlerter'
import { Context } from './BaseContext'
import { Filter, FilterCross } from './Svg'
import LeftFilterSidebarMobile from './LeftFilterSidebarMobile'
import { useQueryParams } from '../../hooks/queryString'

interface IProps {
    children: React.ReactNode
    footer?: JSX.Element
    leftSidebarData: ILeftSlideBarProps
}

export default function LeftFilterSidebar({ leftSidebarData, children, footer }: IProps): JSX.Element {
    const { screenWidth, isEmbedded } = useContext(Context)
    const { tags } = useQueryParams()
    const [t] = useTranslation('global')
    // @ts-ignore
    const [open, setOpen] = useState(tags?.length > 0 && screenWidth <= 1199)
    const [openedMenu, setOpenedMenu] = useState([1])
    const modalRef = useRef(null)
    const { navbarHeight } = useContext(Context)

    const location = useLocation()

    const handleCloseModal = () => {
        setOpen(false)
    }

    const handleOpenModal = () => {
        setOpen(true)
    }

    useOutsideClick(modalRef, handleCloseModal)

    const handleOpenDropDown = (id:number) => {
        if (!openedMenu.includes(id)) {
            setOpenedMenu(uniq([...openedMenu, id]))
        } else {
            setOpenedMenu(openedMenu.filter((item) => item !== id))
        }
    }

    useEffect(() => {
        if (leftSidebarData.closeAfterChangeUrl) setOpen(false)
    }, [location.pathname, leftSidebarData.closeAfterChangeUrl])

    return (
        <div className="flex w-full flex-col justify-between h-screen overflow-y-hidden" style={{ paddingTop: navbarHeight }}>
            <style>{'html, body {height: 100%; overflow: hidden;}'}</style>

            <div className="flex overflow-y-hidden justify-center">
                <div className="inline-flex overflow-y-scroll bg-pistachio laptop:w-[calc((100vw-1440px)/2+366px)] tablet:min-w-[366px] justify-end">
                    <div className="hidden px-2 mobile:block tablet:hidden top-0 w-60 mt-[32px]">
                        <div onClick={handleOpenModal}
                            className="inline-flex p-2 text-sm text-gray-500 rounded-lg focus:outline-none">
                            <div className="flex flex-col items-center">
                                {leftSidebarData.icon}
                                <span className="font-semibold tracking-tight text-rocket text-12">
                                    {leftSidebarData.iconName}
                                </span>
                            </div>
                        </div>
                    </div>

                    {leftSidebarData?.buttonName === 'articles' ? (
                        <div ref={modalRef} className={`z-[10000] pb-10 overflow-y-scroll top-0 left-0 w-[300px] bg-pistachio text-white fixed h-full z-40 ease-in-out duration-300 ${open ? 'translate-x-0' : '-translate-x-full'}`}>
                            <div className="bg-pistachio sticky top-0 flex items-center justify-between h-[56px] border-b-[1px] border-avocado px-[20px]">
                                <div onClick={handleCloseModal}>
                                    <FilterCross width={24} height={24} />
                                </div>
                            </div>

                            <div className="mr-10 mt-[32px] pl-6">
                                {leftSidebarData?.data?.map((item) => (
                                    <div key={item.id}>
                                        <DropDownMenu
                                            loadContent={item.loadContent}
                                            isOpen={!openedMenu.includes(item.id)}
                                            onOpen={() => {
                                                handleOpenDropDown(item.id)
                                            }}
                                            title={item.title}>
                                            {item.content}
                                        </DropDownMenu>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <LeftFilterSidebarMobile
                            leftSidebarData={leftSidebarData}
                            modalRef={modalRef}
                            handleCloseModal={handleCloseModal}
                            open={open}
                        />
                    )}

                    <div className="hidden tablet:block bg-pistachio pl-10 laptop:pl-10 max-w-[366px] w-full pt-[55px]">
                        <div className="mr-10 pb-[80px]">
                            {leftSidebarData?.data?.map((item) => (
                                <div key={item.id}>
                                    <DropDownMenu
                                        loadContent={item.loadContent}
                                        isOpen={!openedMenu.includes(item.id)}
                                        onOpen={() => {
                                            handleOpenDropDown(item.id)
                                        }}
                                        title={item.title}>
                                        {item.content}
                                    </DropDownMenu>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="overflow-y-scroll flex-1">
                    <div className="max-w-[1074px]">
                        {children}
                    </div>

                    {footer}
                </div>
            </div>

            <div onClick={handleOpenModal} className="flex justify-center items-center">
                <div className={`fixed ${isEmbedded ? 'bottom-[100px]' : 'bottom-[40px]'} mobile:hidden cursor-pointer flex justify-center items-center w-[164px] h-[48px] bg-rocket rounded-[30px]`} style={{ boxShadow: ('2px 3px 0px rgba(0, 0, 0, 0.2') }}>
                    <div className="mr-2">
                        <Filter width={18} height={19} />
                    </div>

                    <h1 className="text-16 leading-[17.6px] text-white font-semibold font">
                        {t('global.filter')} {leftSidebarData?.buttonName}
                    </h1>
                </div>
            </div>
        </div>
    )
}
