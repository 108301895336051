import React from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import Footer from './Footer'
import LeftFilterSidebar from '../common/LeftFilterSidebar'
import { ILeftSlideBarProps } from '../../types/components'
import Navbar from './Navbar'
import SustainabilityMenu from './SustainabilityMenu'
import { getUser } from '../../state/user/selectors'

interface IProps {
    children: React.ReactNode
    leftSidebarData?: ILeftSlideBarProps
    spaceOnBottom?: boolean
    showFooter?: boolean
    showNavbar?: boolean
    leftBackground?: boolean
    contentContainerClassName?: string
    maxWidth?: boolean
    travel?: boolean
    showSustainabilityMenu?: boolean
}

function Layout({ travel, maxWidth = true, children, leftSidebarData, showSustainabilityMenu = true, spaceOnBottom = true, showFooter = true, showNavbar = true, leftBackground = false, contentContainerClassName }: IProps): JSX.Element {
    const { user } = useSelector(getUser)

    return (
        <div className={`bg-white flex flex-col h-screen ${!travel && 'justify-between'} items-center`}>
            {showNavbar && (
                <Navbar spaceOnBottom={spaceOnBottom} />
            )}

            {leftBackground && <div className="h-screen w-[50%] left-0 fixed bg-pistachio" style={{ zIndex: -1 }} />}

            <div className={cn('flex w-full bg-white', {
                absolute: maxWidth && leftSidebarData,
                'tablet:max-w-[1600px]': maxWidth && !leftSidebarData,
            }, contentContainerClassName)}>
                {leftSidebarData ? (
                    <LeftFilterSidebar leftSidebarData={leftSidebarData} footer={<Footer />}>
                        {children}
                    </LeftFilterSidebar>
                ) : children}
            </div>

            {user?.company?.menuPosition === 'Bottom' && showSustainabilityMenu && <SustainabilityMenu />}
            {(!leftSidebarData && showFooter) && <Footer />}
        </div>
    )
}

export default Layout
