import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import isEmpty from 'lodash/isEmpty'
import PointModal from './PointModal'
import { getLanguageList, getLoginConfig,
    getMenuItems, getSettingsList,
    getUser,
    getWhiteLabelingList } from '../../state/user/selectors'
import { FontSizeMap, IBadgeModalData, IPointModalData, IUser } from '../../types/data'
import { fetchAddVisitPoints,
    fetchFinalPointRewardsSuccess,
    fetchLanguageItems,
    fetchMenuItemsSuccess, fetchSettings,
    fetchUser,
    fetchUserSuccess, fetchWhiteLabeling } from '../../state/user/actions'
import BadgeModal from './BadgeModal'
import { getPrize, getPrizeDraw } from '../../state/prize/selectors'
import { fetchPrizeDrawSuccess, fetchWinnersPrice } from '../../state/prize/actions'
import SpinTheWheelModal from '../SpinTheWheelModal'
import LastDayToTrackModal from './LastDayToTrackModal'
import { getChallengeList } from '../../state/challenges/selectors'
import { getNavbarHeight, useResize } from '../../hooks/sizes'
import RewardsModal from '../rewards/RewardsModal'
import EnteredPrizeDrawModal from '../EnteredPrizeDrawModal'
import PerkBolCouponModal from '../perk/PerkBolCouponModal'
import TicketModal from './TicketModal'
import IntroModal from './IntroModal'
import { integrateMenuAndNavigation } from '../layout/Navbar'
import { fetchGame } from '../../state/travel/actions'
import OrganisationUnitModal from '../OrganisationUnitModal'
import Schedule from '../../pages/schedule/Schedule'
import { fetchInitializeResponse, fetchSponsorshipBlocksSuccess } from '../../state/home/actions'
import { fetchLastDayToTrackSuccess } from '../../state/challenges/actions'
import TravelPointModal from '../travel/TravelPointModal'
import Loader from './Loader'
import { HomeIcon, LanguageIcon, MyInfoIcon, ScheduleIcon, Shield } from './Svg'

const defaultPointModalValue: { show: boolean, data: IPointModalData } = {
    show: false,
    data: {
        points: 0,
        description: '',
    },
}

const defaultBadgeModalValue: { show: boolean, data: IBadgeModalData, onClose?: () => void } = {
    show: false,
    data: {
        badgeName: '',
        description: '',
    },
    onClose: undefined,
}

interface IContextType {
    setPointModal: (val: typeof defaultPointModalValue) => void
    setBadgeModal: (val: typeof defaultBadgeModalValue) => void
    setIsIntroShown: (val: boolean | undefined) => void
    showOnboarding: (user: IUser) => void
    navbarHeight: number
    screenWidth: number
    screenHeight: number
    isEmbedded: boolean
    distanceUnit: string
    learnUrls: string | undefined
    lowerUrls: string | undefined
    collectedLinks: any
    meUrls: string | undefined
    pledgeUrls: string | undefined
    setShowFactOfTheDayModal: (value: boolean) => void;
    showFactOfTheDayModal: boolean
    setShowArticleModal: (value: number | undefined) => void
    showArticleModal: number | undefined
    setTravelPointModalPoints: (value: number) => void
    accountMenuSettings: any
}

export const Context = createContext<IContextType>({
    setPointModal: () => null,
    setIsIntroShown: () => null,
    setBadgeModal: () => null,
    showOnboarding: () => null,
    navbarHeight: 0,
    screenWidth: 0,
    screenHeight: 0,
    isEmbedded: false,
    distanceUnit: 'kilometres',
    learnUrls: '',
    lowerUrls: '',
    meUrls: '',
    collectedLinks: '',
    pledgeUrls: '',
    setShowFactOfTheDayModal: () => null,
    showFactOfTheDayModal: false,
    setShowArticleModal: () => null,
    showArticleModal: 0,
    setTravelPointModalPoints: () => 0,
    accountMenuSettings: '',
})

interface IProps {
    children: React.ReactNode
}

export default function BaseContextWrapper({ children }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { settings } = useSelector(getSettingsList)
    const { user, token } = useSelector(getUser)
    const { winnersPrice } = useSelector(getPrize)
    const { menuItems } = useSelector(getMenuItems)
    const { lastDayToTrack } = useSelector(getChallengeList)
    const today = new Date().toISOString().slice(0, 10)
    const [pointModal, setPointModal] = useState<{ show: boolean; data: IPointModalData }>(defaultPointModalValue)
    const [badgeModal, setBadgeModal] = useState<{ show: boolean; data: IBadgeModalData, onClose?:() => void }>(defaultBadgeModalValue)
    const [fortuneModalShown, setFortuneModalShown] = useState<boolean>(false)
    const [lastDayToTrackModal, setLastDayToTrackModal] = useState<boolean>(false)
    const [showModalReward, setShowModalReward] = useState<boolean>(false)
    const [showEnteredPrizeDraw, setShowEnteredPrizeDraw] = useState<boolean>(false)
    const [showOrganisationUnit, setShowOrganisationUnit] = useState<boolean>(false)
    const [showBolModal, setShowBolModal] = useState<boolean | undefined>(false)
    const [showFirstTicketModal, setShowFirstTicketModal] = useState<boolean | undefined>(false)
    const [showSecondTicketModal, setShowSecondTicketModal] = useState<boolean | undefined>(false)
    const [showFactOfTheDayModal, setShowFactOfTheDayModal] = useState<boolean>(false)
    const [showArticleModal, setShowArticleModal] = useState<number | undefined>(0)
    const [isIntroShown, setIsIntroShown] = useState<boolean | undefined>(false)
    const [scheduleShowModal, setScheduleShowModal] = useState<boolean>(false)
    const [travelPointModalPoints, setTravelPointModalPoints] = useState<number>(0)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')
    const [whiteLabelingLoading, setWhiteLabelingLoading] = useState(true)

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString && JSON.parse(languageDataString)
    const { languageItem } = useSelector(getLanguageList)
    // const defaultLanguage = languageItem?.find((item) => item?.isDefault)
    const { prizeDraw } = useSelector(getPrizeDraw)

    const userLanguage = navigator.language.split('-')[0]
    const userDefaultLanguage = languageItem?.find((item) => item.language.shortName === userLanguage)

    const distanceUnit = localStorage.getItem('unit') || user?.company?.defaultDistanceUnit

    const { loginConfig } = useSelector(getLoginConfig)
    const { height: navbarHeight } = useResize({
        changedHeight: () => getNavbarHeight(),
        // @ts-ignore
    }, [token, window?.location?.pathname])

    const { width: screenWidth, height: screenHeight } = useResize({
        changedWidth: () => window.self.innerWidth,
        changedHeight: () => window.self.innerHeight,
    })

    useEffect(() => {
        if (user?.company?.googleAnalyticsMeasurementId || loginConfig?.googleAnalyticsMeasurementId) {
            ReactGA.initialize((user?.company?.googleAnalyticsMeasurementId || loginConfig?.googleAnalyticsMeasurementId) || '')
        }
    }, [loginConfig?.googleAnalyticsMeasurementId, user?.company?.googleAnalyticsMeasurementId])

    useEffect(() => {
        if (isEmpty(settings)) {
            dispatch(fetchSettings)
        }
    }, [dispatch, settings])

    useEffect(() => {
        if (!token) return

        dispatch(fetchLanguageItems)

        dispatch(fetchInitializeResponse((response) => {
            dispatch(fetchMenuItemsSuccess(response.menuItems))
            dispatch(fetchUserSuccess(response.userDetail))
            dispatch(fetchPrizeDrawSuccess(response.monthlyCompetition))
            dispatch(fetchLastDayToTrackSuccess(response.general))
            dispatch(fetchFinalPointRewardsSuccess(response.finalPointRewards))
            dispatch(fetchSponsorshipBlocksSuccess(response.sponsorshipBlocks))
        }))

        if (user?.visits?.date !== today) {
            dispatch(
                fetchAddVisitPoints({ date: today }, (res) => {
                    if (res.points) {
                        dispatch(fetchUser)
                    }

                    if (res.show) {
                        setPointModal({
                            show: true,
                            data: { points: res.points || 0, description: 'Just for logging in today you’ve received' },
                        })
                    }
                }),
            )
        }
        // eslint-disable-next-line
    }, [token])

    function milestoneModal() {
        navigate(`${window.location.pathname}?milestoneModal=true`)
    }

    useEffect(() => {
        if (winnersPrice.pointsTarget && winnersPrice.pointsTarget.points <= user.thisMonthPoints && !winnersPrice.isShown) {
            if (winnersPrice?.pointsTarget?.prize === 'spin_the_wheel') {
                setFortuneModalShown(true)
                milestoneModal()
            }
        }

        if (winnersPrice?.pointsTarget?.prize === 'bol_freebie_form' && !winnersPrice.isShown) {
            setShowBolModal(user?.company?.gamification)
            milestoneModal()
        }

        if (winnersPrice?.pointsTarget?.prize === 'reward_selection_pop_up' && !winnersPrice.isShown) {
            setShowModalReward(true)
            milestoneModal()
        }

        if (winnersPrice?.pointsTarget?.prize === 'entered_prize_draw' && !winnersPrice.isShown) {
            setShowEnteredPrizeDraw(true)
            milestoneModal()
        }

        if (winnersPrice?.pointsTarget?.prize === 'first_ticket_in_draw' && !winnersPrice.isShown) {
            setShowFirstTicketModal(user?.company?.gamification)
            milestoneModal()
        }

        if (winnersPrice?.pointsTarget?.prize === 'subsequent_ticket_in_draw' && !winnersPrice.isShown) {
            setShowSecondTicketModal(user?.company?.gamification)
            milestoneModal()
        }

        // eslint-disable-next-line
    }, [user, winnersPrice.pointsTarget, user?.thisMonthPoints])

    useEffect(() => {
        if (user?.company?.autoForwardIfSingleEvent && user?.company?.travelTrackingType !== 'standard') {
            dispatch(fetchGame())
        }

        // eslint-disable-next-line
    }, [user?.company?.autoForwardIfSingleEvent, user?.company?.travelTrackingType])

    useEffect(() => {
        // @ts-ignore
        if (lastDayToTrack?.lastDayToTrackChallenges?.id) setLastDayToTrackModal(true)
        // eslint-disable-next-line
    }, [lastDayToTrack?.lastDayToTrackChallenges])

    useEffect(() => {
        if (!token) return

        dispatch(fetchWinnersPrice)
        // eslint-disable-next-line
    }, [token, user?.thisMonthPoints])

    useEffect(() => {
        dispatch(fetchUser)
        // eslint-disable-next-line
    }, [window.location.pathname])

    useEffect(() => {
        if (!user?.organisationUnit?.name && !isIntroShown) setShowOrganisationUnit(true)
    }, [isIntroShown, user?.organisationUnit?.name])

    const onCloseIntro = (registeredUser?: IUser) => {
        setIsIntroShown(false)

        if (((registeredUser || user)?.company?.organisationalUnitsLevels ?? 0) > 1) {
            setShowOrganisationUnit(true)
        } else if (user?.company?.enableSchedule && user?.company?.showScheduleDuringOnboarding) {
            setScheduleShowModal(true)
        }

        // if (ssoRegisterPoints || registerPoints) {
        //     setPointModal({
        //         show: true,
        //         data: {
        //             points: ssoRegisterPoints || registerPoints,
        //             description: 'Thanks for joining! You’ve just earned',
        //         },
        //     })
        // }
    }

    const onCloseOrganisation = () => {
        setShowOrganisationUnit(false)
        setScheduleShowModal(true)
    }

    // const isDomainInTags = typeof user?.company?.embeddedDomains === 'string' && user?.company?.embeddedDomains?.split(',').includes(window?.location?.host)

    // const isEmbedded = (navigator?.userAgent === 'lowr-mobile-app' || window?.self !== window?.top) || isDomainInTags
    const isEmbedded = user?.company?.menuPosition === 'Bottom'
    const collectedLinks: string[] = ['/landing']

    const navigationData = integrateMenuAndNavigation(menuItems, user, prizeDraw)
    navigationData?.forEach((item) => {
        if (item?.mainHref && !['/learn', '/lower', '/me'].includes(item.mainHref)) {
            collectedLinks.push(item.mainHref)
            collectedLinks.push('/faqs', '/prize-draw', '/account/account-settings', '/account-overview', '/account/preferences', '/privacy-policy', '/terms-of-services', '/quiz', '/dashboard', '/account/organisation-unit', '/me/refer-a-friend', '/account/saved-places', '/account/schedule', '/overnight-stay', '/survey')
        }
        if (item?.items) {
            item?.items?.forEach((subItem) => {
                if (subItem?.href) {
                    collectedLinks.push(subItem.href)
                }
            })
        }
    })
    useEffect(() => {
        function checkNavigate() {
            const currentPath = window.location.pathname

            const isInCollectedLinks = collectedLinks.some((link) => currentPath.startsWith(link))

            if (!isInCollectedLinks && currentPath !== '/') {
                navigate('/404')
            }
        }

        if (token && token.token) checkNavigate()
        // eslint-disable-next-line
    }, [window.location.pathname])

    const learnUrls = collectedLinks.find((url) => url.startsWith('/learn'))
    const lowerUrls = collectedLinks.find((url) => url.startsWith('/lower'))
    const pledgeUrls = collectedLinks.find((url) => url.startsWith('/lower/pledges'))
    const meUrls = collectedLinks.find((url) => url.startsWith('/me/progress') || url.startsWith('/me/earn-points') || url.startsWith('/me/impact') || url.startsWith('/me/leaderboard') || url.startsWith('/me/achievements'))

    useEffect(() => {
        if (languageData) {
            i18next.changeLanguage(languageData || 'en')
        } else {
            i18next.changeLanguage(userDefaultLanguage?.language?.shortName ? userLanguage : 'en')
            localStorage.setItem('browserLanguage', JSON.stringify(userDefaultLanguage?.language?.shortName ? userLanguage : 'en'))
        }

    // eslint-disable-next-line
    }, [languageItem, userDefaultLanguage])

    // const measurementId = user?.company?.googleAnalyticsMeasurementId || loginConfig?.googleAnalyticsMeasurementId

    const showOnboarding = (registeredUser: IUser) => {
        if (registeredUser.company.isOnboardingShown) {
            setIsIntroShown(true)
        } else {
            onCloseIntro(registeredUser)
        }
    }

    const accountMenuSettings = [
        {
            id: 6,
            menuItem: t('navbar.My info'),
            href: '/account/account-settings',
            icon:
    <div className="mr-2 flex justify-center w-6 h-6">
        <MyInfoIcon />
    </div>,
        },
    ]

    if (user?.company?.organisationalUnitsLevels && user.company.organisationalUnitsLevels > 1 && user?.company?.hasOrganisationUnits) {
        accountMenuSettings.push({
            id: 7,
            menuItem: `${t('navbar.My')} ${user?.company?.organisationalUnitName || 'club'}`,
            href: '/account/organisation-unit',
            icon: (
                <div className="mr-2 flex justify-center w-6 h-6">
                    <Shield />
                </div>
            ),
        })
    }

    if (user?.company?.showSavedPlaces) {
        accountMenuSettings.push({
            id: 8,
            menuItem: t('savedPlaces.saved-places'),
            href: '/account/saved-places',
            icon: (
                <div className="mr-2 flex justify-center w-6 h-6">
                    <HomeIcon />
                </div>
            ),
        })
    }

    if (user?.company?.enableSchedule) {
        accountMenuSettings.push({
            id: 9,
            menuItem: t('schedule.schedule'),
            href: '/account/schedule',
            icon: (
                <div className="mr-2 flex justify-center w-6 h-6">
                    <ScheduleIcon />
                </div>
            ),
        })
    }

    accountMenuSettings.push({
        id: 10,
        menuItem: t('navbar.Preferences'),
        href: '/account/preferences',
        icon: (
            <div className="mr-2 flex justify-center w-6 h-6">
                <LanguageIcon />
            </div>
        ),
    })

    useEffect(() => {
        dispatch(fetchWhiteLabeling(() => {
            setWhiteLabelingLoading(false)
        }))

        // eslint-disable-next-line
    }, [])

    const fontSize: FontSizeMap = {
        58: '58px',
        48: '48px',
        36: '36px',
        32: '32px',
        28: '28px',
        27: '27px',
        26: '26px',
        25: '25px',
        24: '24px',
        22: '22px',
        20: '20px',
        18: '18px',
        16: '16px',
        15: '15px',
        14: '14px',
        13: '13px',
        12: '12px',
        11: '11px',
        10: '10px',
        9: '9px',
        8: '8px',
    }

    const resizeFontSizes = (resizeFontSize: number, headingResizeFontSize: number) => {
        const root = document.documentElement

        Object.keys(fontSize).forEach((key) => {
            const fontSizeKey = Number(key) as keyof FontSizeMap
            const currentFontSize = parseInt(fontSize[fontSizeKey], 10)

            const newBodyFontSize = currentFontSize + resizeFontSize
            root.style.setProperty(`--font-size-${fontSizeKey}`, `${newBodyFontSize}px`)

            const newHeadingFontSize = currentFontSize + headingResizeFontSize
            root.style.setProperty(`--heading-font-size-${fontSizeKey}`, `${newHeadingFontSize}px`)
        })
    }

    resizeFontSizes(whiteLabeling?.resizeFontSize || 0, whiteLabeling?.headingResizeFontSize || 0)

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <Context.Provider value={{
            setPointModal,
            setBadgeModal,
            navbarHeight,
            screenWidth,
            screenHeight,
            setIsIntroShown,
            showOnboarding,
            isEmbedded,
            learnUrls,
            lowerUrls,
            meUrls,
            pledgeUrls,
            collectedLinks,
            distanceUnit,
            setShowFactOfTheDayModal,
            showFactOfTheDayModal,
            setShowArticleModal,
            showArticleModal,
            setTravelPointModalPoints,
            accountMenuSettings,
        }}>
            {whiteLabeling?.fontLink && <link href={whiteLabeling?.fontLink} rel="stylesheet" />}
            {whiteLabeling?.headingsFontLink && <link href={whiteLabeling?.headingsFontLink} rel="stylesheet" />}

            {!whiteLabeling?.fontName && (
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap" rel="stylesheet" />
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@100;200;300;400;500;600;700;800;900&display=swap"
                        rel="stylesheet" />
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap"
                        rel="stylesheet" />
                    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                        rel="stylesheet" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto&display=swap"
                        rel="stylesheet" />

                </Helmet>
            )}

            <Helmet>
                <title>{user?.company?.publicName ? `Sustainability | ${user?.company?.publicName}` : 'Lowr'}</title>
            </Helmet>

            {/* {measurementId && ( */}
            {/*    <Helmet> */}
            {/*        <script async src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`} /> */}
            {/*        <script> */}
            {/*            { */}
            {/*                ` */}
            {/*                        window.dataLayer = window.dataLayer || []; */}
            {/*                        function gtag(){dataLayer.push(arguments)} */}
            {/*                        gtag('js', new Date()); */}

            {/*                        gtag('config', '${measurementId}'); */}
            {/*                    ` */}
            {/*            } */}
            {/*        </script> */}
            {/*    </Helmet> */}
            {/* )} */}

            <style>
                {isEmbedded && `
                *::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
                `}
            </style>

            <style>
                {(whiteLabeling?.fontFile && whiteLabeling.fontName) && `
@font-face {
  font-family: '${whiteLabeling.fontName}';
  src: url('${whiteLabeling?.fontFile}') format('${whiteLabeling.fontFileType}');
}
                `}

                {(whiteLabeling?.headingsFontFile && whiteLabeling?.headingsFontName) && `
@font-face {
  font-family: '${whiteLabeling.headingsFontName}';
  src: url('${whiteLabeling?.headingsFontFile}') format('${whiteLabeling.headingsFontFileType}');
}
                `}

                {whiteLabeling?.headingsFontFace || ''}

                {/* @ts-ignore */}
                {whiteLabeling && Object?.keys(whiteLabeling)?.map((item) => `
                .bg-${item} { background: ${(whiteLabeling as any)[item]} !important; }
                .text-${item} { color: ${(whiteLabeling as any)[item]} !important; }
                .border-${item} { border-color: ${(whiteLabeling as any)[item]} !important; }
                `)}

                {whiteLabeling?.fontName && ` 
* { 
    font-family: ${whiteLabeling?.fontName} !important; 
    font-weight: ${whiteLabeling?.fontWeight || 'normal'} !important;
} 
                `}

                {`
.font-semibold:not(.not-heading-font), .font-bold:not(.not-heading-font), .heading-font {
    ${whiteLabeling?.headingsFontName && `font-family: ${whiteLabeling?.headingsFontName} !important;`}
    ${whiteLabeling?.headingsInCaps && 'text-transform: uppercase;'}
}
.font-semibold:not(.not-heading-font), .font-bold:not(.not-heading-font), .heading-font {
    ${whiteLabeling?.headingsFontName && `font-family: ${whiteLabeling?.headingsFontName} !important;`}
    ${whiteLabeling?.headingFontWeight && `font-weight: ${whiteLabeling?.headingFontWeight} !important;`}
    ${whiteLabeling?.headingsInCaps && 'text-transform: uppercase;'}
}

.heading-font{
    font-size: var(--heading-font-size-16, 16px);
}
                `}

                {whiteLabeling?.headingsInCaps && `
                    .heading-uppercase {
                        text-transform: uppercase;
                    }
                `}

                {whiteLabeling?.headingsFontName && `
                    .sen-font {
                        font-family: "Sen", sans-serif !important;
                    }
                `}

                {`
                    .hmocIu {
                        transform: rotate(9deg);
                    }
                                     
                    input::placeholder {
                        color: rgba(91, 91, 91, 1) !important;
                    }
                    
                    #inputID::placeholder {
                        color: #5B5B5B;
                        opacity: 1;
                    }
                    
                    .frame::-webkit-scrollbar {
                        -webkit-appearance: none;
                    }

                    .frame::-webkit-scrollbar:horizontal {
                        height: 11px;
                    }
                    
                    .frame::-webkit-scrollbar-thumb {
                        border-radius: 8px;
                        border: 2px solid white;
                        background-color: rgba(0, 0, 0, .5);
                    }
                    
                    /* Hide scrollbar for IE, Edge and Firefox */
                    .no-scrollbar {
                        -ms-overflow-style: none;  /* IE and Edge */
                        scrollbar-width: none;  /* Firefox */
                    }
                    
                    [id^="react-select-"]:focus {
                        box-shadow: none !important;
                    }
                    
                    @keyframes load {
                        0% {
                            left: -100%
                        }
                        100% {
                            left: 150%
                        }
                    }
                    .rich-text p {
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #5B5B5B;
                        letter-spacing: -0.01em;
                        margin-bottom: 16px;
                    }

                    .rich-text h1 {
                        font-family: 'Noto Serif', serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 26px;
                        line-height: 34px;
                        color: #313131;
                        letter-spacing: -0.02em;
                        margin-bottom: 16px;
                    }

                    .rich-text h2 {
                        font-family: 'Noto Serif', serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        color: #313131;
                        letter-spacing: -0.02em;
                        margin-bottom: 16px;
                    }

                    .rich-text h3 {
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: #313131;
                        letter-spacing: -0.02em;
                        margin-bottom: 8px;
                    }

                    .rich-text ol {
                        margin-bottom: 24px;
                        margin-left: 25px;
                        list-style: decimal;
                    }

                    .rich-text ul {
                        margin-bottom: 24px;
                        margin-left: 22px;
                        list-style: disc;
                    }

                    .rich-text a {
                        text-underline-offset: 2px;
                        text-decoration: underline;
                        color: #417339;
                    }

                    .rich-text {
                        word-break: break-word
                    }

                    .rich-text ::marker {
                        margin-left: 100px
                    }

                    .rich-text li {
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #5B5B5B;
                        letter-spacing: -0.01em;
                        margin-bottom: 16px;
                        margin-left: 0;
                    }
                `}

                {whiteLabeling && `
                    body {
                        background-color: ${whiteLabeling.white}
                    }
                `}
            </style>

            {pointModal?.show && (
                <PointModal
                    width
                    onClose={() => setPointModal(defaultPointModalValue)}
                    point={pointModal.data.points}
                    description={pointModal.data.description} />
            )}

            {user?.company?.isTravelTypeEvent ? (
                (user?.company?.gamification && !!travelPointModalPoints) && (
                    <TravelPointModal
                        isShown={!!travelPointModalPoints}
                        onClose={() => setTravelPointModalPoints(0)}
                        point={travelPointModalPoints || 0}
                        title={t('log.congratulations!')}
                        description={t('log.for-logging-your-travel-you’ve-earned-points').replace('[points]', String(travelPointModalPoints || 0))}
                        descriptionTwo={t('log.keep-logging-how-you-travel-to-keep-collecting-points')} />
                )
            ) : (
                (user?.company?.gamification && !!travelPointModalPoints) && (
                    <TravelPointModal
                        isShown={!!travelPointModalPoints}
                        onClose={() => setTravelPointModalPoints(0)}
                        point={travelPointModalPoints || 0}
                        title={t('global.well-done')} />
                )
            )}

            {badgeModal?.show && (
                <BadgeModal
                    onClose={() => {
                        setBadgeModal(defaultBadgeModalValue)
                        if (typeof badgeModal.onClose === 'function') badgeModal.onClose()
                    }}
                    badgeName="Green Newbie"
                    description="You’ve just earned the" />
            )}

            {fortuneModalShown && (
                <SpinTheWheelModal
                    isShown={fortuneModalShown}
                    onClose={() => setFortuneModalShown(false)} />
            )}

            {showEnteredPrizeDraw && (
                <EnteredPrizeDrawModal
                    isShown={showEnteredPrizeDraw}
                    onClose={() => {
                        setShowEnteredPrizeDraw(false)
                        navigate('/')
                    }} />
            )}

            {showBolModal && <PerkBolCouponModal onClose={() => setShowBolModal(false)} />}

            {showModalReward && (
                <RewardsModal isShown={showModalReward} setShowModalPoint={setShowModalReward} />
            )}

            {showFirstTicketModal && (
                <TicketModal
                    width
                    onClose={() => setShowFirstTicketModal(false)}
                    title={t('log.you\'re-in-the-draw')} />
            )}

            {showSecondTicketModal && (
                <TicketModal
                    width
                    onClose={() => setShowSecondTicketModal(false)}
                    title={t('log.another-ticket')} />
            )}

            {isIntroShown && <IntroModal onClose={onCloseIntro} />}

            {showOrganisationUnit && (user?.company?.organisationalUnitsLevels ?? 0) > 1 && !user?.organisationUnit?.name && user?.company?.hasOrganisationUnits && !isIntroShown && (
                <OrganisationUnitModal
                    isShown={showOrganisationUnit}
                    onClose={onCloseOrganisation}
                    setScheduleShowModal={setScheduleShowModal} />
            )}

            {(user?.company?.enableSchedule && user?.company?.showScheduleDuringOnboarding && scheduleShowModal) && (
                <Schedule setShowModal={setScheduleShowModal} showModal />
            )}

            {/* <MaxPrizeModal isShown={maxPrizeModalShown} onClose={onCloseMaxPrizeModal} /> */}

            {lastDayToTrackModal && (
                // @ts-ignore
                <LastDayToTrackModal onClose={() => setLastDayToTrackModal(false)} data={lastDayToTrack?.lastDayToTrackChallenges} />
            )}

            {whiteLabelingLoading ? (
                <div className="flex items-center justify-center h-screen">
                    <Loader width={100} height={100} color="#00362B" />
                </div>
            ) : children}
        </Context.Provider>
    )
}
