import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '../components/common/Input'
import Button from '../components/common/Button'
import AuthContainer from '../components/auth/LoginContainer'
import { IResetPassword } from '../types/data'
import { getUser } from '../state/user/selectors'
import { fetchUserResetPassword } from '../state/user/actions'
import ServerError from '../components/common/ServerError'

export default function ResetPassword() {
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const { loading } = useSelector(getUser)
    const navigate = useNavigate()
    const { code } = useParams()

    const onSubmit = async (data: IResetPassword) => {
        dispatch(
            fetchUserResetPassword(
                data,
                () => navigate('/'),
                setError,
            ),
        )
    }

    const initialValues: IResetPassword = {
        password: '',
        password2: '',
        key: code,
    }

    return (
        <AuthContainer title="Reset your password">
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}>
                <Form>
                    <Input
                        name="password"
                        label="Password"
                        placeholder=""
                        type="password" />

                    <Input
                        name="password2"
                        label="Confirm password"
                        placeholder=""
                        type="password" />

                    <ServerError error={error} />
                    <Button loading={loading} style={{ marginTop: 27 }} text="Continue" />
                </Form>
            </Formik>
        </AuthContainer>

    )
}
