import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import lodash from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { ArrowTravel, PencilIconSmall } from '../../components/common/Svg'
import 'react-datepicker/dist/react-datepicker.css'
import { fetchUser } from '../../state/user/actions'
import { getDateTime } from '../../utils/date'
import { ITravelData } from '../../types/data'
import { travelSelector } from '../../state/travel/selectors'
import { saveCommuteInstanceToLocalstorage } from '../../utils/travel'
import { readableC02 } from '../../utils/helpers'
import { getWhiteLabelingList } from '../../state/user/selectors'

export default function TrackTravelRecentRoutes() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { recentRoutes } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')

    const onRestartLoad = useCallback(() => {
        dispatch(fetchUser)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    function onEdit(commute: ITravelData) {
        saveCommuteInstanceToLocalstorage(commute, { editCommute: true })

        navigate('/log/track-travel-overview', { state: { oneWay: false } })
    }

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel recent routes' })
    }, [])

    return (
        <div className="w-full">
            <div className="justify-end medium:mt-[52px] mt-[32px] pl-5 mobile:pl-[45px] pr-4 mobile:mx-[42px]">
                <div onClick={() => navigate(-1)} className="flex cursor-pointer">
                    <ArrowTravel width={22} height={22} />
                </div>

                <div className="flex flex-col items-center mobile:mt-[30px]">
                    <div className="max-w-[764px] w-full">
                        <div className="flex items-center justify-between mb-[3px] mt-[30px]">
                            <h1 className="heading-uppercase font-bold text-22 text-black">
                                {t('global.recent-routes')}
                            </h1>
                        </div>

                        {recentRoutes?.map((item) => (
                            <div key={item?.id} className="mt-[16px]">
                                <div className="flex items-center justify-between">
                                    <p className="text-14 font-bold not-heading-font text-black">
                                        {item?.game?.name}
                                        {/* {user?.company?.isTravelTypeEvent ? item.teamNameOnImpact : item?.year} */}
                                    </p>

                                    <div onClick={() => item?.startPlaceId && onEdit(item)}
                                        className={`flex items-center ${item?.startPlaceId && 'cursor-pointer'}`}>
                                        <p className={`text-14 font-bold ${item?.startPlaceId ? 'text-black' : 'text-grey02'} mr-[4px]`}>
                                            {t('global.edit')}
                                        </p>

                                        <PencilIconSmall width={16} height={16} color={item?.startPlaceId ? whiteLabeling.rocket : whiteLabeling.grey02} />
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mt-[4px]">
                                    <p className="text-14 not-heading-font text-black">
                                        {getDateTime(item?.dates?.[0] || item.date || undefined)}
                                    </p>

                                    {/* <p className="font-normal text-14 text-grey01"> */}
                                    {/*    {displayDistanceWithUnit(Number(item.distanceInKm || 0), distanceUnit, t)} */}
                                    {/* </p> */}

                                    <p className="font-normal text-14 text-grey01">
                                        {t('log.by')}&nbsp;
                                        {lodash
                                            .uniq(item?.legs?.map((i) => i?.transport?.name))
                                            .join(', ')}
                                    </p>
                                    <p className="font-normal text-14 text-grey01">
                                        +{readableC02(item?.totalCeo2Kg || 0)}
                                    </p>
                                </div>

                                <div className="w-full h-[1px] bg-grey01 mt-[6px]" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
