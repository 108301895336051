import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import humps from 'humps'
import { useQueryParams } from '../hooks/queryString'
import { loginSuccess } from '../state/user/actions'
import { Context } from '../components/common/BaseContext'
import { getUser } from '../state/user/selectors'

export default function Authorize() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { login, is_new_user: isNewUser } = useQueryParams()
    const { showOnboarding } = useContext(Context)

    useEffect(() => {
        // @ts-ignore
        const response = JSON.parse(login || '{}')
        const data = humps.camelizeKeys(response)

        dispatch(loginSuccess(data))

        if (isNewUser === '1' && data.user.company.isOnboardingShown) {
            showOnboarding(data.user)
        }

        navigate('/')
        // eslint-disable-next-line
    }, [login, user])

    return (
        <div />
    )
}
