import React, { useCallback, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import Input from '../components/common/Input'
import Button from '../components/common/Button'
import LinkText from '../components/common/LinkText'
import AuthContainer from '../components/auth/LoginContainer'
import { getLoginConfig, getUser } from '../state/user/selectors'
import { login } from '../state/user/actions'
import { ISignIn } from '../types/data'
import ServerError from '../components/common/ServerError'
import { required } from '../utils/validators'
import { objectToUrlParams, useQueryParams } from '../hooks/queryString'
import logo from '../static/lowr-black.png'

export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useQueryParams()
    const [error, setError] = useState('')
    const { loading } = useSelector(getUser)
    const { loginConfig } = useSelector(getLoginConfig)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Login' })
    }, [])

    const cleanQueryString = objectToUrlParams({ ...params, to: undefined })

    const onSubmit = useCallback(async (data: ISignIn) => {
        dispatch(login(data, () => {
            // navigate(String(params?.to || '/'))
            navigate(`${params.to || '/'}?${cleanQueryString}`)
        }, setError))

        // eslint-disable-next-line
    }, [])

    const initialValues: ISignIn = {
        email: '',
        password: '',
    }

    return (
        <AuthContainer title={t('login.login')}>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}>
                <Form>
                    <Input
                        name="email"
                        validate={required(t)}
                        label={loginConfig?.signUpEmail === 'company_email_address' ? t('login.Company-email-address') : t('global.email-address')}
                        placeholder={loginConfig?.signUpEmail === 'company_email_address' ? t('register.company-email-placeholder') : t('register.email-placeholder')}
                        type="email" />

                    <Input
                        name="password"
                        validate={required(t)}
                        label={t('global.password')}
                        type="password" />

                    <ServerError error={error} />

                    <Button loading={loading} className="mt-[27px]" text={t('register.log-in')} />

                    {loginConfig?.showPoweredByOnAuth && (
                        <div className="flex item-center justify-end mt-2">
                            <h1 style={{ color: loginConfig?.whiteLabeling?.black }} className="text-14 font">
                                {t('register.powered-by')}
                            </h1>

                            <img src={logo} className="w-[60px] h-[15px] object-contain" alt="" />
                        </div>
                    )}
                </Form>
            </Formik>

            <LinkText to="/reset-password" text={t('login.forgot-your-password')} className="text-center" />

            <div className="flex items-center justify-center mt-5">
                <p className="text-14 text-grey01 font-normal mr-1">
                    {t('login.don’t-have-an-account')}
                </p>

                <LinkText to="/register" text={t('register.sign-up')} className="text-center mt-[0px]" />
            </div>
        </AuthContainer>
    )
}
