import React from 'react'
import Dashboard from '../pages/Dashboard'

export default function FanAndEmployee() {
    return (
        <div>
            <Dashboard />
        </div>
    )
}
