import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RWebShare } from 'react-web-share'
import { Context } from '../common/BaseContext'
import { DismissIcon, InviteIcon, TicketModalMobileIcon, TicketModalWebIcon } from '../common/Svg'
import { updatePrize } from '../../state/prize/actions'
import { getPrize } from '../../state/prize/selectors'
import { getReferralList, getUser } from '../../state/user/selectors'

interface IProps {
    showModal?: boolean
    onClose: () => void
}

export default function ReferralNotificationReferrer({ showModal = true, onClose }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { screenWidth } = useContext(Context)
    const { referral } = useSelector(getReferralList)
    const [t] = useTranslation('global')
    const { winnersPrice } = useSelector(getPrize)

    const onSubmit = () => {
        dispatch(
            updatePrize({
                id: winnersPrice.id,
                isShown: true,
                pointsTarget: winnersPrice?.pointsTarget?.id,
                points: 0,
            }),
        )

        onClose()
        navigate('/me/refer-a-friend')
    }

    const shareData = {
        title: document.title,
        text: referral?.sharingText || '',
        url: `${window.location.origin}/refer/${user?.id}`,
    }

    const handleClick = async () => {
        try {
            onClose()
            dispatch(
                updatePrize({
                    id: winnersPrice.id,
                    isShown: true,
                    pointsTarget: winnersPrice?.pointsTarget?.id,
                    points: 0,
                }),
            )
            navigate('/me/refer-a-friend')
        } catch (err) {
            console.log(`Error: ${err}`)
        }
    }

    function isMobileDevice() {
        const userAgent = navigator.userAgent.toLowerCase()
        return /mobile|android|iphone|ipad|ipod|blackberry|windows phone/.test(userAgent)
    }

    return (
        <div>
            {showModal && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-[#ECFFFC] relative xs-mobile:w-[500px] w-11/12 pb-10 mobile:pt-4 pt-3 mobile:px-4 px-3 rounded-2xl" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center"
                                    onClick={() => {
                                        dispatch(
                                            updatePrize({
                                                id: winnersPrice.id,
                                                isShown: true,
                                                pointsTarget: winnersPrice?.pointsTarget?.id,
                                                points: 0,
                                            }),
                                        )

                                        onClose()
                                    }}>
                                    <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                                </div>

                                <div className="flex items-end justify-center mobile:mt-[76px] mt-[60px]">
                                    <div className="flex flex-col items-center justify-center absolute">
                                        {screenWidth >= 768 ? (
                                            <TicketModalWebIcon />
                                        ) : (
                                            <TicketModalMobileIcon />
                                        )}
                                    </div>
                                </div>

                                <div className="flex flex-col justify-center items-center">
                                    <div className="xs-mobile:w-[370px] w-[242px] flex flex-col items-center justify-center mt-[24px]">
                                        <h1 className="mobile:text-22 text-18 text-darken font-medium tracking-[-0.02rem] font">
                                            Congratulations!
                                        </h1>

                                        <h1 className="text-center mobile:text-16 text-14 mt-[2.5px] text-grey01 tracking-tight font-normal mobile:leading-[24px]">
                                            Your friend used your referral link, earning you an extra ticket for the prize draw.
                                        </h1>
                                    </div>

                                    {isMobileDevice() ? (
                                        <button
                                            type="submit"
                                            className="w-full h-12 bg-rocket mt-4 rounded-md flex items-center justify-center"
                                            style={{ boxShadow: '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}
                                            onClick={handleClick}>
                                            <InviteIcon />

                                            <h1 className="ml-2.5 text-16 font-bold tracking-tight text-pistachio">
                                                {t('referral.invite-someone')}
                                            </h1>
                                        </button>
                                    ) : (
                                        <div onClick={onSubmit} className="w-full">
                                            <RWebShare
                                                data={{
                                                    url: `${window.location.origin}/refer/${user?.id}`,
                                                    title: shareData?.title,
                                                }}
                                                onClick={() => console.info('share successful!')}
                                            >
                                                <button
                                                    type="submit"
                                                    className="w-full h-12 bg-rocket mt-4 rounded-md flex items-center justify-center"
                                                    style={{ boxShadow: '2px 2px 0px 0px rgba(0, 0, 0, 0.15)' }}
                                                >
                                                    <InviteIcon />

                                                    <h1 className="ml-2.5 text-16 font-bold tracking-tight text-pistachio">
                                                        {t('referral.invite-someone')}
                                                    </h1>
                                                </button>
                                            </RWebShare>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
