import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ShieldBadge, TicketIconForLeaderboard, TopArrow, UserDefaultIcon } from './common/Svg'
import { getUser } from '../state/user/selectors'
import Pagination from './common/Pagination'
import { fetchLeaderboard } from '../state/leaderboard/actions'
import { getLeaderboard } from '../state/leaderboard/selectors'
import Loader from './common/Loader'
import { Context } from './common/BaseContext'

export default function LeaderboardUserItems() {
    const dispatch = useDispatch()
    const { screenWidth } = useContext(Context)
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { leaderboard, loading } = useSelector(getLeaderboard)
    const leaderboards = leaderboard.leaderboards.find((item) => item.id === leaderboard?.currentLeaderboard)

    let topUsers: any[] = []
    let bottomUsers: any[] = []

    if (leaderboards?.goldZoneOption === 'percentage') {
        const percentage = leaderboards.amount ? leaderboards.amount / 100 : 0
        const thresholdIndex = leaderboard.count && percentage ? Math.floor(leaderboard.count * percentage) : 0

        topUsers = leaderboard.results
            .slice(0, thresholdIndex)
            .sort((a, b) => {
                const pointsA = typeof a.thisMonthPoints === 'string' ? parseInt(a.thisMonthPoints, 10) : a.thisMonthPoints
                const pointsB = typeof b.thisMonthPoints === 'string' ? parseInt(b.thisMonthPoints, 10) : b.thisMonthPoints
                return pointsB - pointsA
            })

        bottomUsers = leaderboard.results
            .slice(thresholdIndex)
            .sort((a, b) => {
                const pointsA = typeof a.thisMonthPoints === 'string' ? parseInt(a.thisMonthPoints, 10) : a.thisMonthPoints
                const pointsB = typeof b.thisMonthPoints === 'string' ? parseInt(b.thisMonthPoints, 10) : b.thisMonthPoints
                return pointsB - pointsA
            })
    } else if (leaderboards?.goldZoneOption === 'points') {
        const pointsThreshold = leaderboards.amount || 0

        topUsers = leaderboard.results.filter((item) => {
            const points = typeof item.thisMonthPoints === 'string' ? parseInt(item.thisMonthPoints, 10) : item.thisMonthPoints
            return points >= pointsThreshold
        })

        bottomUsers = leaderboard.results.filter((item) => {
            const points = typeof item.thisMonthPoints === 'string' ? parseInt(item.thisMonthPoints, 10) : item.thisMonthPoints
            return points < pointsThreshold
        })
    } else if (leaderboards?.goldZoneOption === 'top_n') {
        const topN = typeof leaderboards?.amount === 'number' ? leaderboards.amount : 0

        topUsers = leaderboard.results.slice(0, topN).sort((a, b) => {
            const pointsA = typeof a.thisMonthPoints === 'number' ? a.thisMonthPoints : parseInt(a.thisMonthPoints ?? '0', 10)
            const pointsB = typeof b.thisMonthPoints === 'number' ? b.thisMonthPoints : parseInt(b.thisMonthPoints ?? '0', 10)
            return pointsB - pointsA
        })

        bottomUsers = leaderboard.results.slice(topN).sort((a, b) => {
            const pointsA = typeof a.thisMonthPoints === 'number' ? a.thisMonthPoints : parseInt(a.thisMonthPoints ?? '0', 10)
            const pointsB = typeof b.thisMonthPoints === 'number' ? b.thisMonthPoints : parseInt(b.thisMonthPoints ?? '0', 10)
            return pointsB - pointsA
        })
    } else if (leaderboards?.goldZoneOption === 'off') {
        topUsers = leaderboard.results.filter((item) => {
            const points = typeof item.thisMonthPoints === 'string' ? parseInt(item.thisMonthPoints, 10) : item.thisMonthPoints
            return points >= 100
        })

        bottomUsers = leaderboard.results.filter((item) => {
            const points = typeof item.thisMonthPoints === 'string' ? parseInt(item.thisMonthPoints, 10) : item.thisMonthPoints
            return points < 100
        })
    }

    return (
        <div>
            {loading && (
                <div className="flex items-center justify-center h-48">
                    <Loader width={32} height={32} />
                </div>
            )}

            {leaderboard?.results?.length ? (
                <div className="mt-[11px]">
                    {topUsers?.map((item: any) => (
                        <div key={item?.rank} className={`flex items-center justify-between h-[60px] border-b-[1px] border-[#EEFFED] ${leaderboard?.isUserLeaderboard ? user?.id?.toString() === item?.userId?.toString() ? 'bg-grapeFruit' : (leaderboards?.goldZoneOption === 'off' ? 'bg-white' : 'bg-[#FCF8AC]') : item?.me ? 'bg-grapeFruit' : (leaderboards?.goldZoneOption === 'off' ? 'bg-white' : 'bg-[#FCF8AC]')} px-[16px]`}>
                            <div className="flex items-center">
                                <h1 className={`mobile:text-16 not-heading-font text-14 font-medium tracking-[-0.02rem] text-black font ${leaderboard?.isUserLeaderboard ? user?.id?.toString() === item?.userId?.toString() ? 'text-white' : 'text-dark' : item?.me ? 'text-white' : 'text-dark'}`}>
                                    {item?.rank}
                                </h1>

                                <div className="flex items-center">
                                    <div>
                                        {item?.image ? (
                                            <img className="object-cover w-[40px] h-[40px] ml-[10px] rounded-full"
                                                src={item?.image} alt="" />
                                        ) : (
                                            <div className="ml-[10px]">
                                                {leaderboard?.isUserLeaderboard ? (
                                                    <UserDefaultIcon width={40} height={40} />
                                                ) : (
                                                    leaderboards?.entry?.image
                                                        ? <img className="object-cover w-[40px] h-[40px]" src={leaderboards?.entry?.image} alt="" />
                                                        : <ShieldBadge />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="ml-[13px]">
                                        <h1 style={{ maxWidth: screenWidth - 190 }}
                                            className={`x-mobile:max-w-full not-heading-font x-mobile:line-clamp-none line-clamp-1 font-medium mobile:text-16 text-14 tracking-[-0.01rem] ${leaderboard?.isUserLeaderboard ? user?.id?.toString() === item?.userId?.toString() ? 'text-white' : 'text-black' : item?.me ? 'text-white' : 'text-dark'}`}>
                                            {leaderboard?.isUserLeaderboard ? (
                                                <div>
                                                    {item.firstName} {item?.lastName?.slice(0, 1)}.
                                                </div>
                                            ) : (
                                                <div>
                                                    {item?.name}
                                                </div>
                                            )}
                                        </h1>
                                    </div>
                                </div>
                            </div>

                            <h1 className={`flex items-center font-medium not-heading-font mobile:text-16 text-14 tracking-[-0.01rem] text-black ${leaderboard?.isUserLeaderboard ? user?.id?.toString() === item?.userId?.toString() ? 'text-white' : 'text-dark' : item?.me ? 'text-white' : 'text-dark'}`}>
                                {item?.thisMonthPoints}

                                {leaderboards?.metric === 'tickets' ? (
                                    <div className="ml-1.5">
                                        <TicketIconForLeaderboard />
                                    </div>
                                ) : (
                                    <>
                                        &nbsp;{t('global.pts')}.
                                    </>
                                )}
                            </h1>
                        </div>
                    ))}

                    {leaderboards?.goldZoneOption === 'off' ? null : (
                        <div className="h-[46px] bg-[#FFD700] flex items-center justify-center">
                            <TopArrow width={11} height={11} />

                            <h1 className="not-heading-font text-16 text-black font italic font-bold mx-[22px]">
                                {t('me.gold-zone')}
                            </h1>

                            <TopArrow width={11} height={11} />
                        </div>
                    )}

                    {bottomUsers?.map((item: any) => (
                        <div key={item?.rank} className={`flex items-center justify-between h-[60px] border-b-[1px] border-[#EEFFED] ${leaderboard?.isUserLeaderboard ? user?.id?.toString() === item?.userId?.toString() ? 'bg-grapeFruit' : 'bg-white' : item?.me ? 'bg-grapeFruit' : 'bg-white'}  px-[16px]`}>
                            <div className="flex items-center">
                                <h1 className={`mobile:text-16 not-heading-font text-14 font-medium tracking-[-0.02rem] text-dark font ${leaderboard?.isUserLeaderboard ? user?.id?.toString() === item?.userId?.toString() ? 'text-white' : 'text-dark' : item?.me ? 'text-white' : 'text-dark'}`}>
                                    {item?.rank}
                                </h1>

                                <div className="flex items-center">
                                    <div>
                                        {item?.image ? (
                                            <img className="object-cover w-[40px] h-[40px] ml-[10px] rounded-full" src={item?.image} alt="" />
                                        ) : (
                                            <div className="ml-[10px]">
                                                {leaderboard?.isUserLeaderboard ? (
                                                    <UserDefaultIcon width={40} height={40} />
                                                ) : (
                                                    leaderboards?.entry?.image
                                                        ? <img className="object-cover w-[40px] h-[40px]" src={leaderboards?.entry?.image} alt="" />
                                                        : <ShieldBadge />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="ml-[13px]">
                                        <h1 style={{ maxWidth: screenWidth - 190 }} className={`x-mobile:max-w-full not-heading-font x-mobile:line-clamp-none line-clamp-1 font-medium mobile:text-16 text-14 tracking-[-0.01rem] ${leaderboard?.isUserLeaderboard ? user?.id?.toString() === item?.userId?.toString() ? 'text-white' : 'text-dark' : item?.me ? 'text-white' : 'text-dark'}`}>
                                            {leaderboard?.isUserLeaderboard ? (
                                                <div>
                                                    {item.firstName} {item?.lastName?.slice(0, 1)}.
                                                </div>
                                            ) : (
                                                <div>
                                                    {item?.name}
                                                </div>
                                            )}
                                        </h1>
                                    </div>
                                </div>
                            </div>

                            <h1 className={`flex items-center font-medium not-heading-font mobile:text-16 text-14 tracking-[-0.01rem] text-dark ${leaderboard?.isUserLeaderboard ? user?.id?.toString() === item?.userId?.toString() ? 'text-white' : 'text-dark' : item?.me ? 'text-white' : 'text-dark'}`}>
                                {item?.thisMonthPoints}
                                {leaderboards?.metric === 'tickets' ? (
                                    <div className="ml-1.5">
                                        <TicketIconForLeaderboard />
                                    </div>
                                ) : (
                                    <>
                                        &nbsp;{t('global.pts')}.
                                    </>
                                )}
                            </h1>
                        </div>
                    ))}

                    <Pagination
                        loading={loading}
                        loadMore={(page) => dispatch(fetchLeaderboard({ page, leaderboard: leaderboards?.id || 0 }))}
                        containerClassName="mt-[60px]"
                        totalCount={leaderboard?.count}
                        currentCount={leaderboard?.results?.length} />
                </div>
            ) : null}
        </div>

    )
}
