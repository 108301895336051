import React, { useContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { ArrowBottomIcon,
    ArticleIcon, FlagIcon, LeafIcon, Pencil,
    PerkIcon,
    PledgeIcon,
    RecipeIcon,
    ReferralIcon, RightArrow,
    SignOutIcon, UserDefaultIcon } from './common/Svg'
import { Context } from './common/BaseContext'
import { getUser, getWhiteLabelingList } from '../state/user/selectors'
import { fetchUser, fetchUserAvatarUpdate, signOutUser } from '../state/user/actions'
import { readableC02 } from '../utils/helpers'
import Skeleton from './common/Skeleton'
import { getMonths } from '../utils/date'
import { getPrizeDraw } from '../state/prize/selectors'

interface IProps {
    right?: boolean
    setShowModal?: (value: boolean) => void
    setLoading?: (value: boolean) => void
    loading?: boolean
}

export default function AccountMenuContent({ right, setShowModal, setLoading, loading }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { prizeDraw } = useSelector(getPrizeDraw)
    const { screenWidth, collectedLinks, accountMenuSettings } = useContext(Context)
    const pathName = window?.location?.pathname
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { user, isSso } = useSelector(getUser)
    const [t] = useTranslation('global')

    const onLogout = () => {
        dispatch(signOutUser(() => navigate('/login')))
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }

        const file = e.target.files[0]
        setLoading?.(true)
        setShowModal?.(false)
        dispatch(fetchUserAvatarUpdate({ uploadedImage: file }))
        dispatch(fetchUser)
        setLoading?.(false)
    }

    const onSubmit = () => {
        dispatch(fetchUser)
    }

    const accountMenuReferral = [
        {
            id: 1,
            menuItem: t('navbar.Refer a friend'),
            href: '/me/refer-a-friend',
            icon:
    <div className="mr-2 flex justify-center w-6 h-6">
        <ReferralIcon />
    </div>,
        },
    ]

    const accountMenuBookmarks = [
        collectedLinks.includes('/lower/swaps') ? {
            id: 2,
            menuItem: 'Swaps',
            href: '/lower/swaps/swaps-bookmark',
            icon:
            <div className="mr-2 flex justify-center w-6 h-6">
                <PerkIcon width={22} height={21} />
            </div>,
        } : null,
        collectedLinks.includes('/learn/articles') ? {
            id: 3,
            menuItem: 'Articles',
            href: '/learn/articles/article-bookmark',
            icon:
            <div className="mr-2 flex justify-center w-6 h-6">
                <ArticleIcon width={22}
                    height={22} />
            </div>,
        } : null,
        collectedLinks.includes('/lower/recipes') ? {
            id: 4,
            menuItem: 'Recipes',
            href: '/lower/recipes/recipe-bookmark',
            icon:
            <div className="mr-2 flex justify-center w-6 h-6">
                <RecipeIcon width={24}
                    height={24} />
            </div>,
        } : null,
        collectedLinks.includes('/lower/pledges') ? {
            id: 5,
            menuItem: 'Pledges',
            href: '/lower/pledges/pledges-list',
            icon:
            <div className="mr-2 flex justify-center w-6 h-6">
                <PledgeIcon width={24} height={24} />
            </div>,
        } : null,
    ].filter((item) => item !== null)

    const legalData = [
        {
            id: 11,
            menuItem: t('footer.terms-of–service'),
            href: '/terms-of-services',
            icon: '',
        },
        {
            id: 12,
            menuItem: t('footer.privacy-policy'),
            href: '/privacy-policy',
            icon: '',
        },
    ]

    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            {user?.company?.gamification && prizeDraw?.showProgressBar ? (
                <div className="mb-8">
                    <div className="flex items-center mb-3">
                        {loading ? (
                            <Skeleton className="w-[60px] h-[60px] object-cover rounded-full" />
                        ) : (
                            screenWidth <= 500 ? (
                                <Formik onSubmit={onSubmit} initialValues={{ uploadedImage: '' }}>
                                    <Form>
                                        <div className="flex flex-col items-center">
                                            <label htmlFor="file-upload"
                                                className="flex items-center justify-center cursor-pointer">
                                                <div className="flex justify-end cursor-pointer relative">
                                                    {!user.uploadedImage && !user.socialImage ? (
                                                        <div className="w-[60px] h-[60px] object-cover">
                                                            <UserDefaultIcon width={60} height={60} />
                                                        </div>
                                                    ) : user.uploadedImage ? (
                                                        <div className="w-[60px] h-[60px]">
                                                            <img
                                                                className="rounded-full w-full h-full object-cover"
                                                                src={user.uploadedImage}
                                                                alt=""
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="w-[60px] h-[60px]">
                                                            <img
                                                                className="rounded-full object-cover w-full h-full"
                                                                src={user.socialImage}
                                                                alt=""
                                                            />
                                                        </div>
                                                    )}

                                                    <div className="absolute">
                                                        <Pencil width={16} height={16} />
                                                    </div>
                                                </div>
                                            </label>

                                            <input
                                                name="image"
                                                id="file-upload"
                                                type="file"
                                                className="hidden"
                                                onChange={handleFileChange} />
                                        </div>
                                    </Form>
                                </Formik>
                            ) : (
                                <div onClick={() => setShowModal?.(true)}
                                    className="flex justify-end cursor-pointer relative">
                                    {!user.uploadedImage && !user.socialImage ? (
                                        <div className="w-[60px] h-[60px] object-cover">
                                            <UserDefaultIcon width={60} height={60} />
                                        </div>
                                    ) : user.uploadedImage ? (
                                        <div className="w-[60px] h-[60px]">
                                            <img
                                                className="rounded-full w-full h-full object-cover"
                                                src={user.uploadedImage}
                                                alt=""
                                            />
                                        </div>
                                    ) : (
                                        <div className="w-[60px] h-[60px]">
                                            <img
                                                className="rounded-full object-cover w-full h-full"
                                                src={user.socialImage}
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <div className="absolute">
                                        <Pencil width={16} height={16} />
                                    </div>
                                </div>
                            )
                        )}

                        <div className="ml-4">
                            <span className="font-medium text-24 tracking-[-0.02rem] text-black font">
                                {user.firstName} {user.lastName}
                            </span>

                            <h1 className="text-grey01 text-14 font-normal">
                                {t('account.joined')} {getMonths(user.dateJoined)}
                            </h1>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex items-center">
                            <LeafIcon width={16} height={16} color={whiteLabeling?.grey01} />

                            <span className={`${right ? 'max-laptop:text-13 text-15' : 'text-15'} font-medium not-heading-font tracking-tight text-grey01 font ml-2`}>
                                {user?.thisMonthPoints} {t('global.pts')}
                            </span>
                        </div>

                        <div className="flex items-center max-laptop:mx-2 mx-6">
                            <FlagIcon width={16} height={16} color={whiteLabeling?.grey01} />

                            <span className={`${right ? 'max-laptop:text-13 text-15' : 'text-15'} font-medium not-heading-font tracking-tight text-grey01 font ml-2`}>
                                #{user?.rank === null ? t('navbar.top') : user?.rank}
                            </span>
                        </div>

                        <div className="flex items-center max-s-mobile:hidden">
                            <ArrowBottomIcon color={whiteLabeling?.grey01} width={16} height={16} />

                            <span className={`${right ? 'max-laptop:text-13 text-15' : 'text-15'} font-medium not-heading-font tracking-tight text-grey01 font ml-2`}>
                                {readableC02(user?.thisMonthCeoKg || 0)}
                            </span>
                        </div>
                    </div>
                </div>
            ) : null}

            {user?.company?.referralCampaignIsActive && (
                <div>
                    <div>
                        <span className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01">
                            {t('navbar.referral')}
                        </span>
                    </div>

                    {accountMenuReferral?.map((item) => (
                        <div key={item.id} onClick={() => navigate(`${item.href}`)}>
                            <div>
                                <a href="#"
                                    className={classNames('border-b border-spinach text-black not-heading-font flex items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font')}>
                                    {right && (
                                        pathName.startsWith(item?.href || 'home') && (
                                            <div className="mr-2">
                                                <RightArrow width={13} height={8} />
                                            </div>
                                        )
                                    )}

                                    {item.icon}
                                    {item?.menuItem}
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div className={user?.company?.referralCampaignIsActive ? 'mt-[29px]' : ''}>
                <span className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01">
                    {t('navbar.account-settings')}
                </span>
            </div>

            {accountMenuSettings?.map((item: any) => (
                <div key={item.id} onClick={() => navigate(`${item.href}`)}>
                    <div>
                        <a href="#"
                            className={classNames('border-b border-spinach text-black not-heading-font flex items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font')}>
                            {right && (
                                pathName.startsWith(item?.href || 'home') && (
                                    <div className="mr-2">
                                        <RightArrow width={13} height={8} />
                                    </div>
                                )
                            )}

                            {item.icon}
                            {item?.menuItem}
                        </a>
                    </div>
                </div>
            ))}

            {!isSso && (
                <div className="border-b border-spinach mt-[29px]" onClick={onLogout}>
                    <div className="flex items-center">
                        <div className="mr-2 flex justify-center items-center w-6 h-6">
                            <SignOutIcon color={whiteLabeling.raspberry} width={19} height={20} />
                        </div>

                        <a href="#"
                            className="flex not-heading-font items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium pb-[4px] font text-raspberry">
                            {t('global.sign-out')}
                        </a>
                    </div>
                </div>
            )}

            {isEmpty(accountMenuBookmarks) ? null : (
                <p className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01 mt-[29px]">
                    {t('navbar.bookmarks')}
                </p>
            )}

            {accountMenuBookmarks.map((item) => (
                <div key={item?.id}>
                    <div>
                        <NavLink to={item?.href || '/'}
                            className={classNames('border-b border-spinach text-black not-heading-font flex items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font')}>
                            {item?.icon}
                            {t(`navbar.${item?.menuItem}`)}
                        </NavLink>
                    </div>
                </div>
            ))}

            <div>
                <div className="mt-[29px]">
                    <span className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01">
                        {t('navbar.support')}
                    </span>
                </div>

                {user?.company?.gamification && (
                    <div>
                        <h1 className="border-b border-spinach cursor-pointer flex items-center not-heading-font text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font text-black"
                            onClick={() => navigate('/me/earn-points')}>
                            {pathName.startsWith('/me/earn-points') && (
                                <div className="mr-2">
                                    <RightArrow width={13} height={8} />
                                </div>
                            )}

                            {t('navbar.how-to-earn-points')}
                        </h1>
                    </div>
                )}

                <div>
                    <h1 className="border-b border-spinach cursor-pointer flex items-center not-heading-font text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font text-black"
                        onClick={() => window.open('https://www.lowr.co/contact-us', '_blank')}>
                        {t('footer.contact-us')}
                    </h1>
                </div>

                <div>
                    <h1 className="border-b border-spinach cursor-pointer flex items-center not-heading-font text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font text-black"
                        onClick={() => navigate('/faqs')}>
                        {pathName.startsWith('/faqs') && (
                            <div className="mr-2">
                                <RightArrow width={13} height={8} />
                            </div>
                        )}

                        {t('footer.faqs')}
                    </h1>
                </div>
            </div>

            <div>
                <div className="mt-[29px]">
                    <span className="tracking-wider w-full h-[18px] text-12 font-medium text-grey01">
                        {t('navbar.legal')}
                    </span>
                </div>

                {legalData.map((item) => (
                    <div key={item?.id}>
                        <NavLink to={item?.href || '/'}
                            className={classNames('border-b border-spinach text-black not-heading-font flex items-center text-18 leading-[23.76px] tracking-[-.02em] font-medium mt-3 pb-[4px] font')}>
                            {right && (
                                pathName.startsWith(item?.href || 'home') && (
                                    <div className="mr-2">
                                        <RightArrow width={13} height={8} />
                                    </div>
                                )
                            )}

                            {item?.menuItem}
                        </NavLink>
                    </div>
                ))}

                <p className="mt-7 text-11 text-[#5B5B5B]">
                    © The Green Shoot Inst. Ltd.<br />
                    2022.<br />
                    All Rights Reserved.
                </p>
            </div>
        </div>
    )
}
